import React, { useState, useEffect } from 'react';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { connect, useSelector, useDispatch } from 'react-redux';
import {
  makeGetAuth,
  makeGetDefaultAccount,
  makeGetNoiCap,
  makeGetProfile,
  makeGetToken,
} from '../../../lib/selector';
import { setAuth } from '../../../containers/client/actions';
import { _formatDate2, formatPhoneNumber } from '../../../util';
import { withNamespaces } from 'react-i18next';
import './profile.scss';
import AddressChangeModal from './addressChangeModal';
import EmailPhoneChangeModal from './emailPhoneChangeModal';
import PersonalIDChangeModal from './personalIDChangeModal';
import { MdVerifiedUser } from 'react-icons/md';
import { GoUnverified } from 'react-icons/go';
import { setToast } from '../../../containers/client/actions';
import FloatingLabelInput from '../../input/floatingLabelInput';

const Index = withNamespaces('translations')(
  ({ t, handleLoadProfile, getProfileInforState }) => {
    const [openEmailPhoneChangeModal, setEmailPhoneChangeModal] =
      useState(false);
    const [openAddressChangeModal, setAddressChangeModal] = useState(false);
    const [openPersonalIDChangeModal, setPersonalIDChangeModal] =
      useState(false);

    const token = useSelector(makeGetToken());
    const auth = useSelector(makeGetAuth());
    const defaultAccount = useSelector(makeGetDefaultAccount());
    const profile = useSelector(makeGetProfile());
    const noicap = useSelector(makeGetNoiCap());
    const state = useSelector((state) => state.customer?.profileUpdateState);
    const dispatch = useDispatch();

    const authenHandle = () => {
      dispatch(setAuth());
    };

    useEffect(() => {
      getProfileInforState();
    }, []);

    const toastHandle = (msg, title = t('txt-notice')) => {
      const toastMsg = {
        id: Math.random(),
        msg: msg,
        title: title,
      };
      dispatch(setToast(toastMsg));
    };

    const emailPhoneChangeHandle = () => {
      if (state && state > 0) {
        toastHandle(t('account.updateProcessing'));
      } else {
        setEmailPhoneChangeModal(true);
      }
    };

    const addressChangeHandle = () => {
      if (state && state > 0) {
        toastHandle(t('account.updateProcessing'));
      } else {
        setAddressChangeModal(true);
      }
    };

    const personalChangeHandle = () => {
      if (state && state > 0) {
        toastHandle(t('account.updateProcessing'));
      } else {
        setPersonalIDChangeModal(true);
      }
    };

    return (
      <>
        <PerfectScrollBar>
          <div className="profile mb-3">
            <div className="col-md-8 px-0 profile-state">
              <span className="float-left">
                {state && state > 0 ? t('account.updateProcessing') : ''}
              </span>
              <span
                className={`d-flex float-right justify-content-center align-items-center ${
                  auth.isVerified ? 'i' : 'd'
                }`}
              >
                {auth && auth.isVerified ? (
                  <>
                    <MdVerifiedUser className="mr-2" />
                    {t('txt-da-xacthuc')}
                  </>
                ) : (
                  <>
                    <GoUnverified className="mr-2" />
                    {t('txt-chua-xacthuc')}
                  </>
                )}
              </span>
            </div>

            <div className="profile-panel account-infor col-md-8">
              <div className="profile-panel-header">
                <span className="profile-panel-header__title">
                  {t('txt-label-account-info')}
                </span>
              </div>
              <div className="profile-panel-body">
                <div className="profile-panel-items">
                  <div className="profile-panel-item">
                    <span className="profile-panel-item__name">
                      {t('txt-label-phone-num')}
                    </span>
                    <span className="profile-panel-item__value">
                      {profile && formatPhoneNumber(profile.cphnNo)}
                    </span>
                    <span className="profile-panel-item__action">
                      {auth && auth.isVerified ? (
                        <span
                          className="btn-action"
                          onClick={() => emailPhoneChangeHandle()}
                        >
                          {t('txt-change')}
                        </span>
                      ) : (
                        <span
                          className="btn-action btn-unAuthen"
                          onClick={() => authenHandle()}
                        >
                          {t('txt-xacthuc')}
                        </span>
                      )}
                    </span>
                  </div>
                  <div className="profile-panel-item">
                    <span className="profile-panel-item__name">Email</span>
                    <span className="profile-panel-item__value">
                      {profile && profile.email}
                    </span>
                    <span className="profile-panel-item__action"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-panel account-infor col-md-8">
              <div className="profile-panel-header">
                <span className="profile-panel-header__title">
                  {t('txt-label-general-info')}
                </span>
              </div>
              <div className="profile-panel-body">
                <div className="profile-panel-items">
                  <div className="profile-panel-item">
                    <span className="profile-panel-item__name">
                      {t('txt-label-address')}
                    </span>
                    <span className="profile-panel-item__value">
                      {profile && profile.cttAddr}
                    </span>
                    <span className="profile-panel-item__action">
                      {auth && auth.isVerified ? (
                        <span
                          className="btn-action"
                          onClick={() => addressChangeHandle()}
                        >
                          {t('txt-change')}
                        </span>
                      ) : (
                        <span
                          className="btn-action btn-unAuthen"
                          onClick={() => authenHandle()}
                        >
                          {t('txt-xacthuc')}
                        </span>
                      )}
                    </span>
                  </div>
                </div>
                <div className="profile-panel-items">
                  <div className="profile-panel-item">
                    <span className="profile-panel-item__name">
                      {t('txt-label-gender')}
                    </span>
                    <span className="profile-panel-item__value">
                      {profile &&
                        (profile.gend === 'M'
                          ? t('txt-label-male')
                          : profile.gend === 'F'
                          ? t('txt-label-female')
                          : '-')}
                    </span>
                    <span className="profile-panel-item__action"></span>
                  </div>
                  <div className="profile-panel-item">
                    <span className="profile-panel-item__name">
                      {t('txt-label-nationality')}
                    </span>
                    <span className="profile-panel-item__value">
                      {profile && profile.natnlNm}
                    </span>
                    <span className="profile-panel-item__action"></span>
                  </div>
                  <div className="profile-panel-item">
                    <span className="profile-panel-item__name">
                      {t('txt-label-cif-no')}
                    </span>
                    <span className="profile-panel-item__value">
                      {token && token.cif}
                    </span>
                    <span className="profile-panel-item__action"></span>
                  </div>
                  <div className="profile-panel-item">
                    <span className="profile-panel-item__name">
                      {t('txt-label-vsd-no')}
                    </span>
                    <span className="profile-panel-item__value">
                      {defaultAccount && defaultAccount.vsdAcntNo}
                    </span>
                    <span className="profile-panel-item__action"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-panel personal-ID col-md-8">
              <div className="profile-panel-header">
                <span className="profile-panel-header__title">
                  {t('txt-PersonalID')}
                </span>
                <span className="profile-panel-header__action">
                  {auth && auth.isVerified ? (
                    <span
                      className="btn-action"
                      onClick={() => personalChangeHandle()}
                    >
                      {t('txt-change')}
                    </span>
                  ) : (
                    <span
                      className="btn-action btn-unAuthen"
                      onClick={() => authenHandle()}
                    >
                      {t('txt-xacthuc')}
                    </span>
                  )}
                </span>
              </div>
              <div className="profile-panel-body">
                <div className="profile-panel-items">
                  <div className="profile-panel-item">
                    <span className="profile-panel-item__name">
                      {t('txt-personalID-type')}
                    </span>
                    <span className="profile-panel-item__value">
                      {profile && profile.custIdKnd == '1'
                        ? t('txt-personalID-1')
                        : t('txt-personalID-2')}
                    </span>
                    <span className="profile-panel-item__action"></span>
                  </div>
                  <div className="profile-panel-item">
                    <span className="profile-panel-item__name">
                      {t('txt-personalID-number')}
                    </span>
                    <span className="profile-panel-item__value">
                      {profile && profile.custId}
                    </span>
                    <span className="profile-panel-item__action"></span>
                  </div>
                  <div className="profile-panel-item">
                    <span className="profile-panel-item__name">
                      {t('txt-label-fullname')}
                    </span>
                    <span className="profile-panel-item__value">
                      {profile && profile.custNm}
                    </span>
                    <span className="profile-panel-item__action"></span>
                  </div>
                  <div className="profile-panel-item">
                    <span className="profile-panel-item__name">
                      {t('txt-residence-addr')}
                    </span>
                    <span className="profile-panel-item__value">
                      {profile && profile.perdomAddr}
                    </span>
                    <span className="profile-panel-item__action"></span>
                  </div>
                  <div className="profile-panel-item">
                    <span className="profile-panel-item__name">
                      {t('txt-personalID-IssuedPlace')}
                    </span>
                    <span className="profile-panel-item__value">
                      {profile && noicap}
                    </span>
                    <span className="profile-panel-item__action"></span>
                  </div>
                  <div className="profile-panel-item">
                    <span className="profile-panel-item__name">
                      {t('txt-personalID-IssuedDate')}
                    </span>
                    <span className="profile-panel-item__value">
                      {profile && _formatDate2(profile.custIdIssuDate)}
                    </span>
                    <span className="profile-panel-item__action"></span>
                  </div>
                  {profile?.custIdExprDate && (
                    <div className="profile-panel-item">
                      <span className="profile-panel-item__name">
                        {t('txt-personalID-ExpireDate')}
                      </span>
                      <span className="profile-panel-item__value">
                        {profile && _formatDate2(profile.custIdExprDate)}
                      </span>
                      <span className="profile-panel-item__action"></span>
                    </div>
                  )}
                </div>

                {/* <FloatingLabelInput
                  type="text"
                  name="personalNum"
                  placeholder={t('account.personal.number')}
                  autoComplete="off"
                  maxLength={15}
                  fieldName={t('account.personal.number')}
                /> */}
              </div>
            </div>
          </div>
        </PerfectScrollBar>
        {openAddressChangeModal && (
          <AddressChangeModal
            getProfileInforState={getProfileInforState}
            handleLoadProfile={handleLoadProfile}
            profile={profile}
            toastHandle={toastHandle}
            showModal={openAddressChangeModal}
            handleCloseModal={() => setAddressChangeModal(false)}
          />
        )}

        {openEmailPhoneChangeModal && (
          <EmailPhoneChangeModal
            getProfileInforState={getProfileInforState}
            handleLoadProfile={handleLoadProfile}
            profile={profile}
            toastHandle={toastHandle}
            showModal={openEmailPhoneChangeModal}
            handleCloseModal={() => setEmailPhoneChangeModal(false)}
          />
        )}

        {openPersonalIDChangeModal && (
          <PersonalIDChangeModal
            getProfileInforState={getProfileInforState}
            handleLoadProfile={handleLoadProfile}
            toastHandle={toastHandle}
            profile={profile}
            noicap={noicap}
            showModal={openPersonalIDChangeModal}
            handleCloseModal={() => setPersonalIDChangeModal(false)}
          />
        )}
      </>
    );
  }
);

export default Index;
