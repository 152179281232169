import React, { useState, useEffect } from 'react';
import {
  BsCaretLeftFill,
  BsCaretRightFill,
  BsFillCaretDownFill,
  BsFillCaretUpFill,
} from 'react-icons/bs';
import DatagridGroup from '../../../table/DatagridGroup';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollBar from 'react-perfect-scrollbar';
import {
  changeSortDirection,
  changeSortField,
  getCWSnapshotRequest,
} from '../../../../containers/banggia/actions';
import { sortBy, map } from 'lodash';
import { stringToDate, stringToDate2 } from '../../../../util';

const Thead = ({ row1, row2, isPc, setIsPC, sortCallback }) => {
  const { isAsc, sortField } = useSelector((state) => state.priceBoard);
  const dispatch = useDispatch();

  useEffect(() => {}, [isAsc]);
  useEffect(() => {}, [sortField]);

  const showSortIcon = (field) => {
    if (sortField == field) {
      if (isAsc)
        return (
          <span onClick={() => _handleSort(field)}>
            <BsFillCaretUpFill
              className="fz_10 position-relative"
              style={{ marginLeft: '0.15rem' }}
            />
          </span>
        );
      else
        return (
          <span onClick={() => _handleSort(field)}>
            <BsFillCaretDownFill
              className="fz_10 position-relative"
              style={{ marginLeft: '0.15rem' }}
            />
          </span>
        );
    } else return null;
  };

  const _handleSort = (fieldName) => {
    // sortCallback(fieldName, !isAsc);
    dispatch(changeSortField(fieldName));
    dispatch(changeSortDirection());
  };

  return (
    <>
      <tr>
        {row1.map((r, i) => (
          <th
            rowSpan={r.rowSpan}
            colSpan={r.colSpan}
            className={r.className}
            key={`r1_${i}`}
            onClick={() => r.key && _handleSort(r.key)}
          >
            <span>{r.text}</span>
            {showSortIcon(r.key)}
          </th>
        ))}
      </tr>
      <tr>
        {row2.map((r, i) => (
          <th
            rowSpan={r.rowSpan}
            colSpan={r.colSpan}
            className={r.className}
            key={`r2_${i}`}
            onClick={() =>
              r.key &&
              !(r.key == 'changePc' || r.key == 'change') &&
              _handleSort(r.key)
            }
          >
            {r.key == 'changePc' || r.key == 'change' ? (
              <>
                <BsCaretLeftFill
                  className="tbl-toggle-left"
                  onClick={() => setIsPC()}
                />
                <span className="filterIcon" onClick={() => _handleSort(r.key)}>
                  {isPc ? '%' : '+/-'}
                </span>
                {showSortIcon(r.key)}
                <BsCaretRightFill
                  className="tbl-toggle-right"
                  onClick={() => setIsPC()}
                />
              </>
            ) : (
              <>
                <span>{r.text}</span>
                {showSortIcon(r.key)}
              </>
            )}
          </th>
        ))}
      </tr>
    </>
  );
};

const TblCW = withNamespaces('translations')(({ t, typeIndex }) => {
  const dispatch = useDispatch();
  const { currentG } = useSelector((state) => state.socket);
  const { snapshot, cwSnapshot, isAsc, sortField, cwSearchFilter, cwList } =
    useSelector((state) => state.priceBoard);
  const { symbol, issuer, fromDate, toDate } = cwSearchFilter;

  const [isPc, setIsPC] = useState(false);
  const [source, setSource] = useState([]);

  const sortHandle = (fieldname, isAsc) => {
    let _source = [...source];

    const mySort = (field) => {
      const rs = _source.sort(function (a, b) {
        return a[field] - b[field];
      });

      return rs;
    };

    if (sortField == 'sym')
      _source = isAsc
        ? sortBy(_source, 'sym')
        : sortBy(_source, 'sym').reverse();
    else {
      const _sort = mySort(fieldname);
      _source = isAsc ? _sort : _sort.reverse();
    }

    setSource(_source);

    // dispatch(setCWSnapshotRequest(_cwSnapshot));
  };

  const onChangeHandle = (symbol, issuer, fDate, tDate) => {
    if (!symbol?.length && !issuer?.length && !fDate && !tDate) {
      // clear case
      setSource(cwSnapshot);
    } else {
      debugger;
      const filterCW = cwSnapshot.filter(
        (x) =>
          (!!symbol?.length ? x.sym.includes(symbol) : true) &&
          (!!issuer?.length
            ? typeof x.issuer != undefined &&
              x.issuer.toUpperCase().includes(issuer.toUpperCase())
            : true) &&
          (fDate ? stringToDate2(x.lastTradingDate) >= fDate : true) &&
          (tDate ? stringToDate2(x.lastTradingDate) <= tDate : true)
      );

      // TODOS: What happen in realtime case,
      // maybe this snapshot is not lastest price cause update dom price mechanise
      // call api to get lastest price data of filterSnapshot befor set to cwSnapshot without change snapshot

      //   dispatch(setCWSnapshotRequest(filterSnapshot));
      setSource(filterCW);
    }
  };

  const row1 = [
    {
      key: 'sym',
      text: t('txt-symbol'), // Mã CK
      className: 'filterIcon',
      rowSpan: '2',
      colSpan: '1',
    },
    {
      key: 'issuer',
      text: t('cw.issuer'),
      className: 'filterIcon',
      rowSpan: '2',
      colSpan: '1',
    },
    {
      key: 'lastTradingDate',
      text: t('cw.lastTradingDate'),
      className: 'filterIcon',
      rowSpan: '2',
      colSpan: '1',
    },
    {
      key: 'r',
      text: t('txt-ref'), // T.C
      className: 'filterIcon',
      rowSpan: '2',
      colSpan: '1',
    },
    {
      key: 'c',
      text: t('txt-ceil'), // Trần
      className: 'filterIcon',
      rowSpan: '2',
      colSpan: '1',
    },
    {
      key: 'f',
      text: t('txt-floor'), // Sàn
      className: 'filterIcon',
      rowSpan: '2',
      colSpan: '1',
    },
    {
      text: t('txt-bid'), // Bên mua
      rowSpan: '1',
      colSpan: '6',
    },
    {
      text: t('txt-match'), // Khớp lệnh
      className: '',
      rowSpan: '1',
      colSpan: '3',
    },
    {
      text: t('txt-ask'), // Bên bán
      rowSpan: '1',
      colSpan: '6',
    },
    {
      key: 'lot',
      text: t('txt-total-vol'), // Tổng KL
      className: 'filterIcon',
      rowSpan: '2',
      colSpan: '1',
    },
    {
      key: 'highPrice',
      text: t('txt-high'), // Cao
      className: 'filterIcon',
      rowSpan: '2',
      colSpan: '1',
    },
    {
      key: 'lowPrice',
      text: t('txt-low'), // Thấp
      className: 'filterIcon',
      rowSpan: '2',
      colSpan: '1',
    },
    {
      key: 'avePrice',
      text: t('txt-ave'), // Trung bình
      className: 'filterIcon',
      rowSpan: '2',
      colSpan: '1',
    },
    {
      text: t('txt-foreign'), // Khối ngoại
      rowSpan: '1',
      colSpan: '2',
    },
    {
      text: t('txt-underlying'),
      rowSpan: '1',
      colSpan: '2',
    },
    {
      key: 'bEPoint',
      text: t('txt-breakeven-point'),
      className: 'filterIcon',
      rowSpan: '2',
      colSpan: '1',
    },
    {
      key: 'sp',
      text: 'Strike Price',
      text: t('txt-strike-price'),
      className: 'filterIcon',
      rowSpan: '2',
      colSpan: '1',
    },
    {
      key: 'cr',
      text: t('txt-conversion-ratio'),
      className: 'filterIcon',
      rowSpan: '2',
      colSpan: '1',
    },
    {
      key: 'leverage',
      text: t('txt-leverage'),
      className: 'filterIcon',
      rowSpan: '2',
      colSpan: '1',
    },
  ];

  const row2 = [
    {
      key: 'bP3',
      text: `${t('txt-price')} 3`, // Giá 3
      className: 'filterIcon fw-500',
      rowSpan: '1',
      colSpan: '1',
    },
    {
      key: 'bV3',
      text: `${t('txt-vol')} 3`, // KL 3
      className: 'filterIcon',
      rowSpan: '1',
      colSpan: '1',
    },
    {
      key: 'bP2',
      text: `${t('txt-price')} 2`, // Giá 2
      className: 'filterIcon fw-500 ',
      rowSpan: '1',
      colSpan: '1',
    },
    {
      key: 'bV2',
      text: `${t('txt-vol')} 2`, // KL 2
      className: 'filterIcon',
      rowSpan: '1',
      colSpan: '1',
    },
    {
      key: 'bP1',
      text: `${t('txt-price')} 1`, // Giá 1
      className: 'filterIcon fw-500 ',
      rowSpan: '1',
      colSpan: '1',
    },
    {
      key: 'bV1',
      text: `${t('txt-vol')} 1`, // KL 1
      className: 'filterIcon',
      rowSpan: '1',
      colSpan: '1',
    },
    {
      key: 'lastPrice',
      text: t('txt-price'), // Giá
      className: 'filterIcon',
      rowSpan: '1',
      colSpan: '1',
    },
    {
      key: 'lastVolume',
      text: t('txt-vol'), // KL
      className: 'filterIcon',
      rowSpan: '1',
      colSpan: '1',
    },
    {
      key: `${isPc ? 'changePc' : 'change'}`,
      text: `${isPc ? '%' : '+/-'}`, // +/-
      className: 'po-relative',
      rowSpan: '1',
      colSpan: '1',
    },
    {
      key: 'oP1',
      text: `${t('txt-price')} 1`, // Giá 1
      className: 'filterIcon fw-500 ',
      rowSpan: '1',
      colSpan: '1',
    },
    {
      key: 'oV1',
      text: `${t('txt-vol')} 1`, // KL 1
      className: 'filterIcon',
      rowSpan: '1',
      colSpan: '1',
    },
    {
      key: 'oP2',
      text: `${t('txt-price')} 2`, // Giá 2
      className: 'filterIcon fw-500',
      rowSpan: '1',
      colSpan: '1',
    },
    {
      key: 'oV2',
      text: `${t('txt-vol')} 2`, // KL 2
      className: 'filterIcon',
      rowSpan: '1',
      colSpan: '1',
    },
    {
      key: 'oP3',
      text: `${t('txt-price')} 3`, // Giá 3
      className: 'filterIcon fw-500',
      rowSpan: '1',
      colSpan: '1',
    },
    {
      key: 'oV3',
      text: `${t('txt-vol')} 3`, // KL 3
      className: 'filterIcon',
      rowSpan: '1',
      colSpan: '1',
    },
    {
      key: 'fBVol',
      text: t('txt-buy'), // Mua
      className: 'filterIcon',
      rowSpan: '1',
      colSpan: '1',
    },
    {
      key: 'fSVolume',
      text: t('txt-sell'), // Bán
      className: 'filterIcon',
      rowSpan: '1',
      colSpan: '1',
    },
    {
      key: 'uPrice',
      text: t('txt-price'), // Symbol
      className: 'filterIcon',
      rowSpan: '1',
      colSpan: '1',
    },
    {
      key: 'uChange',
      text: t('txt-change'), // Price
      className: 'filterIcon',
      rowSpan: '1',
      colSpan: '1',
    },
  ];

  const colgroup = (
    <colgroup>
      <col width="72px"></col>
      <col></col>
      <col width="80px"></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
      <col></col>
    </colgroup>
  );

  const heightTb = 195 + (typeIndex === 1 ? 130 : 70);

  useEffect(() => {
    sortHandle(sortField, isAsc);
  }, [sortField, isAsc]);

  useEffect(() => {
    if (!!cwList?.length) {
      const symbols = map(cwList, 'code');
      dispatch(getCWSnapshotRequest(symbols.join(',')));
    }
  }, [cwList]);

  useEffect(() => {
    if (!!cwSnapshot?.length) {
      onChangeHandle(symbol, issuer, fromDate, toDate);
    }
  }, [cwSnapshot, symbol, issuer, fromDate, toDate]);

  return (
    <>
      <div>
        <DatagridGroup
          resource={[]}
          classname="cw-table table table-bordered table-fix"
          isPc={isPc}
          colgroup={colgroup}
        >
          <Thead
            isPc={isPc}
            setIsPC={() => setIsPC(!isPc)}
            row1={row1}
            row2={row2}
          />
        </DatagridGroup>
      </div>

      <PerfectScrollBar style={{ height: `calc(100vh - ${heightTb}px)` }}>
        <DatagridGroup
          resource={source}
          classname="cw-table table table-fix price-table border-top-0"
          isPc={isPc}
          key={currentG}
          colgroup={colgroup}
          isCW={true}
        />
        <div
          style={{
            height: '200px',
            marginTop: '10px',
            color: '#c5c5c6',
            textAlign: 'center',
            fontSize: '13px',
            lineHeight: '16px',
          }}
        >
          <span className="text-tb-copyright">{t('txt-copyright')}</span>
        </div>
      </PerfectScrollBar>
    </>
  );
});

export default TblCW;
