import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import ReactSelect from 'react-select';
import Page from '../../../../../components/print/Page';
import PagingCustom from '../../../../../components/common/paging/PagingCustom';
import { take, takeRight } from 'lodash';
import {
  _formatDate,
  PARTNER_REQUEST_TYPE_OPTION,
  useDebounce,
} from '../../../../../util';
import DatePicker from '../../../../../components/datePicker2';
import Checkbox from '../../../../../components/common/checkbox';
import ModalChangeFee from '../../modal/modalChangeFee';
import { v4 as uuidv4 } from 'uuid';
import {
  PARTNER_REQUEST_STATUS,
  PARTNER_REQUEST_TYPE,
  PARTNER_ROLE,
} from '../../../../../util/constant';
import { getCommonRequest } from '../../../../customer/actions';
import moment from 'moment';
import ModalCancelRequest from '../../modal/modalCancelRequest';
import { setAuth } from '../../../../client/actions';

const PAGE_SIZES_OPTIONS = [
  { value: 15, label: '15' },
  { value: 30, label: '30' },
  { value: 50, label: '50' },
];

const RequestList = withNamespaces('translations')(({ t }) => {
  const { defaultAccount } = useSelector((state) => state.socket);
  const { auth, token } = useSelector((state) => state.client);
  const [showModalCancel, setShowModalCancel] = useState(false);
  const theme = useSelector((state) => state.client.currentTheme);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [continued, setContinued] = useState(false);
  const [pageSizeOption, setPageSizeOption] = useState(PAGE_SIZES_OPTIONS[0]);
  const [requestList, setRequestList] = useState([]);
  const [dataSelect, setDataSelect] = useState();
  const [selectList, setSelectList] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const currentDate = new Date();
  const _fromDate = new Date(currentDate.setDate(currentDate.getDate() - 180));
  const [fromDate, setFromDate] = useState(_fromDate);
  const [toDate, setToDate] = useState(new Date());
  const [showModalRemoveCustomer, setShowModalRemoveCustomer] = useState(false);
  const [reqTp, setReqTp] = useState(PARTNER_REQUEST_TYPE_OPTION[0]);
  const [vsdAcntNo, setVsdAcntNo] = useState();
  const [showModalChangeFee, setShowModalChangeFee] = useState(false);
  const [status, setStatus] = useState();
  const [dataBackup, setDataBackup] = useState();

  const dispatch = useDispatch();

  const handleNextPage = (step) => {
    handleQueryPage(page + step);
  };

  const handleQueryPage = (page) => {
    if (!requestList) return;
    const _pageSize = pageSizeOption?.value ?? PAGE_SIZES_OPTIONS[0].value;
    const totalPage =
      _pageSize != 0
        ? requestList && requestList.length > 0
          ? Math.ceil(requestList.length / _pageSize)
          : 1
        : 1;

    const _takeContent = take(requestList, _pageSize * page);
    const _contentShow = takeRight(
      _takeContent,
      _takeContent.length - _pageSize * (page - 1)
    );

    if (_pageSize == 0) {
      setRequestList(requestList);
    } else {
      setRequestList(_contentShow);
    }
    setPage(page);
    setContinued(page < totalPage);
    setTotalPage(totalPage);
  };

  const pageSizeOptions = [
    ...PAGE_SIZES_OPTIONS,
    { value: 0, label: 'txt-all' },
  ];
  const textSearchDebounce = useDebounce(vsdAcntNo, 500);

  const _handleLoadData = () => {
    if (!token) return;

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getReqList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
        acntNo: defaultAccount.acntNo,
        roleTp: PARTNER_ROLE.REFERRER + '',
        makerId: activeTab === 0 ? defaultAccount.acntNo : '',
        vsdAcntNo: textSearchDebounce,
        reqTp: reqTp.value,
        frDt: moment(fromDate).format('YYYYMMDD'),
        toDt: moment(toDate).format('YYYYMMDD'),
      },
    };
    const callback = (data) => {
      if (activeTab === 1) {
        setDataBackup(data.filter((el) => el.side === '2'));
        setRequestList(data.filter((el) => el.side === '2'));
      } else {
        setDataBackup(data);
        setRequestList(data);
      }
    };

    dispatch(getCommonRequest(resData, callback, true));
  };

  useEffect(() => {
    _handleLoadData();
  }, [activeTab, fromDate, toDate, reqTp, textSearchDebounce]);

  const handleClearData = () => {
    setRequestList([]);
    setVsdAcntNo('');
    setReqTp(PARTNER_REQUEST_TYPE_OPTION[0]);
    setFromDate(_fromDate);
    setToDate(new Date());
  };

  return (
    <div className={`${theme?.name || 'dark'} page-partner-list`}>
      <ModalChangeFee
        t={t}
        showModal={showModalChangeFee}
        handleClose={() => setShowModalChangeFee(false)}
        dataCustomer={dataSelect}
        isEdit={false}
        isConfirm={activeTab != 0 && dataSelect?.reqStat == '0'}
        isCustomer={activeTab == 0}
        submitApproveCallback={() => {
          _handleLoadData();
          setShowModalChangeFee(false);
        }}
        isCompare={dataSelect?.reqStat == '0'}
      />
      <ModalCancelRequest
        t={t}
        showModal={showModalCancel}
        handleClose={() => setShowModalCancel(false)}
        handleLoadData={() => {
          _handleLoadData();
        }}
        dataRequest={dataSelect}
      />
      <div className="p-3">
        <div className="d-flex gap-16">
          <span
            onClick={() => {
              setActiveTab(0);
              handleClearData();
            }}
            className={`text text--lg text--light cursor-pointer ${
              activeTab === 0 ? 'activeTab text--light' : 'text--light3'
            }`}
          >
            {t('partner.myRequest')}{' '}
          </span>
          <span
            onClick={() => {
              setActiveTab(1);
              handleClearData();
            }}
            className={`text text--lg cursor-pointer ${
              activeTab === 1 ? 'activeTab text--light' : 'text--light3'
            }`}
          >
            {t('partner.customerRequest')}{' '}
          </span>
        </div>
        <div className="d-flex gap-16 mt-4">
          <DatePicker
            startDate={fromDate}
            endDate={toDate}
            callback={(from, to) => {
              setFromDate(_formatDate(from));
              setToDate(_formatDate(to));
            }}
          />
          <ReactSelect
            options={PARTNER_REQUEST_TYPE_OPTION}
            defaultValue={PARTNER_REQUEST_TYPE_OPTION[0]}
            className="filter-control-select w-160 p-0"
            classNamePrefix="filter-control-select"
            isSearchable={false}
            value={reqTp}
            onChange={(e) => {
              setReqTp(e);
            }}
            getOptionLabel={(option) => t(option.label)}
            getOptionValue={(option) => option.value}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
          <ReactSelect
            options={PARTNER_REQUEST_STATUS}
            defaultValue={PARTNER_REQUEST_STATUS[0]}
            className="filter-control-select w-160 p-0"
            classNamePrefix="filter-control-select"
            isSearchable={false}
            value={status}
            onChange={(e) => {
              if (e.value === PARTNER_REQUEST_STATUS[0].value) {
                setRequestList(dataBackup);
              } else {
                setRequestList(
                  dataBackup.filter((el) => el.reqStat === e.value)
                );
              }

              setStatus(e);
            }}
            getOptionLabel={(option) => t(option.label)}
            getOptionValue={(option) => option.value}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
          <div className="w-160 p-0">
            <input
              placeholder={'VSD ' + t('partner.customer')}
              className="search__input"
              value={vsdAcntNo}
              onChange={(e) => {
                setVsdAcntNo(e.target.value);
              }}
            />
          </div>
        </div>
        {selectList.length > 0 && activeTab === 0 && (
          <div className="d-flex gap-16 mt-2">
            <button className={'btn btn-danger-secondary d'}>
              <span className="icon iClose mr-1 bg-red"></span>
              {t('partner.cancelMultipleRequest') + ` (${selectList.length})`}
            </button>
          </div>
        )}
      </div>
      <Page
        style={{
          maxHeight: 'calc(100vh - 320px)',
          overflow: 'auto',
          position: 'relative',
        }}
      >
        <table className="table table-bordered tbl-list scroll-table">
          <thead>
            <tr>
              {/*{activeTab === 0 && <th style={{ width: '50px' }}></th>}*/}
              <th className="text-center">{t('partner.createdDate')}</th>
              <th className="text-center">{t('partner.VSDAccount')}</th>
              <th className="text-center">{t('txt-label-customer-name')}</th>
              <th className="text-center">{t('txt-sub-account')}</th>
              <th className="text-center">{t('partner.requestType')}</th>
              <th className="text-center">{t('partner.status')}</th>
              <th style={{ width: '120px' }}>{t('txt-action')}</th>
            </tr>
          </thead>
          <tbody>
            {requestList &&
              !!requestList.length &&
              requestList.map((item, i) => {
                // const check = selectList.findIndex((el) => el === i);
                return (
                  <tr key={i}>
                    {/*{activeTab === 0 && (*/}
                    {/*  <td className="">*/}
                    {/*    {item.reqStat === '0' && (*/}
                    {/*      <div*/}
                    {/*        className="d-flex justify-content-center cursor-pointer"*/}
                    {/*        onClick={() => {*/}
                    {/*          if (check !== -1) {*/}
                    {/*            setSelectList(*/}
                    {/*              selectList.filter((el) => el !== i)*/}
                    {/*            );*/}
                    {/*          } else {*/}
                    {/*            setSelectList(selectList.concat(i));*/}
                    {/*          }*/}
                    {/*        }}*/}
                    {/*      >*/}
                    {/*        <Checkbox*/}
                    {/*          type="checkbox"*/}
                    {/*          className="checkbox-group partner-checkbox"*/}
                    {/*          checked={check !== -1}*/}
                    {/*        />*/}
                    {/*      </div>*/}
                    {/*    )}*/}
                    {/*  </td>*/}
                    {/*)}*/}
                    <td className="text-center text--light3">
                      {moment(item.reqDate).format('DD/MM/YYYY')}
                    </td>
                    <td className="text-center text--light3">
                      {item.vsdAcntNo}
                    </td>
                    <td className="text-center text--light3">{item.custNm}</td>
                    <td className="text-center fw-500">
                      {!item.subAcntNo ? t('inday.allSubAcc') : item.subAcntNo}
                    </td>
                    <td
                      className={
                        'text-center fw-500 ' +
                          PARTNER_REQUEST_TYPE_OPTION.find(
                            (el) => el.value === item.reqTp
                          )?.color || 'text--light'
                      }
                    >
                      {t(
                        PARTNER_REQUEST_TYPE_OPTION.find(
                          (el) => el.value === item.reqTp
                        )?.label
                      )}
                    </td>
                    <td
                      className={
                        'text-center ' +
                          PARTNER_REQUEST_STATUS[Number(item.reqStat) + 1]
                            ?.color || 'text--light'
                      }
                    >
                      {t(
                        PARTNER_REQUEST_STATUS[Number(item.reqStat) + 1]?.label
                      )}
                    </td>
                    <td className="text-right text--light fw-500">
                      <div className="d-flex justify-content-center gap-8">
                        {[PARTNER_REQUEST_TYPE.changeFee].includes(
                          item.reqTp
                        ) && (
                          <button
                            onClick={() => {
                              if (auth && auth.isVerified) {
                                setDataSelect(item);
                                if (
                                  item.reqTp === PARTNER_REQUEST_TYPE.changeFee
                                ) {
                                  setShowModalChangeFee(true);
                                  return;
                                }
                              } else {
                                dispatch(setAuth());
                              }
                            }}
                            className={`btn d-flex ${
                              auth && auth.isVerified
                                ? 'btn-brand-secondary i'
                                : 'btn--authen'
                            }`}
                          >
                            <span className="icon iEye"></span>
                          </button>
                        )}
                        {activeTab === 0 && item.reqStat === '0' ? (
                          <button
                            className={`btn d-flex ${
                              auth && auth.isVerified
                                ? 'btn-danger-secondary d'
                                : 'btn--authen'
                            }`}
                            onClick={() => {
                              if (auth && auth.isVerified) {
                                setShowModalCancel(true);
                                setDataSelect(item);
                              } else {
                                dispatch(setAuth());
                              }
                            }}
                          >
                            <span
                              className={
                                auth && auth.isVerified
                                  ? 'icon iClose bg-red'
                                  : 'icon iClose'
                              }
                            ></span>
                          </button>
                        ) : null}
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {(!requestList || !requestList.length) && (
          <p className="text-center fz_14 mt-2 title-time">
            {t('txt-no-data-found')}
          </p>
        )}
      </Page>
      {/*{requestList && (*/}
      {/*  <div className="pb-5">*/}
      {/*    <PagingCustom*/}
      {/*      handleNextPage={() => handleNextPage(1)}*/}
      {/*      handlePrevPage={() => handleNextPage(-1)}*/}
      {/*      page={page}*/}
      {/*      totalPage={totalPage}*/}
      {/*      continued={continued}*/}
      {/*      t*/}
      {/*      hasPageSize={true}*/}
      {/*      pageSizeOption={pageSizeOption}*/}
      {/*      _handleSetPageSize={setPageSizeOption}*/}
      {/*      pageSizeOptions={pageSizeOptions}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
});

export default RequestList;
