import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import '../index.scss';
import { v4 as uuidv4 } from 'uuid';
import { handleApiErrors } from '../../../../lib/api-errors';
import AuthenStep from '../../../../components/common/auth';
import {
  PARTNER_FEE_POLICY,
  PARTNER_REQUEST_ADD_REMOVE_TYPE,
  PARTNER_REQUEST_APPROVE_REJECT_TYPE,
  PARTNER_REQUEST_TYPE,
  PARTNER_SERVICE_TYPE,
  PARTNER_SIDE_REQUEST,
} from '../../../../util/constant';
import { getCommonRequest } from '../../../customer/actions';
import { setToast } from '../../../client/actions';
import { numberFormat } from '../../../../util';

const ViewFeeDefault = withNamespaces('translations')(
  ({ t, listFee, viewFull }) => {
    const { auth, token } = useSelector((state) => state.client);
    const newListFee = viewFull
      ? listFee
      : listFee?.filter((el) =>
          token.custInfo.normal.find(
            (item) => item.subAcntClss === el.subAcntClss
          )
        );

    return newListFee?.length > 0
      ? Object.keys(newListFee).map((el, index) => {
          return (
            <div key={index} className="mt-4 d-flex flex-column gap-12">
              <div className="text text--md text--light">
                {newListFee[el]?.serTpNm}
              </div>
              {newListFee[el]?.policy?.map((item) => (
                <div className="d-flex align-items-center justify-content-between">
                  {newListFee[el]?.policy?.length === 1 ? (
                    <div className="text text--sm text--light">
                      {t('partner.rateUsed' + newListFee[el]?.subAcntClss) +
                        `:  `}
                    </div>
                  ) : (
                    <div className="text text--sm text--light">
                      {t('partner.rate' + newListFee[el]?.subAcntClss) +
                        ' ' +
                        numberFormat(item?.frVal, 0, '0') +
                        (item?.toVal
                          ? '   ->   ' + numberFormat(item?.toVal, 0, '0')
                          : '') +
                        ` (${
                          newListFee[el]?.subAcntClss === 'D'
                            ? t('contract.short.c')
                            : 'VND'
                        }):  `}
                    </div>
                  )}
                  <div className="text text--sm text--light">
                    {numberFormat(item?.fee, 2, '0') + ' ' + item?.levyTp}
                  </div>
                </div>
              ))}
            </div>
          );
        })
      : null;
  }
);

export default ViewFeeDefault;
