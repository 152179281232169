import React, { useState } from 'react';
import { Field } from 'formik';
import { v4 as uuidv4, validate } from 'uuid';
import './index.scss';

const FloatingLabelInput = ({ props, fieldName }) => {
  const uuid = uuidv4();
  const [show, setShow] = useState(false);
  return (
    // <div className="floating-label">
    //   <label
    //     className="floating-label__label"
    //     htmlFor={uuid}
    //     onClick={() => setShow(true)}
    //   >
    //     {fieldName}
    //   </label>
    //   {show && (
    //     <input
    //       className="floating-label__input"
    //       type="email"
    //       name="email"
    //       id={uuid}
    //       placeholder="jane.appleseed@example.com"
    //     />
    //   )}
    // </div>
    <div className="floating-label">
      <input className="floating-label__input" type="text" />
      <label className="floating-label__label">Quan test</label>
    </div>
  );
};

export default FloatingLabelInput;
