import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field } from 'formik';
import { maxLength, required } from '../../../util/formikValidation';
import { withNamespaces } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  makeGetProfile,
  makeGetToken,
  makeGetDefaultAccount,
} from '../../../lib/selector';
import {
  updateProfileRequest,
  resetUpdateProfile,
} from '../../../containers/customer/actions';
import { _formatDate2, _hideMidStr } from '../../../util';
import { v4 as uuidv4 } from 'uuid';
import { setToast } from '../../../containers/client/actions';
import AuthenStep from '../../common/auth';

const FormAddressChange = withNamespaces('translations')(
  ({ t, nextStepHandle, _address, profile, toastHandle }) => {
    const elRef = useRef(null);
    useEffect(() => {
      if (elRef) elRef.current.focus();
    }, []);

    return (
      <Formik
        initialValues={{
          address: _address,
        }}
        onSubmit={(values) => {
          const { address } = values;
          if (profile?.cttAddr == address) {
            toastHandle(t('account.personalModal.compare'));
            return;
          }
          nextStepHandle(values.address);
        }}
      >
        {({ errors, touched }) => (
          <Form className="change-form" autoComplete="off">
            <div className="order-text">
              <span className="icon iLocation"></span>
              <Field
                innerRef={elRef}
                type="text"
                name="address"
                placeholder={t('txt-label-address')}
                autoComplete="off"
                validate={required}
                maxLength={500}
              />
            </div>
            {touched.address && errors.address && (
              <div className="d my-2 error">{errors.address}</div>
            )}
            <button className="btn btn-submit" type="submit">
              {t('txt-update')}
            </button>
          </Form>
        )}
      </Formik>
    );
  }
);

const AddressChangeModal = withNamespaces('translations')(
  ({
    handleCloseModal,
    showModal,
    t,
    handleLoadProfile,
    getProfileInforState,
    profile,
  }) => {
    const dispatch = useDispatch();
    const [step, setStep] = useState(1);
    const [address, setAddress] = useState(profile?.cttAddr);
    const token = useSelector(makeGetToken());
    const defaultAccount = useSelector(makeGetDefaultAccount());
    const profileUpdateRequest = useSelector(
      (state) => state.customer?.profileUpdateRequest
    );

    const profileUpdateRequestSuccess = useSelector(
      (state) => state.customer?.profileUpdateRequestSuccess
    );

    const profileUpdateRequestError = useSelector(
      (state) => state.customer?.profileUpdateRequestError
    );

    const [oneTimeToken, setOneTimeToken] = useState('');
    const [authType, setAuthType] = useState(null);

    const submitHandle = () => {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'changeCustInfo',
        rqId: uuid,
        channel: 'WTS',
        type: '6',
        token: oneTimeToken ?? '',
        data: {
          acntNo: defaultAccount.acntNo,
          cif: token.cif,
          chngTp: '03',
          cttAddr: address,
        },
      };

      dispatch(updateProfileRequest(params));
    };

    useEffect(() => {
      if (!profileUpdateRequest) {
        if (profileUpdateRequestSuccess) {
          handleCloseModal();
          handleLoadProfile();
          getProfileInforState();
          toastHandle(t('account.personalModal.updateSuccess'));
          dispatch(resetUpdateProfile());
        }

        if (
          !profileUpdateRequestSuccess &&
          Array.isArray(profileUpdateRequestError) &&
          profileUpdateRequestError.length > 0
        ) {
          toastHandle(profileUpdateRequestError[0].body);
          dispatch(resetUpdateProfile());
        }
      }
    }, [profileUpdateRequest]);

    const toastHandle = (msg) => {
      const toastMsg = {
        id: Math.random(),
        msg: msg,
        title: t('txt-notice'),
      };
      dispatch(setToast(toastMsg));
    };

    return (
      <div>
        <Modal
          show={showModal}
          dialogClassName="address-change-modal"
          backdropClassName="address-change-backdrop"
          contentClassName="address-change-content"
          onEscapeKeyDown={handleCloseModal}
          centered
          onHide={handleCloseModal}
          style={{ zIndex: 11111 }}
        >
          <span className="title">{t('account.addressModal.changeTitle')}</span>
          {step == 1 ? (
            <span className="desc">{t('account.addressModal.changeDesc')}</span>
          ) : (
            <span className="d-flex flex-column">
              <span className="desc">
                {t('account.addressModal.newContact')}
              </span>
              <span>{address}</span>
            </span>
          )}
          {step == 1 ? (
            <FormAddressChange
              toastHandle={toastHandle}
              _address={address}
              nextStepHandle={(value) => {
                setAddress(value);
                setStep(2);
              }}
            />
          ) : (
            <>
              <AuthenStep
                setOneTimeToken={setOneTimeToken}
                // setAuthType={setAuthType}
                trackMixpanel
              />

              <div className="confirm-btn-section">
                <button
                  className="btn btn-cancel"
                  type="button"
                  onClick={() => {
                    setStep(1);
                  }}
                >
                  {t('account.authen.back')}
                </button>
                <button
                  className="btn btn-submit"
                  type="submit"
                  disabled={!oneTimeToken}
                  onClick={() => submitHandle()}
                >
                  {t('account.authen.confirm')}
                </button>
              </div>
            </>
          )}
        </Modal>
      </div>
    );
  }
);

export default AddressChangeModal;
