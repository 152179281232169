import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setChangeFeeOpenModal } from '../../../../customer/actions';
import ModalCancelService from '../../modal/modalCancelService';
import ModalChangeFee from '../../modal/modalChangeFee';
import ModalRegisterService from '../../modal/modalRegisterService';
import { _formatDate2 } from '../../../../../util';
import Page from '../../../../../components/print/Page';
import React from 'react';
import { setAuth } from '../../../../client/actions';

const ServiceSection = withNamespaces('translations')(({ t, onSuccess }) => {
  const { serList4Cus } = useSelector((state) => state.customer);
  const { changeFeeOpenModal } = useSelector((state) => state.customer);
  const { auth } = useSelector((state) => state.client);
  const dispatch = useDispatch();

  if (!serList4Cus) {
    return <div />;
  }
  const { services, canChangeFeeYN } = serList4Cus;

  return (
    <>
      <div className="section service">
        <div className="title border-bottom">
          <span className="text text--md text--light">
            {t('partner.serviceType')}
          </span>
        </div>
        <Page
          style={{
            maxHeight: 'calc(100vh - 440px)',
            overflow: 'auto',
            position: 'relative',
          }}
        >
          <table className="table table-bordered tbl-list scroll-table">
            <thead>
              <tr>
                <th>{t('partner.serviceLabel.type')}</th>
                <th>{t('partner.serviceLabel.feePolicy')}</th>
                <th>{t('partner.serviceLabel.activeDate')}</th>
                <th>{t('partner.serviceLabel.status')}</th>
                <th style={{ width: 72 }}></th>
              </tr>
            </thead>
            <tbody>
              {(services || [])
                .sort((a, b) => a.serTp - b.serTp)
                .map((service, index) => (
                  <tr key={index}>
                    <td>{service.serTpNm}</td>
                    <td>{service.feePolicy}</td>
                    <td>{_formatDate2(service.startDate || '')}</td>
                    <td>{t(`partner.serviceStatus.${service.status}`)}</td>
                    <td>
                      {service.registYN === 'Y' && (
                        <ModalRegisterService
                          service={service}
                          onSuccess={onSuccess}
                        />
                      )}
                      {service.unregistYN === 'Y' && (
                        <ModalCancelService
                          service={service}
                          onSuccess={onSuccess}
                        />
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Page>
        {canChangeFeeYN === 'Y' && (
          <button
            className={`btn w-28 w-100 ${
              auth && auth.isVerified ? 'btn-edit' : 'btn--authen'
            }`}
            onClick={() => {
              if (auth && auth.isVerified) {
                dispatch(setChangeFeeOpenModal(true));
              } else {
                dispatch(setAuth());
              }
            }}
          >
            <span className="icon iEdit" />
            <span>{t('partner.change_fee')}</span>
          </button>
        )}
      </div>
      <ModalChangeFee
        t={t}
        showModal={changeFeeOpenModal}
        handleClose={() => {
          dispatch(setChangeFeeOpenModal(false));
        }}
        isConfirm={false}
        isEdit
        isCustomer
      />
    </>
  );
});

export default ServiceSection;
