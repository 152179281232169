export default {
  'text-multi-chart': 'Bố cục biểu đồ',
  'text-valid-password-lenth': 'Có ít nhất 8 ký tự và tối đa 32 ký tự',
  'text-password-must-one-letter': 'Có ít nhất 1 chữ cái (A-Z, a-z)',
  'text-password-must-one-digit': 'Có ít nhất 1 chữ số (0-9)',
  'text-password-must-one-special':
    'Có ít nhất 1 ký tự đặc biệt trong @$!%*#?&_)(;/\'".,/[]{}+-',
  'text-password-must-diff_old_pass': 'Không sử dụng lại mật khẩu cũ',
  'text-password-matches-confirm-pass':
    'Hai lần nhập mật khẩu mới phải khớp nhau',
  'text-hint-content':
    '* Tránh sử dụng ID, Số điện thoại trong mật khẩu của bạn',
  'txt-board': 'Bảng giá',
  'txt-derivative': 'Phái sinh',
  'txt-trade': 'Giao dịch',
  'txt-trade-acronym': 'GD',
  'txt-cash-transfer': 'nạp/rút tiền',
  'txt-asset': 'Tài sản',
  'txt-reports': 'Báo cáo',
  'txt-total-asset': 'Tổng tài sản',
  'txt-cash-advance': 'Ứng trước tiền bán',
  'txt-stock-transfer': 'Chuyển cổ phiếu',
  'txt-money-transfer': 'Chuyển tiền giữa các tiểu khoản',
  'txt-margin-debt': 'Công nợ margin',
  'txt-deposit-withdrawal': 'Nạp / rút tiền',
  'txt-reference-subcription': 'Tra cứu / đăng ký quyền',
  'txt-hold-unhold': 'Phong tỏa / Giải toả tiền',

  'txt-triggerPriceTP-long': 'Giá kich hoạt phải lớn hơn giá thị trường',
  'txt-triggerPriceTP-short': 'Giá kich hoạt phải nhỏ hơn giá thị trường',
  'txt-triggerPriceSL-long': 'Giá kich hoạt phải nhỏ hơn giá thị trường',
  'txt-triggerPriceSL-short': 'Giá kich hoạt phải lớn hơn giá thị trường',
  'txt-estProfit': 'Lãi dự kiến',
  'txt-estLoss': 'Lỗ dự kiến',
  'txt-validDate': 'Ngày hiệu lực',
  'txt-condition-trigger': 'Điều kiện KH',
  'txt-TP': 'Giá KH',
  'txt-OP': 'Giá đặt',
  'txt-conditional-type': 'Loại điều kiện',
  'txt-trigger-date': 'Ngày kich hoạt',
  'txt-trigger-order-no': 'SHL kích hoạt',
  'txt-executed': 'Đã kích hoạt',
  'txt-condition-orders-list': 'Sổ lệnh điều kiện',
  'txt-so': 'Dừng giới hạn',
  'txt-order-cancel-confirmation': 'Xác nhận hủy lệnh',
  'txt-order-edit': 'Sửa lệnh',

  'txt-order': 'Đặt lệnh',
  'txt-quickOrder': 'Đặt lệnh',
  'txt-login': 'Đăng nhập',
  'txt-xacthuc': 'Xác thực',
  'txt-cancel': 'Huỷ',
  'txt-re-login': 'Đăng nhập lại',
  'txt-order-confirm': 'Xác nhận lệnh',
  'txt-ignore': 'Bỏ qua',
  'txt-detail': 'Chi tiết mã',
  'txt-move': 'Chuyển',
  'txt-moved': 'Đã chuyển',
  'txt-registration': 'Đăng ký mua',
  'txt-accept': 'Xác nhận',
  'txt-clear': 'Xoá',
  'txt-regis-bank-account': 'Đăng ký tài khoản ngân hàng',
  'txt-change': 'Thay đổi',
  'txt-change-per': 'Thay đổi %',
  'txt-change-pass': 'Đổi mật khẩu',
  'txt-change-telpin': 'Đổi telpin',
  'txt-change-account': 'Đổi tài khoản',
  'txt-change-login-name': 'Đổi tên đăng nhập',
  'txt-register': 'Đăng ký',
  'txt-btn-order-cond': 'Đặt lệnh ĐK',
  'txt-day': 'ngày',
  'txt-equities': 'Cơ sở',
  'txt-notice': 'Thông báo',
  'txt-search': 'Tìm kiếm',
  'txt-add-symbol': 'Thêm mã CK',
  'txt-da-xacthuc': 'Đã xác thực OTP/Matrix',
  'txt-chua-xacthuc': 'Chưa xác thực OTP/Matrix',
  'txt-chua-xacthuc-intro': 'Nhấn <0>xác thực</0> để thực hiện giao dịch',
  'txt-chua-xacthuc-link': 'xác thực',
  'txt-sub-account': 'Tiểu khoản',
  'txt-normal': 'Thường',
  'txt-margin': 'Ký quỹ',
  'txt-pinefolio': 'PineFolio',
  'txt-warning': 'Cảnh báo',
  'txt-utilities': 'Tiện ích',
  'txt-personal': 'Cá nhân',
  'txt-bond': 'Trái phiếu',
  'txt-contact': 'Liên hệ',
  'lbl-contract-code': 'Mã hợp đồng',
  'txt-guide': 'Hướng dẫn',
  'txt-sign-out': 'Thoát',
  'txt-update': 'Cập nhật',
  'txt-nav': 'Tài sản ròng',
  'txt-stock-val': 'Giá trị CK',
  'txt-pp': 'Sức mua',
  'txt-pp-owner': 'Sức mua cơ bản',
  'txt-pp-margin': 'Sức mua margin',
  'txt-cashbalance': 'Tiền mặt thực có',
  'txt-wdrawAvail': 'Tiền được rút',
  'txt-advanceAvail': 'Tiền bán chờ về',
  'txt-CashDividence': 'Tiền cổ tức',
  'txt-BoughtT0': 'Tổng mua khớp T0',
  'txt-UnMatchedBoughtT0': 'Tiền mua chưa khớp T0',
  'txt-AdvanceDebt': 'Tiền đã ứng',
  'txt-BoughtT0-normal': 'Mua khớp bằng tiền mặt',
  'txt-BoughtT0-margin': 'Mua khớp bằng tiền vay',
  'txt-funds-accrued-in-plan': 'Tiền tích lũy trong KH',
  'txt-TotalStocks': 'Tổng giá trị chứng khoán',
  'txt-AvailTradingStock': 'Chứng khoán đang có',
  'txt-PTAStock': 'Chứng khoán chờ giao dịch',
  'txt-TARestric': 'Chứng khoán hạn chế',
  'txt-PTARestric': 'Chứng khoán hạn chế chờ giao dịch',
  'txt-RightStock': 'Cổ tức chứng khoán, cổ phiếu thưởng,...',
  'txt-ReceivingStockQtty': 'Chứng khoán chờ về',
  'txt-SoldT0': 'Bán khớp T0',
  'txt-TotalDebt': 'Tổng Nợ',
  'txt-MarginDebt': 'Tổng dư nợ margin',
  'txt-Principal': 'Nợ gốc margin',
  'txt-Interest': 'Lãi margin',
  'txt-ExpectedDisbursement': 'Dự kiến giải ngân margin',
  'txt-stock-waiting': 'CK chờ về',
  'txt-collateral': 'Cổ tức tiền',
  'txt-debt': 'Nợ',
  'txt-depoFee': 'Phí lưu ký',
  'txt-total-depoFee': 'Tổng phí lưu ký',
  'txt-margin-limit': 'Hạn mức margin',
  'txt-buy-max': 'KL có thể mua',
  'txt-sell-max': 'KL có thể bán',
  'txt-margin-rate': 'Tỷ lệ cho vay',

  'txt-cw': 'Chứng quyền',
  'txt-pt': 'Thoả thuận',
  'txt-industry': 'CP ngành',

  'txt-portfolio': 'Danh mục',
  'txt-balance': 'Số dư',

  'txt-symbol': 'Mã CK',
  'txt-symbol-equity': 'Mã CK cơ sở',
  'txt-enter-symbol-or-keyword': 'Nhập mã ck, từ khóa',
  'txt-symbol-full': 'Mã chứng khoán',
  'txt-stock': 'Mã CP',
  'txt-info': 'Thông tin',
  'txt-max-margin-rate': 'Tỷ lệ cho vay',
  'txt-ref': 'T.C',
  'txt-index-future': 'HĐTL chỉ số',
  'txt-bond-future': 'HĐTL TPCP',
  'txt-ceil': 'Trần',
  'txt-floor': 'Sàn',
  'txt-bid': 'Bên mua',
  'txt-ask': 'Bên bán',
  'txt-price': 'Giá',
  'txt-stick-size': 'Bước giá',
  'txt-vol': 'KL',
  'txt-origin-vol': 'KL gốc',
  'txt-match': 'Khớp lệnh',
  'txt-total-vol': 'Tổng KL',
  'txt-total-dividend-vol': 'KL cổ tức',
  'txt-total-val': 'Tổng GT',
  'txt-high': 'Cao',
  'txt-low': 'Thấp',
  'txt-ave': 'TB',
  'txt-avarage': 'Trung bình',
  'txt-price-ave': 'Giá TB',
  'txt-price-avg': 'Giá vốn',
  'txt-total-num': 'Tổng số',
  'txt-foreign': 'Khối ngoại',
  'txt-buy': 'Mua',
  'txt-sell': 'Bán',
  'txt-order-no': 'SHL',
  'txt-origin-order-no': 'SHL gốc',
  'txt-type': 'Loại',
  // 'txt-price': 'Giá đặt',
  'txt-price-order': 'Giá đặt',
  'txt-match-vol': 'KL khớp',
  'txt-dividend-match-vol': 'KL cổ tức khớp',
  'txt-match-price': 'Giá khớp',
  'txt-match-price-after-tax': 'Giá trị khớp sau phí thuế',
  'txt-cancel-vol': 'KL huỷ',
  'txt-total-match-value': 'Tổng GT khớp',
  'txt-match-value': 'GT khớp',
  'txt-status': 'Trạng thái',
  'txt-actions': 'Thao tác',
  'txt-market-price': 'Giá TT',
  'txt-change-in-day': 'Thay đổi trong ngày',
  'txt-set': 'Set',
  'txt-odd': 'Lẻ',
  'txt-gain-loss': 'Lãi/lỗ',
  'txt-orders': 'Sổ lệnh',
  'txt-help': 'Trợ giúp',
  'txt-to': 'đến',
  'txt-add-to': 'Thêm vào',
  'txt-vol-order': 'KL đặt',
  'txt-vol-buy': 'KL Mua',
  'txt-vol-sell': 'KL Bán',
  'txt-total-match-vol': 'Tổng KL Khớp lệnh',
  'txt-total-match-val': 'Tổng giá trị Khớp lệnh',
  'txt-lowest': 'Thấp nhất',
  'txt-highest': 'Cao nhất',

  'txt-order-normal': 'Lệnh thường',
  'txt-order-margin': 'Lệnh Margin',
  'txt-order-cond': 'Lệnh điều kiện',
  'txt-order-type': 'Loại lệnh',

  'txt-stt': 'STT',
  'txt-time': 'Thời gian',
  'txt-giatri': 'Giá trị',

  'txt-login-account': 'Đăng nhập với tài khoản Pinetree',
  'txt-account': 'Tài khoản',
  'txt-account-acronym': 'TK',
  'txt-account-number': 'Số tài khoản',
  'txt-login-name': 'Tên đăng nhập',
  'txt-new-login-name': 'Tên đăng nhập mới',
  'txt-old-login-name': 'Tên đăng nhập cũ',
  'txt-pass': 'Mật khẩu',
  'txt-all': 'Tất cả',
  'txt-closed': 'Đóng cửa',
  'txt-account-closed': 'Đã đóng',
  'txt-opened': 'Liên tục',
  'txt-intermission': 'Nghỉ trưa',
  'txt-wait-open': 'Chờ mở',
  'txt-preopen': 'ATO',
  'txt-atc': 'ATC',
  'txt-c': 'Thoả thuận',
  'txt-plo': 'PLO',
  'txt-offline': 'Tại sàn',
  'txt-confirm': 'Xác nhận',
  'txt-create': 'Tạo mới',
  'txt-reserved': 'Lệnh chờ vào sở',
  'txt-sent': 'Chờ XN',
  'txt-waitingConfirm': 'Chờ khớp',
  'txt-matched': 'Khớp hết',
  'txt-part-matched': 'Khớp 1 phần',
  'txt-canceled': 'Đã huỷ',
  'txt-modifyed': 'Đã sửa',
  'txt-negotiation': 'Thỏa thuận',
  'txt-not-accept': 'Không chấp thuận',
  'txt-match-canceled': 'Đã hủy đối ứng',
  'txt-matched-pending': 'Tạm dừng',
  'txt-pending': 'Chờ xác nhận huỷ/sửa',
  'txt-transfer-pending': 'Đang chờ',
  'txt-complete': 'Hoàn thành',
  'txt-bank-error': 'Có lỗi từ bank',
  'txt-admended': 'Đã sửa',
  'txt-rejected': 'Từ chối',
  'txt-error': 'Lỗi',
  'txt-error-order': 'Lệnh bị lỗi',
  'txt-expired': 'Lệnh hết hiệu lực',
  'txt-calculating-fee': 'Đang tính phí',

  'txt-success': 'Thành công',
  'txt-unsuccessful': 'Không thành công',

  'txt-match-order-vol': 'Khớp đủ khối lượng đặt',
  'txt-match-one-time': 'Một lần khớp duy nhất',
  'txt-out-time': 'Lệnh trước ngày',
  'txt-trend-order': 'Lệnh xu hướng',
  'txt-take-profit': 'Lệnh chốt lời',
  'txt-confirm-TnC':
    'Tôi đồng ý với các điều kiện và điều khoản đặt lệnh điều kiện phái sinh nêu trên',
  'txt-confirm-TnC-header': 'Điều khoản và điều kiện',
  'txt-stop-loss': 'Lệnh cắt lỗ',
  'txt-purchase-order': 'Lệnh tranh mua / bán',
  'txt-transfer-content': 'Nop tien TK GDCK',
  'txt-warning-sl-tp':
    'Tại thời điểm kích hoạt lệnh, nếu số lượng vị thế tài khoản thay đổi so với khi cài đặt lệnh điều kiện, hệ thống vẫn sẽ sinh lệnh lên sàn ',

  'txt-cond-pending': 'Chờ kích hoạt',
  'txt-cond-active': 'Đang kích hoạt',
  'txt-cond-activated': 'Đã kích hoạt',
  'txt-cond-final': 'Kết thúc',
  'txt-cond-expired': 'Hết hiệu lực',
  'txt-cond-cancelling': 'Đang huỷ',
  'txt-cond-wait-react': 'Chờ kích hoạt lại',
  'txt-cond-new': 'Đã vào hệ thống',
  'txt-cond-effective-time': 'Thời gian hiệu lực',
  'txt-cond-setting-condition': 'Điều kiện đặt lệnh',
  'txt-cond-setting-price': 'Điều kiện giá',
  'txt-cond-method-trend': 'Phương thức xác định xu hướng',
  'txt-cond-pause-value': 'Khoảng dừng theo giá trị',
  'txt-cond-pause-per': 'Khoảng dừng theo %',
  'txt-cond-choose-price': 'Tuỳ chọn giá',
  'txt-cond-diff-price': 'Giá chênh lệch TP',
  'txt-cond-lowest-price': 'Giá mua thấp nhất',
  'txt-cond-highest-price': 'Giá bán cao nhất',
  'txt-cond-activation-conditions': 'Điều kiện kích hoạt',
  'txt-cond-avg': 'Giá mua Bình quân',
  'txt-cond-diff-for-avg': 'Chênh lệch so với giá BQ',
  'txt-cond-diff-for-avg-per': 'Giá trị chênh lệch',
  'txt-cond-diff-for-cur-price': 'Chênh lệch với giá hiện tại',
  'txt-cond-total-cond': 'Tổng kết điều kiện kích hoạt',
  'txt-open-account': 'Mở tài khoản',
  'txt-forgot-pass': 'Quên mật khẩu?',
  'txt-no-data-found': 'Không có dữ liệu!',
  'txt-please-try-again': 'Vui lòng thử lại',
  'txt-no-add-catalog': 'Không thêm mã vào danh mục ',
  'txt-symbol-has-in-catalog': 'Chứng khoán đã ở trong danh mục: ',
  'txt-price-not-match': 'Giá đặt{{side}} không hợp lệ',
  'txt-price-please-check': 'Kiểm tra giá đặt lệnh',
  'txt-triggerPrice-please-check': 'Kiểm tra điều kiện kích hoạt lệnh',
  'txt-cash-not-match': 'Số tiền không hợp lệ',
  'txt-no-beneficiary-data': 'Không có dữ liệu người thụ hưởng',
  'txt-vol-not-match': 'Khối lượng{{side}} không hợp lệ',
  'txt-symbol-not-match': 'Chứng khoán không hợp lệ',
  'txt-price-not-over': 'Giá đặt không được vượt quá giá trần: ',
  'txt-price-not-less': 'Giá đặt không được nhỏ hơn giá sàn: ',
  'txt-hsx-price': 'HSX không đặt giá MOK/MAK/PLO',
  'txt-hnx-price': 'HNX không đặt giá MP',
  'txt-upcom-price': 'UPCOM không đặt giá thị trường',
  'txt-valid-time': 'Thời gian tìm kiếm không hợp lệ',
  'txt-valid-time-2': 'Hệ thống chỉ hỗ trợ tra cứu dữ liệu từ ngày ',
  'txt-valid-range-time': 'Chỉ được xuất báo cáo trong vòng',
  'txt-valid-5-catalog': 'Thêm tối đa 15 danh mục',
  'txt-valid-category-not-null': 'Tên danh mục không hợp lệ',
  'txt-valid-not-del': 'Không thể xóa danh mục ',
  'txt-valid-not-edit': 'Không được sửa ',
  'txt-valid-excess-amount': 'Số tiền vượt quá số tiền có thể chuyển',
  'txt-valid-exist-category': 'Đã tồn tại danh mục',
  'txt-valid-max-amount': 'Ứng tối đa',
  'txt-valid-min-amount': 'Ứng tối thiểu',
  'txt-valid-send-otp': 'Không được gửi quá 05 OTP trong vòng 2 phút!',
  'txt-valid-same-pass': 'Mật khẩu mới và mật khẩu cũ trùng nhau',
  'txt-valid-pass-confirm-not-match':
    'Mật khẩu mới và nhập lại mật khẩu không trùng nhau',
  'txt-valid-input-required': 'Trường không được để trống',
  'txt-valid-upload-file-required': 'Vui lòng tải file lên',
  'txt-valid-characters': 'ký tự',
  'txt-valid-enter': 'Nhập',
  'txt-valid-phone-incorrect-format':
    'Số điện thoại của bạn không đúng định dạng',
  'txt-valid-date-incorrect-format': 'Ngày sinh của bạn không đúng định dạng',
  'txt-valid-email-incorrect-format': 'Email không đúng định dạng',
  'txt-valid-enter-verification-code': 'Hãy nhập mã xác thực',
  'txt-valid-loss-session':
    'Mất session. Quý khách cần đăng nhập lại để tiếp tục',
  'txt-valid-old-account-not-match': 'Tài khoản cũ không trùng User đăng nhập',
  'txt-valid-same-new-account': 'Tài khoản mới và tài khoản cũ giống nhau',
  'txt-valid-input-not-allow-special-character':
    'Vui lòng không nhập các ký tự đặc biệt như [!, @, #, $,%, ^, &, * (,)] ... hoặc chữ tiếng việt',
  'txt-valid-input-password-must-contain-at-least-one-number-and-one-special-character':
    'Mật khẩu dài tối thiếu 8 ký tự trong đó nhất có 1 chữ số , 1 chữ cái và 1 ký tự đặc biệt',
  'txt-valid-input-min-length-character':
    'Nhập $t(txt-title-min) {{value}} ký tự',
  'txt-valid-input-max-length-character':
    'Nhập $t(txt-title-max) {{value}} ký tự',
  'txt-show': 'Thể hiện',
  'txt-showmore': 'Xem thêm',
  'txt-seemore': 'Xem thêm',
  'txt-note': 'Chú ý',
  'txt-note-text': 'Ghi chú',
  'text-order': 'lệnh',
  'txt-overview': 'Tổng quan (tỷ VNĐ)',
  'txt-all-market': 'Toàn thị trường',
  'txt-total-transaction': 'Tổng GD',
  'txt-total-putThrough': 'Tổng GDTT',
  'txt-total-foreign': 'Khối ngoại',
  'txt-international': 'TT Quốc tế',

  'txt-orderNo': 'Số hiệu lệnh',
  'txt-lost-connect': 'Mất kết nối đến server',
  'txt-regis-unsuccessful': 'Đăng ký private không thành công',

  'txt-title-order-cond': 'Đặt lệnh điều kiện',
  'txt-title-authentication-success': 'Xác thực thành công',
  'txt-title-neworder-success': 'Đặt lệnh thành công',
  'txt-title-neworder-unsuccess': 'Đặt lệnh không thành công',
  'txt-title-delorder-success': 'Huỷ lệnh thành công',
  'txt-title-editorder-success': 'Sửa lệnh thành công',
  'txt-title-hold-success': 'Phong toả / Giải toả ngân hàng thành công!',
  'txt-title-asset-manage': 'Báo cáo tài sản',
  'txt-title-debt-manage': 'Báo cáo nợ',
  'txt-title-transfer-stock-fail': 'Chuyển cổ phiếu không thành công',
  'txt-title-transfer-success': 'Đã chuyển thành công',
  'txt-title-login-success': 'Đăng nhập thành công',
  'txt-title-login-fail': 'Đăng nhập thất bại',
  'txt-title-changepass-success':
    'Đổi mật khẩu thành công. Quý khách cần đăng nhập lại để tiếp tục',
  'txt-title-changetelpin-success': 'Đổi mật khẩu telpin thành công',
  'txt-title-max': 'Tối đa',
  'txt-title-min': 'Tối thiểu',
  'txt-title-withdrawal-confirmation': 'Xác nhận rút tiền',
  'txt-title-withdrawal-verification': 'Xác thực rút tiền',
  'txt-title-edit-confirm': 'Xác nhận sửa lệnh',
  'txt-title-new-confirm': 'Xác nhận đặt lệnh',
  'txt-title-delete-confirm': 'Xác nhận huỷ lệnh',
  'txt-title-fill-info-regis': 'Điền thông tin bạn đã đăng ký với Pinetree',
  'txt-title-fill-info-reset': 'Điền thông tin bạn đã đăng ký với Pinetree',
  'txt-title-login-no-cif': 'Thông tin khách hàng chưa chính xác',

  'txt-stt-in-date': 'Trong hạn',
  'txt-stt-due-date': 'Đến hạn',
  'txt-stt-out-date': 'Quá hạn',
  'txt-stt-right-cash': 'Quyền tiền',
  'txt-stt-right-buy': 'Quyền mua',
  'txt-stt-right-stock': 'Quyền cổ phiếu',
  'txt-stt-internal': 'Nội bộ',
  'txt-stt-bank': 'Ngân hàng',

  'txt-stt-right-01': 'Cổ tức bằng tiền',
  'txt-stt-right-02': 'Cổ tức bằng cổ phiếu',
  'txt-stt-right-03': 'Quyền mua',
  'txt-stt-right-04': 'Cổ phiếu thưởng',
  'txt-stt-right-05': 'Giảm vốn tự nguyệ',
  'txt-stt-right-06': 'Giảm vốn bắt buộc',
  'txt-stt-right-07': 'Sáp nhập và hợp nhất',
  'txt-stt-right-08': 'Chia tách công ty',
  'txt-stt-right-09': 'Đổi mã giao dịch',
  'txt-stt-right-11': 'Chia tách cổ phiếu',
  'txt-stt-right-12': 'Gộp cổ phiếu',

  'txt-asset-total': 'Tổng tài sản',
  'txt-asset-nav': 'Tài sản ròng',
  'txt-asset-withdraw': 'Số tiền được rút',
  'txt-asset-receivable': 'Số tiền tối đa được ứng',
  'txt-asset-withdrawal': 'Tiền được rút',
  'txt-asset-receivable-1': 'Tiền có thể ứng',
  'txt-asset-balance': 'Tiền',
  'txt-asset-cash-balance': 'Số dư tiền',
  'txt-asset-actual-balance-account': 'Số dư tiền thực tế trên tài khoản',
  'txt-asset-receivable-amount': 'Tiền chờ về',
  'txt-asset-buy-mount': 'Tiền mua đã khớp',
  'txt-asset-unmatch-amount': 'Tiền mua chưa khớp',
  'txt-asset-collateral': 'Cổ tức tiền chờ về',
  'txt-asset-collateral-stock': 'Cổ tức CK chờ về',
  'txt-asset-stocks': 'Giá trị chứng khoán',
  'txt-asset-market-stocks': 'Giá trị thị trường của CK',
  'txt-asset-guaranteed': 'Giá trị tài sản cần đảm bảo',
  'txt-asset-outstanding-debt': 'Dư nợ thực tế',
  'txt-asset-adding': 'Số tiền cần bổ sung',
  'txt-asset-remain-margin': 'Hạn mức còn lại',
  'txt-asset-day-loan': 'Nợ vay Margin trong ngày',
  'txt-asset-principal': 'Nợ gốc Margin',
  'txt-asset-month-depo-fee': 'Tổng phí lưu ký',
  'txt-asset-other-fee': 'Phí khác',
  'txt-asset-SMS-fee': 'Phí SMS',
  'txt-asset-cash-can-transfer': 'Số tiền có thể chuyển',
  'txt-asset-margin-loan-interest': 'Lãi vay Margin',

  'txt-label-page-size': 'Hiển thị',
  'txt-label-page': 'Trang',
  'txt-label-cash': 'Tiền',
  'txt-label-avail': 'GD',
  'txt-label-position': 'Chứng khoán',
  'txt-label-from': 'Từ ngày',
  'txt-label-to': 'Đến ngày',
  'txt-label-right': 'Quyền',
  'txt-label-total-loan': 'Tổng dư nợ',
  'txt-label-loan-date': 'Ngày vay',
  'txt-label-expire-date': 'Ngày đến hạn',
  'txt-label-extend-expire-date': 'Ngày gia hạn',
  'txt-label-extend-registration-date': 'Ngày đk gia hạn',
  'txt-label-expired-date': 'Ngày hoàn ứng',
  'txt-label-interest': 'Lãi',
  'txt-label-fee': 'Phí',
  'txt-label-tax': 'Thuế',
  'txt-label-dividend-tax': 'Thuế cổ tức',
  'txt-label-free': 'Miễn phí',
  'txt-label-defer-interest': 'Lãi chậm hạn',
  'txt-label-repay-interest': 'Số tiền thanh toán',
  'txt-label-remain-interest': 'Nợ còn lại',
  'txt-label-disbursement-date': 'Ngày giải ngân',
  'txt-label-maturity-date': 'Hạn',
  'txt-label-call-date': 'ngày mua lại trước hạn',
  'txt-label-interest-rate': 'Lãi suất / năm',
  'txt-label-loan-amount': 'Số tiền vay',
  'txt-label-penalty-delay': 'Phạt chậm hạn',
  'txt-label-paid': 'Đã trả',
  'txt-label-unpaid': 'Chưa trả',
  'txt-label-repay': 'Đã hoàn ứng',
  'txt-label-unrepay': 'Chờ hoàn ứng',
  'txt-label-in-due-amount': 'Còn nợ',
  'txt-label-pay': 'Thanh toán',
  'txt-label-total-amount': 'Thành tiền',
  'txt-label-real-amount': 'GT thực tế',
  'txt-label-payment-history': 'Lịch sử thanh toán',
  'txt-label-return-asset': 'Quay lại tổng quan tài sản',
  'txt-label-major': 'Nghiệp vụ',
  'txt-label-opening-balance': 'Số dư đầu kỳ',
  'txt-label-closing-balance': 'Số dư cuối kỳ',
  'txt-label-increase': 'Phát sinh tăng',
  'txt-label-decrease': 'Phát sinh giảm',
  'txt-label-vol-up': 'KL tăng',
  'txt-label-vol': 'Khối lượng',
  'txt-label-vol-down': 'KL giảm',
  'txt-label-remark': 'Mô tả',
  'txt-label-overview': 'Tổng quan',
  'txt-label-history-price': 'Lịch sử giá',
  'txt-label-finance': 'Tài chính',
  'txt-label-events': 'Sự kiện',
  'txt-label-news': 'Tin tức',
  'txt-label-documents': 'Hồ sơ',
  'txt-label-details': 'Chi tiết giá',
  'txt-label-amount': 'Số lượng',
  'txt-label-expected-date': 'Ngày chốt quyền',
  'txt-label-registration-date': 'Ngày thực hiện quyền',
  'txt-label-quantity-record-date': 'SL tại ngày chốt',
  'txt-label-ratio': 'Tỷ lệ',
  'txt-label-pending-transfer': 'Chờ chuyển',
  'txt-label-pending-delivery': 'Chờ giao',
  'txt-label-pending-trading': 'CK chờ GD',
  'txt-label-tran-date': 'Ngày GD',
  'txt-label-trade-date': 'Ngày đặt lệnh', // TODO: 02/12/2020 update ngày đặt
  'txt-label-tran-amount': 'Số tiền',
  'txt-label-tran-type': 'Loại GD',
  'txt-label-withdrawal': 'Rút tiền',
  'txt-label-hold-bank': 'Phong tỏa tại ngân hàng',
  'txt-label-withdrawal-bank': 'Giải tỏa tại ngân hàng',
  'txt-label-bank-link': 'Ngân hàng đã liên kết',
  'txt-label-account-link': 'Tài khoản liên kết',
  'txt-label-bank-name': 'Tên ngân hàng',
  'txt-label-branch-name': 'Tên chi nhánh',
  'txt-register-bank-account-success':
    'Đăng ký tài khoản ngân hàng thành công.',
  'txt-label-bank-hold-amount': 'Số tiền tạm giữ',
  'txt-label-history': 'Lịch sử',
  'txt-label-transfer-to': 'Chuyển sang',
  'txt-label-transfer-fee': 'Phí chuyển',
  'txt-label-source': 'Nguồn',
  'txt-label-target': 'Đích',
  'txt-label-create-date': 'Ngày tạo',
  'txt-label-stock-transfer': 'Chuyển cổ phiếu',
  'txt-label-stock-holding': 'Mã CP nắm giữ',
  'txt-label-vol-holding': 'KL nắm giữ',
  'txt-label-cash-transfer-amount': 'Số tiền chuyển',
  'txt-label-cash-transfer-content': 'Nội dung chuyển',
  'txt-label-internal-transfer': 'Chuyển tiền tiểu khoản',
  'txt-label-online-transfer': 'chuyển tiền online từ',
  'txt-label-right-subscription': 'Đăng ký quyền mua',
  'txt-label-max-subscription': 'Số tiền tối đa được đăng ký: ',
  'txt-label-owned-quantity': 'KL CK tại ngày chốt',
  'txt-label-remaining-quantity': 'KL được mua',
  'txt-label-purchased-quantity': 'KL đã mua',
  'txt-label-maximum-quantity': 'KL còn được mua',
  'txt-label-start-date': 'Ngày bắt đầu',
  'txt-label-end-date': 'Ngày kết thúc',
  'txt-label-base-date': 'Hạn đăng ký',
  'txt-label-market-value': 'Giá trị thị trường',
  'txt-label-buy-amt': 'Giá trị ban đầu',
  'txt-label-billion': 'tỷ',
  'txt-label-million': 'tr',
  'txt-label-thousand': 'ng',
  'txt-label-deposit-stock': 'Nạp tiền vào tài khoản CK',
  'txt-label-va-deposit-stock':
    'Nạp tiền vào tài khoản CK qua tài khoản định danh tại Ngân hàng',
  'txt-label-beneficiary': 'Tên người thụ hưởng',
  'txt-label-account-beneficiary': 'Tài khoản thụ hưởng',
  'txt-label-va-account-beneficiary': 'Tài khoản định danh',
  'txt-label-choose': 'Chọn',
  'txt-label-transfer-content': 'Nội dung chuyển khoản',
  'txt-label-amount-want-transfer': 'Số tiền muốn chuyển',
  'txt-label-source-advance': 'Nguồn ứng',
  'txt-label-amount-advance': 'Số tiền yêu cầu ứng',
  'txt-label-advance-request': 'Yêu cầu ứng',
  'txt-label-amount-deducted': 'Số tiền đã ứng',
  'txt-label-fee-service': 'Phí ứng',
  'txt-label-fee-management': 'Phí quản lý',
  'txt-label-total': 'Tổng',
  'txt-label-sell-date': 'Ngày bán',
  'txt-label-trade-amount-af-fee': 'Tiền có thể ứng tối đa',
  'txt-label-settlement-date': 'Ngày về TK',
  'txt-label-calculator-fee': 'Tính phí ứng',
  'txt-label-deposit': 'Nộp tiền',
  'txt-label-quick-deposit': 'Nạp tiền nhanh',
  'txt-label-normal-deposit': 'Nạp tiền thường',
  'txt-label-hold': 'Phong tỏa',
  'txt-label-unHold': 'Giải tỏa',
  'txt-label-recharge': 'Nạp thêm',
  'txt-label-profile': 'Hồ sơ',
  'txt-label-security': 'Bảo mật',
  'txt-label-bank-account': 'Tài khoản ngân hàng',
  'txt-label-authorization': 'Người uỷ quyền',
  'txt-label-authorization-person': 'Người uỷ quyền',
  'txt-label-authorization-person-other': 'Người uỷ quyền khác',
  'txt-label-authorization-type': 'Loại GD được uỷ quyền',
  'txt-label-order-statement': 'Sao kê sổ lệnh',
  'txt-label-order-cond-statement': 'Sao kê lệnh ĐK',
  'txt-label-cash-statement': 'Sao kê tiền',
  'txt-label-securities-statement': 'Sao kê chứng khoán',
  'txt-label-gain-loss': 'Lãi lỗ đã thực hiện',
  'txt-label-order-del': 'Huỷ lệnh',
  'txt-label-order-edit': 'Sửa lệnh',
  'txt-label-trading-fee': 'Phí GD',
  'txt-label-sell-fee': 'Phí bán CK',
  'txt-label-condition': 'Điều kiện',
  'txt-label-order-history': 'Lịch sử đặt lệnh',
  'txt-label-order-cond-history': 'Lịch sử đặt lệnh điều kiện',
  'txt-label-channel': 'Kênh đặt',
  'txt-label-account-info': 'Thông tin liên hệ',
  'txt-label-general-info': 'Thông tin chung',
  'txt-label-fullname': 'Họ và tên',
  'txt-label-identification': 'CMND',
  'txt-label-issued-on': 'Ngày cấp',
  'txt-label-place': 'Nơi cấp',
  'txt-label-phone-num': 'Số điện thoại di động',
  'txt-label-phone': 'Số điện thoại',
  'txt-label-date-birth': 'Ngày sinh',
  'txt-label-gender': 'Giới tính',
  'txt-label-cif-no': 'Số CIF',
  'txt-label-vsd-no': 'Số VSD',
  'txt-label-male': 'Nam',
  'txt-label-female': 'Nữ',
  'txt-label-nationality': 'Quốc tịch',
  'txt-label-contact-info': 'Thông tin liên hệ',
  'txt-label-permanent-address': 'Địa chỉ thường trú',
  'txt-label-address': 'Địa chỉ liên hệ',
  'txt-label-ip-address': 'Địa chỉ',
  'txt-label-province': 'Tỉnh / Thành phố',
  'txt-label-branch': 'Chi nhánh',
  'txt-label-beneficiary-bank': 'Ngân hàng thụ hưởng',
  'txt-label-account-holder': 'Chủ tài khoản',
  'txt-label-account-num': 'Số tài khoản',
  'txt-label-account-type': 'Tiểu khoản',
  'txt-label-account-second': 'Tài khoản phụ',
  'txt-label-login-info': 'Thông tin đăng nhập',
  'txt-label-login-account': 'Tên đăng nhập',
  'txt-label-customer-name': 'Tên khách hàng',
  'txt-label-date-of-birth': 'Ngày sinh',
  'txt-label-current-password': 'Mật khẩu hiện tại',
  'txt-label-current-telpin': 'Telpin hiện tại',
  'txt-label-default-account': 'Tài khoản mặc định',
  'txt-label-authentication-registered': 'Các PTXT bạn đang đăng ký',
  'txt-label-change-pass-pinetree': 'Đổi mật khẩu tài khoản Pinetree',
  'txt-label-old-pass': 'Mật khẩu cũ',
  'txt-label-new-pass': 'Mật khẩu mới',
  'txt-label-new-pass-confirm': 'Nhập lại mật khẩu mới',
  'txt-label-old-telpin': 'Mã Telpin cũ',
  'txt-label-new-telpin': 'Mã Telpin mới',
  'txt-label-new-telpin-confirm': 'Nhập lại mã telpin mới',
  'txt-label-authen-otp': 'Xác thực OTP',
  'txt-label-cash-advance': 'Được ứng',
  'txt-label-recipient-account': 'Tài khoản nhận',
  'txt-label-amount-withdraw': 'Số tiền muốn rút',
  'txt-label-margin-list': 'Danh sách cổ phiếu ký quỹ',
  'txt-label-look-right-info': 'Tra cứu thông tin thực hiện quyền',
  'txt-label-default-watchlist': 'Mặc định',
  'txt-label-cash-advance-payment': 'Tiền bán có thể ứng',
  'txt-label-old-account': 'Tài khoản cũ',
  'txt-label-new-account': 'Tài khoản mới',
  'txt-label-quarterly': 'Hàng quý',
  'txt-label-annual': 'Hàng năm',
  'txt-label-income-statement': 'Kết quả kinh doanh',
  'txt-label-balance-sheet': 'Cân đối kế toán',
  'txt-label-ratios': 'Chỉ số tài chính',
  'txt-label-items': 'Chỉ số',
  'txt-label-date': 'Ngày',
  'txt-label-today': 'Hôm nay',
  'txt-label-month': 'Tháng',
  'txt-label-months': 'Tháng',
  'txt-label-monday': 'T2',
  'txt-label-tuesday': 'T3',
  'txt-label-wednesday': 'T4',
  'txt-label-thursday': 'T5',
  'txt-label-friday': 'T6',
  'txt-label-saturday': 'T7',
  'txt-label-sunday': 'CN',
  'txt-label-enter-date': 'Nhập ngày',
  'txt-label-major-shareholders': 'Cổ đông chính',
  'txt-label-legal-representative': 'Đại diện theo pháp luật',
  'txt-label-customer-supporter-name': 'Nhân viên chăm sóc',
  'txt-label-customer-supporter-phone': 'Điện thoại liên hệ',
  'txt-label-read-more': 'Đọc thêm',
  'txt-label-others': 'Khác',

  'txt-export-excel': 'Xuất Excel',
  'txt-export-pdf': 'Xuất PDF',

  'txt-verification-methods': 'Chọn Phương Thức Xác Thực',
  'txt-choose-verification-methods': 'Vui lòng chọn 1 trong các PTXT dưới đây',
  'txt-smartotp-pinetree': 'Sử dụng ứng dụng SmartOTP của Pinetree',
  'txt-matrix': 'Ma trận',
  'txt-regis-counter': 'Đăng ký tại quầy',
  'txt-otp-sent-to': 'OTP sẽ gửi về SĐT ',
  'txt-lost-verification': 'Mất PTXT?',

  'txt-enter-otp': 'Nhập mã OTP của bạn',
  'txt-sub-smartotp':
    'Vui lòng nhập mã hiển thị tại ứng dụng SmartOTP của bạn vào ô dưới đây',
  'txt-sub-otp': 'Vui lòng nhập mã OTP đã được gửi về SDT',
  'txt-learn-more': 'Tìm hiểu thêm',
  'txt-cant-access': 'Chưa thể truy cập thiết bị của bạn?',
  'txt-change-method': 'Thay đổi Phương Thức Xác Thực khác',
  'txt-resend': 'Gửi lại',
  'txt-refresh-matrix': 'Làm mới ma trận',
  'txt-countdown-refresh':
    'Bạn đã nhập sai OTP 5 lần liên tiếp. Vui lòng chờ {{time}}s để lấy lại OTP',
  txt_send_successfully: 'Đã gửi thành công',
  'txt-countdown-resend':
    'Bạn đã nhập sai OTP 5 lần liên tiếp. Vui lòng chờ {{time}}s để lấy lại OTP',
  'txt-resend-after': 'Gửi lại sau',
  'txt-enter-matrix': 'Nhập mã ma trận của bạn',
  'txt-hotline-support':
    'Bạn muốn thay đổi các thông tin hồ sơ trên ? Vui lòng liên hệ hotline của Pinetree để được hỗ trợ.',
  'txt-make-sure-can-access':
    'Hãy chắc chắn bạn có thể truy cập phương thức xác thực hiện tại để thực hiện thay đổi các thông tin bảo mật tài khoản',
  'txt-make-sure-can-access-settings':
    'Hãy chắc chắn bạn có thể truy cập phương thức xác thực hiện tại để thực hiện thay đổi các thông tin cài đặt tài khoản',
  'txt-make-verify-otp-correct': 'Xác thực OTP sau khi nhập đúng cả 2 password',
  'txt-code-smartotp-to-confirm':
    'Quý khách hãy nhập mã số bảo mật từ ứng dụng SmartOTP để xác nhận giao dịch',
  'txt-code-otp-to-confirm': 'Quý khách hãy nhập mã số OTP được gửi đến SDT',
  'txt-confirm-transaction': 'để xác nhận giao dịch',
  'txt-code-matrix-to-confirm':
    'Quý khách hãy nhập mã MATRIX để xác nhận giao dịch',
  'txt-gd':
    'Để thực hiện nạp tiền vào tài khoản Chứng khoán tự động. Quý khách vui lòng thực hiện chuyển khoản Ngân hàng tới 1 trong số các tài khoản Ngân hàng với thông tin CHÍNH XÁC như mẫu.',
  'txt-gd-va':
    'Để thực hiện nạp tiền vào tài khoản Chứng khoán tự động, quý khách vui lòng thực hiện chuyển khoản Ngân hàng tới đúng số tài khoản định danh khách hàng.',
  'txt-gd-va1':
    'Phương pháp nộp tiền thông qua số tài khoản định danh của Ngân Hàng VIB chỉ áp dụng cho khoản tiền nộp <span class="text-highlight">NHỎ HƠN 500 TRIỆU</span> và đi theo đường nộp tiền nhanh 24/7 qua hệ thống NAPAS.',
  'txt-gd-note':
    '<span class=\'text-light fz_13\'><strong>Chú ý: </strong>Quý Khách hàng có thể xem hướng dẫn chi tiết về việc nạp tiền vào tài khoản chứng khoán <a class="text-warning fw-500" target="_blank" href="https://pinetree.vn/post/dich-vu/nop-tien-vao-tai-khoan"> TẠI ĐÂY</a></span>',
  'txt-warning-cashout-fee':
    'Số tiền rút tối đa chưa bao gồm phí smsOTP (nếu có)',
  'txt-warning-cashout':
    'Hệ thống hỗ trợ rút tiền online từ 08h00 tới 15h55 hàng ngày, quý khách vui lòng thực hiện trong khoảng thời gian trên',
  'txt-warning-cashout-0':
    'Theo quy định Napas, số tiền rút tối thiểu là 2,000 VND',
  'txt-warning-cashout-1':
    'Với mỗi giao dịch chuyển tiền < 500 triệu, hệ thống hỗ trợ rút tiền NHANH 24/7.',
  'txt-warning-cashout-2':
    'Với mỗi giao dịch chuyển tiền > = 500 triệu, hệ thống chỉ hỗ trợ rút tiền thường trong thời gian từ 8h đến 15h25 các ngày làm việc.',
  'txt-warning-cashout-3':
    'Quý khách vui lòng thực hiện rút tiền với các hạn mức và thời gian thông báo trên.',
  'txt-warning-relogin': 'Bạn đã đăng xuất, vui lòng đăng nhập lại.',
  'txt-label-system': 'Theo quy định của hệ thống',
  'txt-urgent-announcement':
    'HOSE nâng lô giao dịch lên 100 từ ngày 4/1/2021. Quý khách lưu ý chủ động quản lý danh mục',
  'txt-symbols-sold-out-hide': 'Ẩn mã đã bán hết',
  'txt-symbols-odd-lot-hide': 'Ẩn mã cổ phiếu lẻ',
  'txt-unregis-authen-method':
    'Bạn chưa đăng ký phương thức xác thực này, vui lòng liên hệ hotline để được hỗ trợ',
  'txt-enter-authen-matrix': 'Vui lòng nhập mã MATRIX để xác thực',
  'txt-enter-authen-smartotp':
    'Vui lòng nhập mã xác thực bảo mật từ ứng dụng SmartOTP',
  'txt-enter-authen-smsotp': 'Vui lòng nhập mã số OTP được gửi đến SĐT ',
  'txt-copyright':
    'Giá x 1000 VNĐ. Khối lượng x 10 cổ phiếu. Bản quyền thuộc về Pinetree ©2022.',
  'txt-copyright2':
    'Giá x 1 VNĐ. Khối lượng x 1 hợp đồng. Bản quyền thuộc về Pinetree ©2022',
  'lbl-need-VSD-case-1':
    'Quý khách vui lòng hoàn thiện hợp đồng mở TK. Chức năng rút tiền sẽ tạm khóa đến khi hợp đồng được hoàn thiện.',
  'lbl-need-VSD-case-2':
    'Quý khách vui lòng đăng nhập ứng dụng Alphatrading hoặc PineX để hoàn thiện hợp đồng mở TK. Quý khách lưu ý không nộp tiền vào tài khoản cho đến khi hoàn thiện hợp đồng.',
  'lbl-need-VSD-case-3': 'Quý khách vui lòng hoàn thiện hợp đồng mở tài khoản.',
  'lbl-ok': 'Đồng ý',
  'lbl-gain-loss': 'Lãi/lỗ',
  'lbl-buy-amount': 'Giá trị vốn',
  'lbl-company': 'CÔNG TY CỔ PHẦN CHỨNG KHOÁN PINETREE',
  'lbl-company-address':
    'Tầng 20, Tòa ROX Tower, 54A Nguyễn Chí Thanh, Đống Đa, Hà Nội',
  'lbl-company-contact':
    'Tel: 024 6282 3535 | Fax: 024 6275 0077 | Email: contact@pinetree.vn',
  'lbl-report-name': 'Tên báo cáo',
  'lbl-subAccount': 'Số tiểu khoản',
  'lbl-cost-at-the-time-of-sale': 'Giá vốn',
  'lbl-activity': 'Hoạt động',
  'txt-fail': 'Thất bại',
  'txt-login-notification': 'Đăng nhập {{status}} trên thiết bị: {{device}}',
  'txt-order-notification': 'TK: {{subAccount}} {{orderType}} {{orderSymbol}}',
  'lbl-next': 'Tiếp theo',
  'lbl-previous': 'Trước',
  'lbl-finish': 'Đồng ý',
  'lbl-close': 'Đóng',
  'lbl-notification-slider-pagecount': '{{current}} trên {{total}} thông báo',
  'lbl-setting-services': 'Cài đặt dịch vụ',
  'lbl-sms-reg-service': 'Dịch vụ SMS',
  'lbl-other-reg-services': 'Đăng ký dịch vụ',
  'lbl-other-reg-date': 'Ngày đăng ký',
  'lbl-other-api-services': 'Dịch vụ API',
  'lbl-partner-services': 'Dịch vụ đăng ký',
  'lbl-un-reg-service': 'Chưa đăng ký',
  'lbl-reg-service': 'Đã đăng ký',
  'lbl-are-you-sure': 'Bạn có chắc chắn?',
  'lbl-remove-the-bank': 'Bạn muốn xóa tài khoản ngân hàng này!',
  'lbl-remove': 'Xóa',

  'txt-regis-bank-account-confirm': 'Xác nhận đăng ký mới tài khoản ngân hàng',
  'txt-title-regis-bank-verification': 'Xác thực đăng ký tài khoản ngân hàng',
  'txt-matched-order': 'Lệnh khớp',
  'txt-matched-detail': 'Chi tiết khớp',
  'txt-price-chart': 'Biểu đồ',
  'txt-price-analytic': 'Phân tích giá',
  'txt-trading-data': 'Lịch sử giá',
  'txt-open-price': 'Mở cửa',
  'lbl-double-click-to-order': 'Click đúp để đặt lệnh',
  'txt-reset': 'Đặt lại',
  'txt-PersonalID': 'Chứng minh nhân dân',
  'txt-personalID-type': 'Loại',
  'txt-personalID-1': 'CMND',
  'txt-personalID-2': 'CCCD',
  'txt-personalID-number': 'Số',
  'txt-residence-addr': 'Địa chỉ thường trú',
  'txt-personalID-IssuedPlace': 'Nơi cấp',
  'txt-personalID-IssuedDate': 'Ngày cấp',
  'txt-personalID-ExpireDate': 'Ngày hết hạn',
  'txt-feedback-get-gift': 'Feedback & nhận quà',
  'txt-odd-lot': 'Lô lẻ',
  'txt-odd-lot-hsx': 'Lô lẻ (HSX)',
  'txt-odd-lot-hnx': 'Lô lẻ (HNX)',
  'txt-odd-lot-upcom': 'Lô lẻ (UPCOM)',

  'txt-buy-in': 'Buy in',
  'txt-buy-in-hsx': 'Buy in (HSX)',
  'txt-buy-in-hnx': 'Buy in (HNX)',
  'txt-buy-in-upcom': 'Buy in (UPCOM)',
  'txt-post-close': 'Post Close',

  'txt-my-watchlist': 'Danh mục của tôi',
  'txt-support-247': 'Hỗ trợ 24/7',
  HOSE: 'HOSE',
  VN30: 'VN30 ',
  HNX: 'HNX',
  UPCOM: 'UPCOM',
  'txt-label-bond-code': 'Mã TP',
  'txt-label-available-quantity': 'Giao dịch',
  'txt-label-receiving-quantity': 'Số lượng chờ về',
  'txt-label-block-quantity': 'Số lượng nắm giữ',
  'txt-label-total-quantity': 'Tổng số lượng',
  'txt-label-buy-value-total': 'Tổng tiền đã đầu tư',
  'txt-label-total-now': 'Tổng tiền hiện tại',
  'txt-label-listed-security': 'Chứng khoán niêm yết',
  'txt-label-bond-otc': 'Trái phiếu OTC',
  'txt-label-refresh-data': 'Làm mới',
  'txt-no': 'Không',
  'txt-yes': 'Có',
  'txt-clickhere': 'Xem thêm',
  'txt-view-full-chart': 'Mở rộng',
  'txt-market-index': 'Chỉ số thị trường',
  'txt-title-remove-bank-account': 'Xác nhận xóa tài khoản ngân hàng',
  'txt-quantity': 'Số lượng',
  'txt-trigger-price': 'Giá kích hoạt',
  'txt-order-side': 'Chiều đặt lệnh',
  'txt-position': 'Vị thế',
  'txt-current-p/l': 'P/L Hiện tại',
  'txt-total-payment': 'Tổng thanh toán',
  'txt-total-value': 'Tổng tiền',
  'txt-performance': 'Hiệu suất đầu tư',
  'txt-accumulation': 'Gói đã bán',
  'txt-cash-internal-transfer': 'Chuyển tiền nội bộ',
  'txt-missing': 'Thiếu',
  'txt-available-balance': 'Số dư khả dụng',
  'txt-transfer-money': 'Chuyển tiền tới',
  'txt-analysis-report': 'Báo cáo phân tích',
  'txt-tax': 'Thuế',
  'txt-feedback': 'Phản hồi',
  'txt-feedback-success': 'Gửi phản hồi thành công',
  'txt-feedback-unsuccess': 'Gửi phản hồi không thành công',
  'txt-feedback-choosen-category': 'Vui lòng chọn loại phản hồi',
  'txt-feedback-type-message': 'Vui lòng nhập nội dung phản hồi',
  'txt-theme': 'Giao diện',
  'txt-personality': 'Báo cáo đầu tư',
  'txt-upgrade': 'Nâng cấp',
  'txt-upgrade-now': 'Nâng cấp ngay',
  'txt-upgrade-later': 'Để sau',
  'txt-choose-this': 'Chọn',
  'txt-many': '',
  'txt-sell-bond-note':
    'Phí bán PineB cho bên thứ 3 là 1.5% tính trên tổng giá trị giao dịch.',
  'txt-save': 'Lưu',
  'txt-copy': 'Sao chép',
  'txt-copied-to-clipboard': 'Sao chép {{object}}thành công',
  'txt-copied-fail': 'Sao chép thất bại',
  'txt-deactive': 'Dừng hoạt động',
  'txt-active': 'Hoạt động',
  object: {
    file: 'Tệp',
    url: 'Đường dẫn',
    img: 'Hình ảnh',
    text: 'Nội dung',
  },
  'txt-login-to-place-order': 'Đăng nhập để đặt lệnh',
  'txt-open-account-to-place-order': 'Mở tài khoản phái sinh',
  'txt-derivatives-Fee-Tax-T0': 'Thuế phí PS chờ giao T0',
  'txt-loss-vm-delivery': 'Lỗ VM chờ giao',
  'txt-derivatives-debt-pay-late': 'Nợ PS trả sau',
  'txt-position-management-fee': 'Phí quản lý vị thế',
  'txt-asset-management-fee': 'Phí quản lý tài sản',
  'txt-val-after-tax-fee': 'Giá trị sau phí thuế',
  'txt-margin-money-at-VSD': 'Tiền ký quỹ tại VSD',
  'txt-derivative-fee-tax-deb': 'Nợ thuế & phí GD PS',
  'txt-submit': 'Gửi',
  'txt-file-attach': 'File đính kèm',
  'txt-type-content': 'Nhập nội dung',
  'txt-new': 'Mới',
  'txt-old': 'Cũ',

  'txt-new-order-sent-success':
    'Lệnh ({{orderNo}}) đặt <strong>{{orderSide}} {{symbol}}</strong> đã được gửi thành công',
  'txt-cancel-order-success': 'Lệnh ({{orderNo}}) đã gửi hủy thành công',
  'txt-new-order-rejected': 'Lệnh ({{orderNo}}) đã bị từ chối',
  'txt-adjust-order-success': 'Lệnh ({{orderNo}}) đã gửi sửa thành công',

  'txt-waiting-executed': 'Chờ kích hoạt',
  'txt-matched-order-success':
    'Lệnh <strong>{{orderSide}}</strong> ({{orderNo}}) đã khớp {{matchedQuantity}} {{symbolType}} mã <strong>{{symbol}}</strong>.',
  'txt-waiting-match': 'Chờ Khớp',
  'txt-action': 'Hoạt động',
  'txt-remain-quantity': 'KL còn lại',
  'txt-order-drvx-page-invalid':
    'Mã chứng khoán không hợp lệ, vui lòng đặt lệnh phái sinh ở trang Phái Sinh',
  'txt-market': 'Thị trường',
  'txt-important': 'Quan trọng',
  'txt-alert': 'Tín hiệu',
  'txt-notifications-board': 'Bảng Thông Báo',
  'txt-notifications-filter-order':
    'Cập nhật chi tiết trạng thái các lệnh giao dịch chứng khoán',
  'txt-notifications-filter-alert':
    'Cảnh báo trạng thái tài khoản margin và tài khoản phái sinh',
  'txt-mark-read-all': 'Đánh dấu đã đọc hết',
  'txt-loading': 'Đang tải...',
  'txt-reregist-fcm-note':
    'Thông báo sẽ được thực thi sau khi bạn thực hiện login lại',
  'txt-last-trading-date': 'Ngày giao dịch cuối cùng',
  'txt-underlying': 'CK cơ sở',
  'txt-breakeven-point': 'Điểm hòa vốn',
  'txt-strike-price': 'Giá thực hiện',
  'txt-conversion-ratio': 'Tỷ lệ chuyển đổi',
  'txt-leverage': 'Đòn bẩy/cp',
  'txt-no-change-note': 'Vui lòng thay đổi đề xuất chính sách',
  'txt-request-change-sent': 'Gửi yêu cầu thay đổi thành công',
  'txt-vsd-number': 'Số VSD',
  order: {
    normal: 'Thường',
    quick: 'Nhanh',
    conditional: 'Lệnh điều kiện',
  },
  navbar: {
    maxItemToAdd: 'Thêm tối đa 15 danh mục',
    maxItemToPin: 'Chỉ có thể ghim tối đa 5 danh mục',
    watchListName: 'Thêm danh mục',
    symbolExisted: 'Mã đã tồn tại trong danh mục',
  },
  orderChannel: {
    offline: 'Tại sàn',
    cc: 'Lệnh đặt qua điện thoại',
    co: 'Lệnh điều kiện',
    app: 'App',
    bloomberg: 'Bloomberg',
    web: 'Web',
    pist: 'PineX',
    stk: 'Stock123',
  },
  orderPanel: {
    displayOddlot: 'Hiển thị giá lô lẻ',
    warningOddlot:
      'Bạn đang chọn xem giá lô lẻ. \n Vui lòng kiểm tra lại giá trước khi xác nhận.',
    warningLot:
      'Bạn đang đặt lệnh lô lẻ. \n Vui lòng xem lại giá lô lẻ trước khi xác nhận.',
  },
  symbolType: {
    long: {
      symbol: 'cổ phiếu',
      contract: 'hợp đồng',
    },
    short: {
      symbol: 'cp',
      contract: 'hđ',
    },
  },
  quantity: {
    short: {
      b: 'Tỷ',
      m: 'Tr',
      k: 'Ng',
    },
    long: {
      b: 'Tỷ',
      m: 'Triệu',
      k: 'Ngàn',
    },
    many: {
      b: 'Tỷ',
      m: 'Tr',
      k: 'Ng',
    },
  },
  time: {
    short: {
      d: 'Ng',
      w: 'T',
      m: 'Th',
      y: 'N',
    },
    long: {
      d: 'Ngày',
      w: 'Tuần',
      m: 'Tháng',
      y: 'Năm',
    },
    many: {
      d: 'Ngày',
      w: 'Tuần',
      m: 'Tháng',
      y: 'Năm',
    },

    dayOfWeekShort: {
      monday: 'T2',
      tuesday: 'T3',
      wednesday: 'T4',
      thursday: 'T5',
      friday: 'T6',
      saturday: 'T7',
      sunday: 'CN',
    },

    dayOfWeek: {
      monday: 'Thứ 2',
      tuesday: 'Thứ 3',
      wednesday: 'Thứ 4',
      thursday: 'Thứ 5',
      friday: 'Thứ 6',
      saturday: 'Thứ 7',
      sunday: 'Chủ nhật',
    },
    months: {
      january: 'Tháng 1',
      february: 'Tháng 2',
      march: 'Tháng 3',
      april: 'Tháng 4',
      may: 'Tháng 5',
      june: 'Tháng 6',
      july: 'Tháng 7',
      august: 'Tháng 8',
      september: 'Tháng 9',
      october: 'Tháng 10',
      november: 'Tháng 11',
      december: 'Tháng 12',
    },
    toDateLessThanFromDate: 'Ngày đến không được nhỏ hơn từ ngày',
    fromDateGreaterThanToDate: 'Từ ngày không được lớn hơn ngày đến',
  },
  contract: {
    short: {
      c: 'HĐ',
    },
    long: {
      c: 'Hợp đồng',
    },
    many: {
      c: 'Hợp đồng',
    },
  },
  formValidation: {
    required: 'Trường không được để trống.',
    maxLength: 'Chứa tối đa {{length}} ký tự.',
    minLength: 'Chứa tối thiểu {{length}} ký tự.',
    patternLetterNumber: 'Cho phép nhập ký tự chữ cái (a-z, A-Z) và số (0-9).',
  },
  formTrade: {
    enterSymbol: 'Nhập mã',
    enterPrice: 'Giá x1000',
    enterVolume: 'KL x1',
    availableBalances: 'TK khả dụng',
    portfolio: 'Danh mục',
    symbolInformation: 'Thông tin mã CK',
    priceList: 'Chi tiết giá',
    chartDay: 'Đồ thị ngày',
    available: 'GD',
    volume: 'Khối lượng',
    ap: 'Giá TB',
  },
  inday: {
    allSubAcc: 'Tất cả tiểu khoản',
    order: 'lệnh trong ngày',
    orders: 'lệnh trong ngày',
  },
  formForgotPass: {
    errorReason: 'Lỗi: ',
  },
  setting: {
    unReg: 'Hủy đăng ký',
    reg: 'Đăng ký',
    reset: 'Đặt lại',
    service: 'Dịch vụ',
  },
  account: {
    updateProcessing: 'TK đang có yêu cầu thay đổi thông tin chờ xử lý',
    accountAuthorization: 'Mật khẩu & xác thực',
    defaultReg: 'Đăng ký mặc định',
    personal: {
      id: 'Chứng minh nhân dân',
      number: 'Số',
      kind: 'Loại',
      type1: 'CMND',
      type2: 'CCCD',
      residenceAddr: 'Địa chỉ thường trú',
      issuedPlace: 'Nơi cấp',
      issuedDate: 'Ngày cấp',
      expireDate: 'Ngày hết hạn',
      fullname: 'Họ và tên',
      taxNumber: 'Mã số thuế',
    },
    emailPhoneModal: {
      changeTitle: 'Thay đổi thông tin',
      newEmail: 'Địa chỉ email mới',
      newPhone: 'Số điện thoại mới',
      confirmNewOTP: 'Vui lòng nhập mã OTP đã gửi tới số điện thoại mới.',
    },
    addressModal: {
      newContact: 'Địa chỉ mới',
      changeTitle: 'Thay đổi địa chỉ liên hệ',
      changeDesc: 'Chúng tôi sẽ xét duyệt địa chỉ của bạn trước khi cập nhật',
    },
    personalModal: {
      changeTitle: 'Thay đổi thông tin CMND',
      frontImage: 'Ảnh trước',
      backImage: 'Ảnh sau',
      upload: 'Tải lên',
      uploadMaxSize: 'The upload file cannot be larger than 4MB',
      uploadDesc: 'Tải lên ảnh CCCD (<4MB)',
      uploadInvalid: 'Vui lòng tải lên file hình ảnh',
      updateSuccess: 'Gửi yêu cầu thay đổi thông tin thành công',
      compare: 'Vui lòng thay đổi thông tin',
      issuedPlaceWrong: 'Vui lòng chọn đúng thông tin nơi cấp',
    },
    authen: {
      inputOTP: 'Nhập mã OTP',
      inputMatrix: 'Nhập ma trận',
      smartOTPDesc:
        'Tìm kiếm và tải về ứng dụng {{highlight}} từ Appstore, CH Play nếu bạn chưa có',
      smsOTPDesc: 'Quý khách hãy nhập mã số OTP được gửi đến SDT {{phonenum}}',
      matrixDesc: 'Quý khách hãy nhập mã ma trận để xác nhận giao dịch',
      back: 'Quay lại',
      confirm: 'Xác nhận',
      title: 'Chọn phương thức xác thực',
      help: 'Lưu ý: Trường hợp Quý khách không sử dụng PTXT trên, vui lòng đọc hướng dẫn <a class="text-highlight" target="_blank" href="https://pinetree.vn/post/dich-vu/cap-nhat-thong-tin">tại đây</a></span>',
      confirmPassIsNotMatch: 'Xác nhận mật khẩu không khớp',
      oldNewPassNotAllowSame: 'Mật khẩu mới không được giống với mật khẩu cũ',
      letEnterOTPCorrect: 'Vui lòng nhập đúng mã xác thực',
      smartCA: {
        name: 'CA',
        payFee: 'Thanh toán cước cho VNPT',
        registerMethod: 'Đăng ký tại VNPT',
        smartCADesc: 'Vui lòng xác minh qua ứng dụng VNPT',
        completed: 'Hoàn thiện chữ ký số trên app VNPT để xác thực.',
        authenRejected: 'Yêu cầu xác thực ký số đã bị từ chối',
        authenTimeout: 'Yêu cầu xác thực ký số đã hết hạn',
      },
      rightToUseInfor: {
        warningTitle: 'Thông tin sử dụng bị từ chối',
        warningDesc1:
          'Quý Khách vui lòng xác nhận bản Chấp Thuận Xử Lý Dữ Liệu trước 22/07/2023. Sau thời hạn trên, các tính năng giao dịch trực tuyến trên tài khoản bị ngưng do hạn chế về xử lý dữ liệu giao dịch. Pinetree không chịu trách nhiệm về các vấn đề phát sinh do hạn chế trên.',
        warningDesc2:
          'Quý Khách vui lòng xác nhận bản Chấp Thuận Xử Lý Dữ Liệu. Các tính năng giao dịch trực tuyến trên tài khoản sẽ bị ngưng do hạn chế về xử lý dữ liệu giao dịch. Pinetree không chịu trách nhiệm về các vấn đề phát sinh do hạn chế trên. Thông tin vui lòng liên hệ bộ phận Dịch Vụ Khách Hàng: (+84) 24 6282 3535',
        contract: {
          note: "<p class='p-0 fw-400 text text--sm text--light3'>Để tiếp tục sử dụng các nền tảng trực tuyến của Pinetree, Quý Khách vui lòng chấp thuận <a class='text text--light-blue3' href='{{link}}' target='_blanks'>xử lý dữ liệu</a> bằng cách tích chọn vào ô sau:</p>",
          header: 'Kính gửi Quý Khách Hàng',
          agree: 'Tôi đồng ý',
          later: 'Để sau',
          back: 'Quay lại',
          continue: 'Tiếp tục',
          signOut: 'Đăng xuất',
          checkboxText: 'Đồng ý bản Chấp Thuận Xử Lý Dữ Liệu',
          successMsg: 'Xác nhận chấp thuận xử lý dữ liệu thành công',
        },
      },
    },
    security: {
      changeDefaultAccSuccess: 'Đổi tài khoản mặc định thành công',
      changeDefaultAccFail: 'Đổi tài khoản mặc định không thành công',
    },
  },
  personal: {
    nav: {
      asset: {
        name: 'Tài sản',
        portfolio: 'Danh mục',
        assetManagement: 'Báo cáo tài sản',
        overview: 'Tổng quan',
      },
      margin: {
        name: 'Margin',
        marginList: 'Danh sách cổ phiếu ký quỹ',
        marginDebt: 'Công nợ margin',
        marginExtending: 'Gia hạn margin',
      },
      payment: {
        name: 'Thanh toán',
        depositAndWithdrawMoney: 'Nạp / rút tiền',
        depositAndWithdrawMoneyDerivative: 'Nộp / rút tiền ký quỹ',
        cashInternalTransfer: 'Chuyển tiền giữa các tiểu khoản',
        cashAdvance: 'Ứng trước tiền bán',
        stockInternalTransfer: 'Chuyển cổ phiếu',
        rightInfo: 'Tra cứu / đăng ký quyền',
        holdUnholdBank: 'Phong tỏa / giải tỏa tiền',
        inputAmount: 'Nhập số tiền',
      },
      statement: {
        name: 'Sao kê',
        orderStatement: 'Sao kê sổ lệnh',
        conditionalOrderStatement: 'Sao kê lệnh điều kiện',
        cashStatement: 'Sao kê tiền',
        securitiesStatement: 'Sao kê chứng khoán',
        executedGainLossStatement: 'Lãi lỗ đã thực hiện',
      },
      account: {
        name: 'Tài khoản',
        accountInformation: 'Hồ sơ',
        bankAccount: 'Tài khoản ngân hàng',
        authorization: 'Người ủy quyền',
        paswordAuthenticate: 'Mật khẩu & xác thực',
      },
      setting: {
        name: 'Cài đặt dịch vụ',
        SMSServices: 'Dịch vụ SMS',
        registerServices: 'Đăng ký dịch vụ',
      },
    },
    asset: {
      totalAccount: 'Tất cả tiểu khoản',
      totalOverview: {
        totalAsset: 'Tổng tài sản',
        netAssetValue: 'Tài sản ròng',
        withdrawable: 'Tiền được rút',
        availableAdvance: 'Tiền có thể ứng',
        marginDebt: 'Tổng dư nợ margin',
        rTT: 'RTT',
        pP: 'Sức mua',
      },
      assetAllocation: 'Phân bổ tài sản',
      debtToAssetRatio: 'Nợ trên tổng tài sản',
      cash: 'Tiền mặt',
      listedSecurity: 'Chứng khoán niêm yết',
      bondOTC: {
        name: 'Trái phiếu OTC',
        availBond: 'Trái phiếu đang có',
        receiving: 'Trái phiếu chờ về',
      },
      netAssetValue: 'Tài sản ròng',
      debt: 'Nợ',
      derivative: {
        cashAtVSD: 'Giá trị tiền ký quỹ VSD',
        valueAtVSD: 'Giá trị chứng khoán ký quỹ VSD',
        usedMargin: 'Giá trị ký quỹ yêu cầu',
        profitWaitForSettlement: 'Lãi VM',
        liability: 'Nghĩa vụ',
        lossSettlement: 'Lỗ VM',
      },
    },
  },
  bond: {
    fix: {
      penaltyRate: 'Lãi suất phạt',
      penaltyRateNote: 'LS phạt do bán trước hạn.',
      rate: 'LS kỳ gần nhất',
      rateNote: 'Gần nhất so với số ngày thực tế nắm giữ trái phiếu',
      promotionByNav: 'LS ưu đãi',
      rateBeforeTaxFee: 'LS áp dụng',
      rateBeforeTaxFeeNote: 'LS áp dụng = LS kỳ gần nhất + LS ưu đãi - LS phạt',
      rateExtension: 'LS gia hạn',
    },
    moneyFlowWarning: 'Giá trị coupon là sau thuế',
    name: 'Trái phiếu',
    bondOTC: 'Trái phiếu OTC',
    bondListed: 'Trái phiếu công chúng',
    bondPrivate: 'Trái phiếu riêng lẻ',
    viewDetail: 'Xem chi tiết',
    collateral: 'Tài sản đảm bảo',
    collateralDetail: {
      title: 'Chi tiết tài sản đảm bảo',
      type: 'Loại',
      ValuationDate: 'Ngày định giá',
      rate: 'Tỷ lệ đảm bảo',
      value: 'Giá trị',
    },
    introduce: 'GIỚI THIỆU SẢN PHẨM',
    select: 'Chọn...',
    issuer: 'Tổ chức phát hành',
    totalProvisionalValue: 'Tổng giá trị ước tính',
    selAvgPrc: 'Giá trị bán trung bình',
    filter: 'Lọc',
    brochure: 'Tài liệu trái phiếu',
    couponFrequency: 'Tần suất coupon',
    coupon: 'Coupon',
    currentCoupon: 'Coupon hiện tại',
    parValue: 'Mệnh giá',
    forProfessionalInvestors: 'Yêu cầu là NĐT chuyên nghiệp',
    issueDate: 'Ngày phát hành',
    information: 'Thông tin trái phiếu',
    tradeDate: 'Ngày giao dịch',
    allocationQuantity: 'KL phân phối',
    manual: 'Thủ công',
    auto: 'Tự động',
    orderConfirm: 'Xác nhận đặt lệnh',
    terms: 'Kỳ hạn',
    formOfOffering: 'Kiểu phát hành',
    professional: {
      name: 'Nhà đầu tư chuyên nghiệp',
      requiredTitle: 'Sản phẩm dành cho nhà đầu tư chuyên nghiệp',
      requiredDesc:
        'Để đăng ký trở thành nhà đầu tư chuyên nghiệp, quý khách vui lòng xem thêm',
    },
    totalValue: {
      proRate: 'Lãi suất ưu đãi theo NAV',
      realRate: 'Lãi suất thực (sau thuế, phí)',
      extendRate: 'Lãi suất gia hạn',
      name: 'Tổng',
      sell: 'Tổng giá trị bán',
      buy: 'Tổng giá trị mua',
    },
    maxSell: {
      name: 'KL bán tối đa',
      overError: 'Vượt quá khối lượng bán tối đa',
      overDeal: 'KL vượt quá KL của deal',
    },
    maxBuy: {
      name: 'Khối lượng mua tối đa',
      value: 'Hạn mức mua tối đa',
      valueLeft: 'Hạn mức mua còn lại',
      overError: 'Vượt quá khối lượng mua tối đa',
      minError: 'Không đủ khối lượng mua tối thiểu',
      minMoneyError: 'Số tiền đầu tư tối thiểu phải lớn hơn giá mua',
    },
    investAmount: 'Giá trị đầu tư',
    bondQuantity: {
      name: 'Khối lượng đầu tư',
      placeHolder: 'Nhập khối lượng',
    },
    bondTotalSellMoney: 'Tiền bán TP',
    sellMoney: 'Tiền bán',
    principal: 'Số tiền nhận gốc trái phiếu',
    netProfit: 'Tổng tiền lãi dự kiến',
    rate: 'Tỉ lệ lãi suất dự kiến ',
    totalAmountReceive: 'Tổng tiền nhận được dự kiến',
    settlementDate: 'Ngày thanh toán',
    dueDate: 'Ngày đáo hạn',
    quantity: 'Khối lượng',
    totalPayment: 'Tổng tiền thanh toán',
    otcTotalPayment: 'Tổng thanh toán (không gồm phí)',
    otcTooltip:
      '0.1%/tổng giá trị GD theo mệnh giá, tối thiểu 500,000 VND, tối đa 5,000,000 VND.',
    otcNoticeTitle:
      'Pinetree đã tiếp nhận thông tin đặt lệnh của Quý Khách. Pinetree sẽ sớm liên hệ Quý Khách để hướng dẫn thủ tục chuyển nhượng Trái phiếu thông qua Đại lý đăng ký lưu ký.',
    otcNoticeTitle1:
      'Quý Khách không đủ số dư tiền để đặt lệnh trái phiếu. Quý Khách vui lòng liên hệ Pinetree để được hỗ trợ.',
    otcNoticeDesc:
      '<span class="r fw-500">Lưu ý:</span> Trái phiếu BCMbond_20.08 là Trái phiếu riêng lẻ không giao dịch qua sàn HNX, được hưởng lãi suất hấp dẫn nhất trong các sản phẩm PineB, dành cho Khách hàng lựa chọn đầu tư nắm giữ Trái phiếu đến đáo hạn. Khách hàng có nhu cầu bán lại trước hạn có thể tự tìm kiếm đối tác và thực hiện các thủ tục chuyển nhượng thông qua Đại lý đăng ký lưu ký. Quý Khách vui lòng tham khảo tài liệu Trái phiếu <a class="text text--light-blue3 fw-500 cursor-pointer" target="_blank" href="https://pinetree.vn/post/20240202/trai-phieu-bcm/">tại đây</a>',
    contact: 'Liên hệ',
    fee: 'Phí',
    otcFee: 'Theo quy định của Đại lý lưu ký',
    previewMoneyFlow: 'Xem dòng tiền',
    cancel: 'Hủy',
    customerAccountNo: 'Tài khoản',
    dayIntendedToSell: 'Ngày dự định bán',
    bondOrder: 'Lệnh trái phiếu',
    intendedMoneyFlow: 'Mô phỏng dòng tiền dự kiến',
    dealMoneyFlow: 'Dòng tiền theo hợp đồng',
    today: 'Hôm nay',
    contract: {
      name: 'Hợp đồng trái phiếu',
      sign: 'Ký kết Văn kiện Giao dịch Trái phiếu',
      confirm:
        'Bằng cách thực hiện xác nhận dưới đây. Khách hàng khẳng định đồng ý với toàn bộ nội dung của tất cả các Văn kiện giao dịch trái phiếu nêu trên và chấp nhận việc giao kết các Văn kiện giao dịch trái phiếu này với Pinetree.',
      view: 'Xem Văn kiện Giao dịch',
      desc: 'Khách hàng đọc, hiểu và thực hiện các văn kiện giao dịch trái phiếu sau đây dưới hình thức giao dịch điện tử',
      note: ' Pinetree chỉ cung cấp hỗ trợ thanh khoản cho các trái phiếu mà Pinetree phân phối và nhà đầu tư mua từ Pinetree. Hỗ trợ thanh khoản do Pinetree cung cấp sẽ ở mức giá mua lại phù hợp theo điều kiện thị trường tại các thời điểm trong kỳ hạn của Trái phiếu.',
    },
    order: {
      infor: 'Đặt lệnh',
      type: 'Loại lệnh',
      success: 'Đặt lệnh thành công',
      fail: 'Đặt lệnh không thành công',
      dealForSale: 'Danh sách thỏa thuận bán',
      allocateQuantity: 'KL phân bổ',
      remainQuantityToSell: 'KL khả dụng',
    },
    minInvestAmount: {
      name: 'Giá trị đầu tư',
      placeHolder: 'Nhập số tiền tối thiểu',
    },
    productForProInvestor: 'Sản phẩm dành riêng cho Nhà đầu tư chuyên nghiệp.',
    nav: {
      promotion: 'Lãi suất ưu đãi theo NAV',
      name: 'NAV (đồng)',
      value: 'Ưu đãi (%/năm)',
      asset: {
        name: 'Tài sản',
        portfolio: 'Danh mục',
      },
    },
    investor: {
      name: 'Nhà đầu tư',
      perInvestor: '/NĐT',
      type: {
        professional: 'Chuyên nghiệp',
        normal: 'Bình thường',
        unProfessional: 'Không chuyên',
      },
    },
    tableLabel: {
      totalSubAccount: 'Tất cả tiểu khoản',
      subAccount: 'Tiểu khoản',
      dealID: 'Số hợp đồng',
      orderID: 'Số hiệu lệnh',
      productCode: 'Mã sản phẩm',
      inputCode: 'Nhập mã',
      productType: 'Loại sản phẩm',
      bondName: 'Tên trái phiếu',
      bondCode: 'Mã trái phiếu',
      bondType: 'Loại trái phiếu',
      tradeDate: 'Ngày giao dịch',
      maturityDate: 'Ngày đáo hạn',
      rate: 'Lãi suất %/năm',
      quantity: 'Khối lượng',
      order: 'Loại lệnh',
      price: 'Giá',
      maturityDuedate: 'Ngày đáo hạn',
      totalValue: {
        name: 'Tổng giá trị',
        sell: 'Tổng giá trị bán',
        buy: 'Tổng giá trị mua',
        includedFee: 'Tổng giá trị mua (đã tính phí mua)',
      },
      tax: 'Thuế',
      fee: 'Phí bán',
      status: 'Trạng thái',
      rateAfterTaxAndFee: 'Lãi suất (sau thuế và phí)',
      yieldToMaturity: 'LS đến đáo hạn',
      buyPrice: 'Giá mua',
      sellPrice: 'Giá Bán',
      minQuantityOrder: 'KL mua tối thiểu / lệnh',
      maxInvtQtyPerCust: 'KL tối đa / KH',
      collateral: 'Tài sản đảm bảo',
      minQuantity: 'Khối lượng tối thiểu',
      maxQuantityOrder: 'KL tối đa / NĐT',
      availQuantity: 'Khối lượng sẵn có',
      actions: 'Thao tác',
      soldQuantity: 'KL đã bán',
      sellPrc: 'Giá bán TB',
      sellAmt: 'Tổng giá trị bán',
      quantityHasProfit: 'Số lượng có lãi',
      sellQuantity: 'Số lượng bán',
      currentSellQuantity: 'Giá bán hiện tại',
      remainValue: {
        name: 'Giá trị còn lại',
        desc: 'Giá trị còn lại đã trừ phí thuế',
      },
    },

    orderList: {
      name: 'Sổ lệnh',
      label: {
        from: 'Từ',
        to: 'Đến',
        filter: 'Lọc',
        placeHolder: 'Nhập tên trái phiếu',
      },
      statusValue: {
        pending: 'Đang xử lí',
        confirmed: 'Đã xác nhận',
        matched: 'Đã khớp',
        canceled: 'Hủy',
        rejected: 'Từ chối',
      },
    },

    dealList: {
      name: 'Hợp đồng',
      label: {
        from: 'Từ',
        to: 'Đến',
        filter: 'Lọc',
        placeHolder: 'Nhập tên trái phiếu',
        dueDate: 'Ngày kết thúc kỳ đầu tư',
        ratePerYear: 'Lãi suất/năm',
      },

      statusValue: {
        dealed: 'Đã khớp',
        paid: 'Đã thanh toán',
        leg1: 'Leg 1',
        leg2: 'Leg 2',
        done: 'Hoàn tất',
      },
      tooltip: 'Click để xem dòng tiền',
      notableToSell:
        'Không thể bán hợp đồng này do chưa nắm giữ đủ số ngày tối thiếu',
    },

    portfolio: {
      name: 'Danh mục',
      tableLabel: {
        subAccount: 'Tiểu khoản',
        bondName: 'Tên trái phiếu',
        bondCode: 'Mã trái phiếu',
        bondType: 'Loại trái phiếu',
        availableQuantity: 'Số lượng TP nắm giữ',
        receivingQuantity: 'Số lượng TP chờ về',
        blockQuantity: 'Số lượng TP phong tỏa',
        totalQuantity: 'Tổng số lượng',
        buyValueTotal: 'Tổng tiền đã đầu tư',
        totalNow: 'Tổng tiền hiện tại',
      },
    },
    bondDemo: {
      name: 'Demo',
      bank: 'Ngân hàng',
      suggest: 'Đã bao gồm lãi suất ưu đãi',
      var: {
        bondRateLegend: '% lãi suất dự kiến trên năm theo số tháng nắm giữ TP',
        bankRateLegend: '% lãi suất trên năm theo số tháng gửi tiết kiệm NH',
      },
      fix: {
        bondRateLegend: '% lãi suất dự kiến trên năm theo kỳ hạn nắm giữ TP',
        bankRateLegend: '% lãi suất trên năm theo kỳ hạn gửi tiết kiệm NH',
      },
      titleChart: 'Bảng minh họa lãi suất dự kiến trong 1 năm nắm giữ',
      titleChartFBond: 'Bảng minh họa lãi suất dự kiến theo kỳ hạn nắm giữ',
      selectProduct: 'chọn sản phẩm',
      sellDate: 'Ngày bán',
      chooseExptSellDate: 'Chọn ngày bán dự kiến',
      purchaseDate: 'Ngày mua',
      investmentMoney: 'Số tiền đầu tư',
      note: 'Bạn sẽ nhận được tiền như minh họa khi Tổ chức phát hành thanh toán đủ và đúng hạn gốc, trái tức (coupon) của trái phiếu. Với các kỳ coupon mà lãi suất chưa được xác định, dòng tiền minh họa được tính toán trên cơ sở lãi suất coupon kỳ hiện tại. Tỷ lệ lãi suất dự kiến được tính sau thuế, phí giao dịch.',
    },
    bondProduct: {
      name: 'Sản phẩm trái phiếu',
      year: 'năm',
      time: 'lần',
      times: 'lần',
    },
    popUp: {
      chooseSellType: 'Vui lòng chọn phương thức bán',
      quickSell: 'Bán thường',
      sellFromDealList: 'Bán từ hợp đồng trái phiếu',
    },
    retailTrading: {
      desc: 'Customers read, understand and perform the following contracts and documents/documents in the form of electronic transactions',
      title: 'Đăng ký giao dịch TP riêng lẻ',
      confirm:
        'Tôi xác nhận đồng ý với toàn bộ nội dung của bản đăng ký, và hiểu rõ các rủi ro liên quan đến sản phẩm này',
      label: 'Đăng ký giao dịch TP riêng lẻ',
      registSuccess: 'Đăng ký giao dịch TPRL thành công',
      registSuccessDetail:
        'Bạn có thể giao dịch vào ngày làm việc kế tiếp sau khi dịch vụ được kích hoạt thành công tại VSDC. Nếu chưa là Nhà đầu tư chuyên nghiệp, vui lòng xác thực theo <a class="active r" href="https://pinetree.vn/post/dich-vu/xac-nhan-nha-dau-tu-chuyen-nghiep/" target="_blank">hướng dẫn sau</a>',
      pendingNoti: 'Yêu cầu đăng ký giao dịch TPRL của bạn đang chờ VSD xử lý',
      pendingStatus: 'Chờ VSD duyệt',
    },
  },

  cashAdvance: {
    name: 'Ứng trước tiền bán',
    settlementAmount: 'Tiền bán chờ về',
    advanceMaxAmount: 'Tiền có thể ứng tối đa',
  },

  transaction: {
    name: 'Tiện ích',
    input: {
      maxExceeded: 'Tối đa',
      minExceeded: 'Tối thiểu',
    },
    cashInternalTransfer: {
      name: 'Chuyển tiền nội bộ',
      moneyAvailable: 'Số dư',
      withdrawable: 'Số tiền có thể rút',
      transferAmount: 'Số tiền chuyển khoản',
    },
    holdUnholdBank: {
      name: 'Phong Tỏa / Giải Tỏa Tiền',
      currentBalance: 'Số dư hiện tại',
      amountOnHold: 'Số tiền phong tỏa',
      code: 'Code',
    },
    stockInternalTransfer: {
      name: 'Chuyển cổ phiếu',
      holdingVolume: 'KL nắm giữ',
      maxTransfer: 'KL tối đa',
      transferVolume: 'KL chuyển',
      source: 'Nguồn',
      target: 'Đích',
      inputAmount: 'Nhập KL',
    },
    right: {
      name: 'Đăng ký quyền mua',
      history: 'Tra cứu thông tin thực hiện quyền',
    },
    marginList: {
      name: 'Danh sách cổ phiếu ký quỹ',
      symbolName: 'Tên mã CK',
    },
    marginDebt: {
      name: 'Công nợ margin',
      pay: 'Trả nợ',
      extend: 'Gia hạn',
      extenHis: 'Lịch sử gia hạn',
      extendRequestSuccess: 'Gửi yêu cầu gia hạn thành công',
      extendRequestFail: 'Gửi yêu cầu gia hạn không thành công',
      extendRequestSuccessMessage:
        'Đề nghị gia hạn khoản vay ký quỹ của Quý Khách đã được tiếp nhận. Pinetree sẽ xem xét và thông báo kết quả cho Quý Khách trong vòng 05 ngày làm việc.',
      wait: 'Chờ duyệt',
      approved: 'Đã duyệt',
      rejected: 'Từ chối duyệt',
      enterValue: 'Nhập số tiền',
      oldExprdt: 'Ngày đến hạn cũ',
      newExprdt: 'Ngày đến hạn mới',
      extendingTime: 'Số lần gia hạn',
      extendingConfirm: 'Xác nhận gia hạn',
      remainingPrincipalDebt: 'Nợ gốc còn lại',
      extendingFee: 'Phí gia hạn',
    },
    conditionalOrderStatement: {
      name: 'Lịch sử đặt lệnh điều kiện',
      allOrderType: 'Tất cả loại lệnh',
      allStatus: 'Tất cả trạng thái',
      allConditionType: 'Tất cả đều kiện',
      reportName: 'Tên BC',
    },
    withdraw: {
      add: 'Thêm',
      subAccount: 'Tiểu khoản chuyển',
      bankRecipient: 'Ngân hàng nhận',
      qrCode: {
        name: 'Mã QR',
        text1:
          'Sử dụng mã QR được tạo bởi tài khoản thụ hưởng của bạn tại Pinetree',
        text2:
          'Mở ứng dụng ngân hàng để quét mã trực tiếp hoặc lưu ảnh và sử dụng sau',
        saveQR: 'Lưu hình ảnh mã QR',
      },
    },
  },
  trading: {
    allStatus: 'Tất cả trạng thái',
    method: 'Phương thức',
    matchedVol: 'KL khớp',
    activeCondition: 'Điều kiện kích hoạt',
    orderCondition: 'Điều kiện lệnh',
    orderPutThrough: {
      name: 'Sổ lệnh thỏa thuận',
    },
  },
  user: {
    homepage: 'Trang chủ',
    helpCenter: 'Trợ giúp',
    bankAccount: 'Tài khoản ngân hàng',
    passwordAndAuth: 'Mật khẩu & Xác thực',
    authenticated: 'Đã xác thực',
    skypeLink: 'Cuộc gọi skype lúc',
    createPortFolioAcc: 'Tạo tài khoản danh mục',
    openAccount: 'Mở tài khoản {{accType}}',
    success: '{{ActionType}} thành công',
    fail: '{{ActionType}} thất bại',
    registSuccess: 'Đăng ký thành công',
    videoCallState: {
      pending: 'Chờ xử lý YC đặt lịch video call tại {{time}}',
      confirm: 'Link skype để gọi video tại {{time}}',
      reject: 'Từ chối YC đặt lịch video tại {{time}}. Lý do: ',
    },
    bookingMarginVideoCall: {
      open: 'Đăng ký tài khoản ký quỹ',
      title: 'Nâng cấp tài khoản ký quỹ',
      upgrade: 'Nâng cấp hạn mức margin lên {{max}} {{unit}}',
      name: 'Đặt lịch video call để nâng cấp hạn mức margin lên {{max}} {{unit}}',
      selectDayTitle: 'Chọn ngày',
      selectTimeFrameTitle: 'Chọn khung giờ',
      booking: 'Đặt lịch',
      pending: 'Đang chờ',
      rejected: 'Đã từ chối',
      success: 'Đã hoàn thành',
      messageRegistBookingSuccess: 'Gửi yêu cầu đặt lịch thành công',
      messageCancelBookingSuccess: 'Hủy yêu cầu đặt lịch thành công',
      loading: 'Đang tải dữ liệu',
      notiSuccess:
        'Khách hàng đã mở tài khoản margin thành công. Vui lòng đăng xuất ra rồi đăng nhập lại',
      wantUpgrade: 'Bạn có muốn nâng cấp tài khoản?',
      upgradeDesc: 'Sử dụng hạn mức cho vay ký quý lên tới',
      registDesc: 'Giới hạn cho vay ký quỹ của bạn là {{max}} {{unit}} đồng.',
      termAndCondition:
        'Tôi đã đọc và đồng ý với điều khoản và điều kiện giao dịch Ký Quỹ tại Pinetree',
      registSuccess: 'Bạn đã mở tài khoản ký quỹ thành công',
    },
    bookingDerivativeVideoCall: {
      open: 'Đăng ký tài khoản phái sinh',
      upgrade: 'Nâng cấp tài khoản phái sinh',
      name: 'Đặt lịch gọi video để nâng cấp hạn mức phái sinh mức {{max}} {{unit}}',
      wantUpgrade:
        'Bạn có muốn nâng cấp tài khoản để giao dịch phái sinh không giới hạn?',
      upgradeDesc: 'Sử dụng hạn mức cho phái sinh lên tới',
      registDesc:
        'Bạn đã đăng ký tài khoản phái sinh thành công. Hạn mức giao dịch tài khoản phái sinh của bạn {{max}} hợp đồng.',
      termAndCondition:
        'Tôi đã đọc và đồng ý với điều khoản và điều kiện giao dịch Phái Sinh tại Pinetree',
      registSuccess: 'Đăng Ký Thành Công',
    },
  },
  news: {
    newsfeed: 'Bảng tin',
    hotnews: 'Tin nổi bật',
    hotArticle: 'Xem nhiều nhất',
    lastestArticle: 'Lastest Article',
    allWatchlist: 'Tin theo danh mục',
    allCategory: 'Tin theo chủ đề',
    loadmore: 'Xem thêm...',
    relatedVideo: 'Video liên quan',
    watchNow: 'Xem ngay',
    allMarket: 'Tất cả thị trường',
    usIndices: 'Chỉ số Mỹ',
    globalIndices: 'Chỉ số thế giới',
    commodity: 'Hàng hóa',
    video: 'Video',
    marketBrief: 'Bản tin thị trường',
    morningBrief: 'Bản tin buổi sáng',
    marketNews: 'Thông tin thị trường',
    attachment: 'Tài liệu tính kèm',
  },
  pinefolio: {
    name: 'PineFolio',
    list: 'Danh sách PineFolio',
    investPlan: 'Kế hoạch Đầu tư',
    code: 'Mã',
    performance: 'Biến động giá Danh mục',
    vniChart: 'VNI',
    pinefolioChart: 'PineFolio',
    stockOutside: 'Cổ phiếu ngoài PineFolio',
    stockOutsideSellNotice: 'Vui lòng bán bond ở mục quản lý kế hoạch.',
    noOdd: 'Không có mã lẻ để bán',
    pf: 'PF',
    pfCode: 'Mã PF',
    risk: {
      aggressive: 'Tấn công',
      balanced: 'Cân bằng',
      defensive: 'Phòng thủ',
      simple: 'Đơn giản',
      other: 'Khác',
    },
    nav: {
      productList: 'Sản phẩm',
      orderList: 'Sổ lệnh',
      portfolio: 'Danh mục',
      planManagement: 'Quản lí kế hoạch',
      analysisReport: 'Báo cáo phân tích',
    },
    theme: {
      report: {
        date: 'Ngày báo cáo',
        type: 'Loại báo cáo',
        fileName: 'Tên tệp',
      },
      performanceExplain:
        'Là % biến động giá khớp so với giá tham chiếu Danh mục của ngày giao dịch hiện tại. Giá khớp của Danh mục được tính theo giá khớp của từng mã chứng khoán trong danh mục theo khối lượng tiêu chuẩn của 1 set, có độ trễ 5 giây so với thực tế.',
      value: {
        desc: 'Giá trị thị trường của danh mục tính theo giá khớp hiện tại của các mã chứng khoán và có độ trễ 5 giây so với thực tế.',
      },

      allocationRate: {
        desc: 'Tỉ lệ phân bổ được tính theo giá trị các chứng khoán trong danh mục dựa trên giá tham chiếu của ngày giao dịch.',
      },
    },
    rebalancePortfolio: {
      name: 'Hoán đổi danh mục',
      rebalanceRate: 'Tỉ lệ hoán đổi',
      volumeInPortfolio: 'KL trong danh mục',
      volumeInPlan: 'KL trong kế hoạch',
      reblanceConfirm: 'Đồng ý tái cấu trúc danh mục',
      reblanceConfirm2: 'Xác nhận tái cấu trúc danh mục',
      later: 'Để sau',
    },
    plan: {
      name: 'Kế hoạch',
      depositTo: 'Thanh toán',
      rebalance: 'Hoán đổi',
      planRunning: 'KH hiệu lực',
      depositRemain: 'Giá trị tích lũy mục tiêu',
      deposited: 'Đã tích lũy',
      running: 'Đang thực hiện',
      messageComplete: 'Bạn đã hoàn thành thanh toán',
      messageCompleteDesc:
        'Tôi đồng ý dịch chuyển tương ứng với “Ngày bắt đầu”, “Ngày đặt mua” của tất cả “Kỳ đầu tư” tiếp theo.',
      orderBuyNow: 'Đặt mua ngay',
      buyFollowPlanAt: 'Mua theo KH vào',
      depositConfirm: 'Xác nhận thanh toán',
      remain: 'Chưa tích lũy',
      depositAmount: 'Mục tiêu tích lũy',
      amountNextPeriod: 'Số tiền kỳ sau',
      cashAvailable: 'Tiền mặt có sẵn',
      completedPlan: 'Hoàn thành kế hoạch',
      startDepositDate: 'Ngày bắt đầu kế hoạch',
      buyingOrderDate: {
        name: 'Ngày đặt mua',
        explain:
          'Là ngày đặt mua dự kiến theo kế hoạch tạo lập ban đầu hoặc điều chỉnh của Kế hoạch đầu tư liên quan.',
      },
      numberOfPeriod: 'Số lần nạp',
      depositDate: 'Ngày tích lũy',
      depositType: {
        name: 'Loại nạp tiền',
        schedule: 'Kế hoạch',
        manual: 'Thường',
      },
      dayOfInterestRate: 'Số ngày hưởng lãi suất',
      dayOfInterestRateDesc:
        'Tính từ ngày tích lũy đến ngày kết thúc dự kiến hoặc ngày kết thúc thực tế của Kỳ đầu tư.',
      estimateInterest: 'Lãi dự kiến',
      estimateInterestDesc:
        'Kỳ tính lãi tính từ ngày tích lũy đến ngày kết thúc dự kiến của Kỳ đầu tư.',
      stopPlan: 'Hủy kế hoạch',
      pack: 'Pack',
      planId: 'Mã kế hoạch',
      portfolioCode: 'Mã danh mục',
      planType: 'Loại kế hoạch',
      depositStartDate: 'Ngày bắt đầu',
      planStartDate: 'Ngày bắt đầu kế hoạch',
      totalAmountOfPlan: 'Tổng giá trị kế hoạch',
      stopPlanDate: {
        name: 'Ngày kết thúc',
        explain:
          'Là ngày hoàn thành hoặc Ngày hủy của Kế hoạch đầu tư liên quan.',
      },
      receptedInterest: {
        name: 'Lãi thực nhận',
        explain:
          'Là tổng các khoản lãi thực nhận của tất cả các lần tích lũy trong Kế hoạch đầu tư liên quan.',
      },
      fullSet: 'Theo set',
      depositSuccess: 'Nạp tiền thành công',
      stopPlanNotice:
        'Hệ thống sẽ dừng tất cả các kỳ đầu tư chưa hoàn thành. Các hợp đồng bond chưa hoàn thành của Kế hoạch vẫn tiếp tục có hiệu lực cho đến khi khách hàng thực hiện bán hết.',
      depositedValue: 'Giá trị TP đang tích lũy',
      yesStopPlan: 'Xác nhận dừng',
      stopPlanSuccess: 'Dừng kế hoạch thành công',
      splitSet: 'Theo pack',
      submit: 'Xác nhận',
      confirmEditPlanMsgBf:
        'Hệ thống sẽ tự động dịch chuyển ngày bắt đầu, ngày kết thúc và lịch nạp tiền của các kỳ tiếp theo muộn hơn',
      confirmEditPlanMsgafter:
        'ngày. Nếu ngày kết thúc sau khi lùi rơi vào ngày nghỉ thì sẽ tự đông lấy ngày giao dịch gần ngày với nghỉ đó nhất.',
      toastMsgEditSucess: 'Chỉnh sửa kế hoạch thành công',
      viewPlanTicket: 'Xem phiếu lệnh',
      depositAmountIntended: 'Mục tiêu tích lũy',
      nontermInterestRate: 'Lãi suất hỗ trợ',
      supportInterestRate: 'Lãi suất lên đến 11%/ năm',
      supportInterestRateTable: 'Bảng lãi suất hỗ trợ',
      createPlan: {
        name: 'Tạo kế hoạch',
        contract: {
          name: 'Phiếu đăng kí kế hoạch đầu tư',
        },
        createPlanTitle: 'Tạo kế hoạch tích lũy bond để mua PF',
        createPlanTitleSm:
          'Mỗi kì đầu tư không dài quá 90 ngày tính từ ngày bắt đầu đến ngày đặt mua.',
        confirmCreatePlan:
          'Tôi đồng ý với toàn bộ nội dung của tất cả các hợp đồng và chấp nhận việc giao kết các hợp đồng và các chứng từ/văn bản giao dịch với pinetree',
        buyingOrderDateExplain:
          'Ngày đặt mua: Được tùy chọn nhưng phải đảm bảo không vượt quá 90 ngày kể từ ngày tạo lập/bắt đầu của kỳ đầu tư. Nếu không phải là ngày làm việc, thì lệnh sẽ được đặt vào ngày làm việc tiếp theo.',
        createPlanNote:
          'Giá trị mục tiêu của Kế hoạch/Kỳ đầu tư được tạm tính theo giá trần của các chứng khoán trương ứng tại ngày lập cộng với phần dự phòng biến động giá.',
        createPlanNoteSplitSet:
          'Ngày bắt đầu của kỳ đầu tư sau là ngày liền kề sau ngày kết thúc của kỳ đầu tư trước đó.',
        createPlanNote1:
          'Một Kế hoạch đầu tư có thể chia thành một kỳ đầu tư ("Theo set") hoặc nhiều kỳ đầu tư ("Theo pack"). Khi lựa chọn nhiều kỳ đầu tư, số kỳ đầu tư sẽ tương ứng với số phần (“Pack”) của Danh mục, mỗi kỳ đầu tư chỉ được mua 1 pack của Danh mục.',
        supportRateExplain:
          'Lãi suất hỗ trợ được tính theo ngày tích lũy thực tế và được trả vào ngày kết thúc của kỳ đầu tư.',
        lastDepositDate: 'Hạn nạp tiền',
        createPlanNote2:
          'Ngày đặt mua được điều chỉnh lùi so với kế hoạch nhưng  không được vượt quá 90 ngày kể từ ngày tạo lập/bắt đầu của kỳ đầu tư. Nếu không phải là ngày làm việc, thì lệnh mua tự động sẽ được đặt vào ngày làm việc tiếp theo.',

        monthlyDay: {
          name: 'Ngày hàng tháng',
          placeHolder: 'Nhập từ 1 đến 31',
        },
        weeklyDay: { name: 'Ngày trong tuần', placeHolder: 'Nhập từ 2 đến 6' },
        getReminder: {
          name: 'Nhận lời nhắc',
          none: 'Không',
          monthly: 'Hàng tháng',
          weekly: 'Hàng tuần',
        },
        bondAccumulatedValue: 'Giá trị tích lũy trái phiếu',
        saveChangeReminder: 'Lưu lời nhắc thay đổi',
        saveChangePlan: 'Lưu thay đổi Kế hoạch',
        cancelPlan: 'Hủy Kế hoạch',
        buyBond: 'Mua trái phiếu',
      },
      buyDateBefore: 'Ngày mua cũ',
      buyDateAfter: 'Ngày mua mới',
      depositHistory: 'Lịch sử tích lũy',
      planHistory: {
        name: 'Lịch sử kế hoạch',
        explain:
          'Thể hiện thông tin của tất cả các kế hoạch đầu tư đã kết thúc (hoàn thành hoặc hủy).',
      },
      endDateExplain:
        'Là Ngày mua đặt dự kiến hoặc Ngày hoàn thành hoặc Ngày hủy của Kỳ đầu tư/Kế hoạch đầu tư liên quan.',
      accumulateMoney: 'Tích lũy tiền',
      setValue: {
        name: '',
        explain:
          'Giá trị mục tiêu của Kế hoạch/Kỳ đầu tư được tạm tính theo giá trần của các chứng khoán trương ứng tại ngày lập cộng với phần dự phòng biến động giá.',
      },
      bondOrderList: {
        name: 'Sổ lệnh trái phiếu',
      },
      bondDealList: {
        name: 'Hợp đồng trái phiếu',
        pfYN: {
          name: 'PF YN',
          yes: 'Y',
          no: 'N',
          desc: 'Y: hợp đồng bond đã bị tách khỏi plan \n N: Hợp đồng bond đang thuộc plan',
        },
      },
      noticeChangePack:
        'Chỉ hỗ trợ sửa ngày mua cho từng pack một. \n Bạn đang sửa ngày mua của pack {{packEditing}}. \n Hệ thống sẽ bỏ sửa ngày mua của pack vừa nhập trước đó.',
    },
    orderList: {
      name: 'Tên danh mục',
      riskLevel: 'Mức độ rủi ro',
      pinefolioCode: 'Mã PineFolio',
      tradeDateFrom: 'Ngày giao dịch từ',
      dateFrom: 'Từ ngày',
      dateTo: 'Đến ngày',
      inputFileName: 'Nhập tên tệp',
      tradeDateTo: 'Ngày giao dịch tới',
      matchAvgPrice: 'Giá khớp TB',
      matchedVol: 'Khối lượng khớp',
      totalValueAfterTaxFee: 'Tổng giá trị sau phí thuế',
      orderPrice: 'Giá mua',
      orderVolume: 'Khối lượng đặt',
      quantity: 'KL đặt',
      matched: {
        name: 'KL khớp',
        desc: 'Chỉ thể hiện khối lượng khớp theo từng set hoàn chỉnh',
      },
      expireDate: 'Ngày hết hạn',
      expireDateExplain:
        'Lệnh mua có hiệu lực trong vòng 5 ngày làm việc. Lệnh bán có hiệu lực trong vòng 1 ngày làm  việc.',
      investType: 'Loại đầu tư',
      investTypeExplain:
        'Thể hiện nguồn của các lệnh được đặt. Từ Khách hàng đặt Trực tiếp hoặc từ Kế Hoạch Đầu Tư hoặc từ Tái Cấu Trúc.',
      orderAmp: {
        name: 'Giá trị đặt',
        explain:
          'Giá trị lệnh đặt được tính theo quy tắc: lệnh mua thì theo giá trần và lệnh bán thì theo giá sàn',
      },
      orderDate: 'Ngày đặt',
      status: {
        new: 'Mới',
        executing: 'Đang thực hiện',
        completed: 'Đã hoàn thành',
        cancel: 'Hủy',
        break: 'Hủy',
        expired: 'Đã hết hạn',
        processing: 'Đang thực hiện',
        canceled: 'Đã hủy',
        waitingForExecuting: 'Chờ thực hiện',
      },
      source: {
        direct: 'Trực tiếp',
        accumulation: 'Tích lũy',
        rebalance: 'Tái cấu trúc',
      },
      value: {
        desc: 'Giá trị thị trường của danh mục tính theo giá khớp hiện tại của các mã chứng khoán và có độ trễ 5 giây so với thực tế.',
      },
      matchValue: {
        name: 'GT khớp',
        explain:
          'Được tính theo khối lượng khớp và giá khớp thực tế của các mã chứng khoán trong danh mục. Tương tự với phần phí, thuế.',
      },
    },
    account: {
      openNew: 'Mở mới tiểu khoản PinePolio để sử dụng dịch vụ',
      confirm:
        'Tôi đã đọc và đồng ý với các Điều khoản và Điều kiện Giao dịch Danh mục tại Pinetree.',
      regisNewSucess: 'Tạo tài khoản PineFolio thành công',
      regisNewFail: 'Tạo tài khoản PineFolio thất bại',
    },
    order: {
      confirm:
        'Bằng cách thực hiện xác nhận dưới đây, Khách hàng khẳng định đã đọc và đồng ý với toàn bộ nội dung của tất cả Văn kiện giao dịch danh mục nêu trên.',
      buy: 'Đặt mua',
      sell: 'Đặt bán',
      viewOrderTicket: 'Phiếu lệnh giao dịch',
      overMaxSell: 'Vượt quá khối lượng bán tối đa',
      overPP: 'Vượt quá sức mua',
      buyOrderExplain:
        'Giá trị thanh toán được tạm tính theo giá trần của ngày giao dịch, theo quy tắc của các loại lệnh ATO, ATC, MP, MTL. Giá trị thanh toán thực tế sẽ dựa trên giá khớp theo thông báo của Sở Giao dịch.',
      sellOrderExplain:
        'Giá trị lệnh được tạm tính theo giá sàn của ngày giao dịch, theo quy tắc của các loại lệnh ATO, ATC, MP, MTL.',
      value: {
        desc: 'Giá trị thị trường của danh mục tính theo giá khớp hiện tại của các mã chứng khoán và có độ trễ 5 giây so với thực tế.',
      },
      totalMoneyTemporary: 'Tổng tiền tạm tính',
      feeTemporary: 'Phí tạm tính',
      taxTemporary: 'Thuế tạm tính',
      explain1:
        'Khách hàng có thể hoàn thành trước kỳ hạn hoặc điều chỉnh Ngày Đặt Mua của Kế hoạch đầu tư theo Điều kiện và Điều khoản của sản phẩm.',
      sellOrderExplain1:
        'Lệnh bán PF có hiệu lực trong vòng 01 ngày làm việc. Không thể sửa/hủy trong giờ giao dịch.',
      sellOrderExplain2:
        'Loại lệnh mua chứng khoán đẩy lên Sở gồm ATO, ATC, MTL, tùy theo phiên và Sở giao dịch.',
      buyOrderExplain1:
        'Lệnh mua PF có hiệu lực trong vòng 05 ngày làm việc. Không thể sửa/hủy trong giờ giao dịch.',
      buyOrderExplain2:
        'Loại lệnh mua chứng khoán đẩy lên Sở gồm ATO, ATC, MTL, tùy theo phiên và Sở giao dịch.',
      note: 'Lưu ý',
      referenceValues: {
        name: 'Giá trị tham chiếu',
        desc: 'Giá trần - Giá trị trần của danh mục được tính theo giá trần các chứng khoán trong Danh mục theo khối lượng tiêu chuẩn của 1 set \n Giá Sàn - Giá trị sàn của danh mục được tính theo giá sàn các chứng khoán trong Danh mục theo khối lượng tiêu chuẩn của 1 set \n Giá Tham chiếu - Giá trị tham chiếu của danh mục được tính theo giá tham chiếu các chứng khoán trong Danh mục theo khối lượng tiêu chuẩn của 1 set',
      },
      sellingBondValue: 'Giá trị bán trái phiếu',
      expectedToSellBond: 'Dự kiến ​​bán trái phiếu',
      expectedToUseOfPP: 'Dự kiến ​​sử dụng PP',
      viewChangePlanTicket: 'Xem phiếu thay đổi kế hoạch',
      changePlanTicket: 'Phiếu thay đổi kế hoạch',
      buyingOrderNewDate: 'Ngày mua mới',
      buyingOrderOldDate: 'Ngày mua cũ',
      listBondForSell: 'Danh sách trái phiếu',
    },
    rebalance: {
      name: 'Tái cấu trúc',
      title: 'Chúng ta có một sự tái cấu trúc để tối ưu hóa danh mục',
      desc: 'Thời gian thực hiện tái cấu trúc từ {{fromDate}} đến {{toDate}}',
      contractHeader: 'Xác nhận tái cấu trúc danh mục',
      confirm:
        'Tôi đã đọc và đồng ý với Điều khoản và Điều kiện Giao dịch Pinefolio tại Pinetree.',
      success: 'Tái cấu trúc danh mục thành công',
    },
    portfolio: {
      warning: 'KL có thể bán chỉ tính trên số dư CK nằm ngoài Danh mục PF.',
      view: 'Xem số dư danh mục PF',
      name: 'Tên sản phẩm',
      riskLevel: 'Độ rủi ro',
      avgValue: 'Giá trị TB / set',
      avgValueExplain:
        'Bằng giá gốc bình quân của từng mã chứng khoán x khối lượng tiêu chuẩn của mã chứng khoán tương ứng trong 1 set danh mục.',
      mrkValue: 'Giá trị thị trường / set',
      performance: {
        name: 'Hiệu suất',
        perYear: 'Trong năm',
        perMonth: 'Trong tháng',
        perDay: 'Trong ngày',
        explain:
          'Là % biến động giữa giá trị thị trường và giá trị gốc trung bình của 1 set danh mục tiêu chuẩn.',
      },
      avail: 'GD',
      availQty: 'KLGD',
      availExplain:
        'Là khối lượng set Danh mục có thể giao dịch trong ngày (set hoàn chỉnh).',
      totalQ: {
        name: 'Tổng KL',
        explain: 'Là khối lượng set Danh mục đang nắm giữ (set hoàn chỉnh).',
      },
      totalV: 'Tổng Giá trị',
      allocation: 'Phân bổ',
      action: '#',
      hideSoldOutPinefolio: 'Ẩn PineFolio đã bán hết',
      volatility: 'Biến động trong ngày',
      marketPrice: 'Giá thị trường',
      set: 'Set',
      setExplain:
        'Là khối lượng của từng mã chứng khoán mà tròn set theo khối lượng tiêu chuẩn của danh mục.',
      odd: 'Lẻ set',
      oddExplain:
        'Là khối lượng của từng mã chứng khoán mà không tròn (lẻ) set theo khối lượng tiêu chuẩn của 1 set danh mục của từng mã chứng khoán.',
    },
    analysisReport: {
      note: 'Các báo cáo chuyên sâu chỉ dành cho những người nắm giữ danh mục đầu tư.',
    },
  },
  marginCompetition: {
    name: 'Cuộc thi Chứng trường bạc tỷ',
    noRoundStarted: 'Vòng thi chưa bắt đầu',
    generalIntroduction: {
      name: 'Giới thiệu chung',
      coHostedBy: 'Đồng tổ chức bởi',
      diversifiedGroups: 'Đa dạng bảng đấu',
      superPrize: 'Siêu giải Master Investor 500 triệu đồng',
      totalAward: 'Tổng giải thưởng lên đến 1,5 tỷ đồng',
      fairAndRule: 'Quy tắc công bằng và minh bạch',
      eazyToReg: 'Dễ dàng đăng ký',
      zeroFee: 'Giao dịch 0 phí trọn đời',
      fixedMargin: 'Tận hưởng 9.9% margin cố định',
      roundStartIn: 'Vòng {{round}} bắt đầu trong',
      registrationPeriod: 'Thời gian đăng ký',
      competingPeriod: 'Thời gian thi đấu',
      roundFinished: 'Vòng {{round}} đã kết thúc.',
      roundIncoming: 'Vòng {{round}} sắp diễn ra',
      roundCompeting: 'Vòng {{round}} đang thi đấu',
      agreeWithTC:
        '<a class="text--light-blue3" target="_blank" href="{{link}}">Tôi đồng ý với Điều khoản và Điều kiện của cuộc thi</a>',
      viewResult: 'Xem kết quả',
      viewInfo: 'Xem thông tin',
      selectAccount: 'Chọn loại tài khoản để thi đấu',
      selectAccountForCompetition: 'Chọn loại tài khoản để thi đấu',
      content:
        'Đồng tổ chức bởi Chứng khoán Pinetree và Trang tin điện tử Cafef (Cafef.vn), cuộc thi "Chứng trường bạc tỷ" lần đầu tiên ra mắt với quy mô lớn trên toàn quốc, nhằm mang đến sân chơi thú vị giúp Nhà đầu tư chứng khoán trải nghiệm, tích lũy kỹ năng đầu tư và giành giải thưởng hấp dẫn',
      messageRegisCptSuccess:
        '<span>Đăng ký tham gia cuộc thi thành công.</span><span> Nickname thi đấu của quý khách là {{nickName}}</span>',
      textConfirmUnReg: 'Bạn có muốn hủy đăng ký không.',
      registed: 'Đã đăng ký',
      stopRace: 'Hủy đăng ký',
      unRegisterSuccess: 'Hủy đăng ký thành công',
      openMarginAccount:
        'Nếu quý khách chưa mở tài khoản margin. Vui lòng tham khảo hướng dẫn kích hoạt <a class="text-warning fw-500" target="_blank" href="{{link}}">tại đây</a>',
      days: ' ngày',
      hours: ' giờ',
      min: ' phút',
      sec: ' giây',
    },
    ruleAndAwards: {
      name: 'Thể lệ và giải thưởng',
      award: {
        title: 'Giải thưởng',
        superPrize: 'Siêu giải',
        masterInvestor: 'Master Investor',
        billion: 'tỷ',
        million: 'triệu',
      },
      awardRule: {
        title: 'Quy định chung về giải thưởng',
        rule1:
          'Giải thưởng được xét theo bảng. Trong trường hợp một giải thưởng có nhiều hơn 1 nhà đầu tư chiến thắng hợp lệ, thứ tự ưu tiên xét giải dựa trên: Giá trị giao dịch lớn hơn -> Tổng số ngày giao dịch nhiều hơn -> Vốn đầu tư ban đầu lớn hơn -> Ngày đăng ký sớm hơn.',
        rule2:
          'Trường hợp nhà đầu tư có % hiệu quả đầu tư bằng nhau và thứ tự ưu tiên xét giải bằng nhau sẽ đồng chia giải thưởng. Cách xác định giá trị giải thưởng mỗi Người chơi nhận được như sau:',
        rule3:
          'Giải thưởng = (Tổng giá trị giải thưởng của các vị trí đồng kết quả)/số người đồng kết quả.',
      },
      receptAward: {
        title: 'Cách thức nhận thưởng',
        rule1:
          'Quà tặng bằng hiện vật có thể được trao ngay hoặc gửi cho khách hàng trong vòng 30 ngày kể từ ngày đủ điều kiện nhận quà',
        rule2:
          'Quà tặng bằng tiền được chuyển vào Tài khoản thi đấu của khách hàng trong vòng 30 ngày kể từ ngày Pinetree nhận được bằng chứng hợp lệ chứng minh khoản phí/lệ phí dịch vụ chuyển khoản chứng khoán mà khách hàng cung cấp',
        rule3:
          'Tiền mặt chuyển vào tiểu khoản thi đấu của người thắng giải trong vòng 45 ngày làm việc kể từ ngày kết thúc vòng thi. Giá trị thực nhận phải tuân theo quy định về luật Thuế của Nhà nước',
        rule4:
          'Đối với giải thưởng Master Investor, người thắng giải được nhận tiền mặt vào tiểu khoản thi đấu hoặc tiểu khoản khác. Tiền thưởng được dùng để đầu tư, giao dịch chứng khoán trên tiểu khoản trong vòng 01 năm kể từ ngày nhận thưởng và không được sử dụng vào các mục đích khác. Trong thời gian này, khách hàng được bổ sung thêm tiền/chứng khoán vào tiểu khoản, nhưng không được rút bất kỳ khoản tiền hay chứng khoán nào ra khỏi tiểu khoản đó. Hết thời hạn 01 năm nêu trên, khách hàng được rút tiền/chứng khoán như bình thường và kết quả giao dịch trên tiểu khoản do khách hàng tự chịu trách nhiệm. Nếu khách hàng có bất kỳ vi phạm nào trong giai đoạn này, giải thưởng sẽ bị hủy bỏ theo quyết định của Pinetree',
      },
      seeMore: 'Xem thêm',
      rank: 'Hạng',
      competingRule: 'Thể lệ cuộc thi',
      competitionAccountRule: {
        title: 'Quy định về Tài khoản thi đấu',
        rule1: 'Tiểu khoản Thường hoặc Margin mở tại Chứng khoán Pinetree',
        rule2:
          'Điều kiện tham gia: Vốn đầu tư ban đầu trong tiểu khoản thi đấu tại Pinetree tối thiểu 10 triệu đồng',
      },
      conditionsForConsideration: {
        title: 'Điều kiện xét giải',
        rule1: 'Hiệu quả đầu tư trên tiểu khoản thi đấu dương (>0%).',
        rule2: 'Giá trị giao dịch tối thiểu 200% vốn đầu tư trung bình.',
        rule3: 'Số ngày phát sinh giao dịch khớp lệnh tối thiểu 3 ngày.',
      },
      tableDivision: {
        title: 'Phân bảng',
        rule1: 'Tài khoản thi đấu được chia làm 3 bảng:',
        carpGroup:
          'Bảng cá Chép: Vốn đầu tư ban đầu từ 10 triệu đến dưới 100 triệu đồng.',
        koiGroup:
          'Bảng cá Koi: Vốn đầu tư ban đầu từ 100 triệu đến dưới 1 tỷ đồng.',
        whaleGroup: 'Bảng cá Voi: Vốn đầu tư ban đầu từ 1 tỷ đồng trở lên.',
      },
      ruleForCompetitionSecurities: {
        title: 'Quy định về chứng khoán thi đấu',
        rule1:
          'Chỉ áp dụng cho giao dịch cổ phiếu trên thị trường chứng khoán cơ sở.',
        rule2:
          'Tất cả các mã cổ phiếu trên HOSE/HNX/UPCOM trong danh sách được phép giao dịch trong cuộc thi theo quy định của công ty.',
      },
      actsOfViolatingTheRule: {
        title: 'Các hành vi vi phạm thể lệ',
        rule1: 'Lũy kế vốn đầu tư thêm vượt quá 5 lần vốn đầu tư ban đầu',
        rule2:
          'Chuyển thêm/rút chứng khoán vào/từ tiểu khoản thi đấu (gồm lưu ký chứng khoán, chuyển chứng khoán nội bộ) trong thời gian thi đấu hoặc thực hiện trước ngày thi đấu nhưng ngày hiệu lực chứng khoán ghi nhận vào tiểu khoản thuộc khoảng thời gian từ ngày thi đấu',
        rule3: 'Có phát sinh giao dịch thỏa thuận và lô lẻ',
      },
      caculationFomula: {
        title: 'Công thức tính',
        fomula: 'Hiệu quả đầu tư (%) = Lợi nhuận đầu tư/Vốn đầu tư bình quân',
      },
      otherGifts: {
        title: 'Quà tặng khác',
        gift1: '2000 quà tặng đăng ký sớm hoặc tham gia sự kiện',
        gift2:
          'Miễn 100% phí chuyển chứng khoán (tối đa 1.000.000 đồng/tài khoản)',
        gift3: 'Cơ hội làm việc tại Pinetree',
        gift4: 'Cơ hội hợp tác với Pinetree',
        gift5: 'Hưởng các đặc quyền ưu đãi khác',
      },
      seeMoreRuleDetail: 'Xem thêm chi tiết quy định',
    },
    performance: {
      name: 'Hiệu quả đầu tư',
      profit: 'Lợi nhuận',
      loss: 'Lỗ',
      initialCapital: 'Vốn ban đầu',
      currentAverageCapital: 'Vốn bình quân hiện tại',
      totalWithdrawalAmount: 'Tổng tiền đã rút',
      totalAmountDeposited: 'Tổng tiền nộp ròng',
      assetTurnoverRate: 'Tỷ lệ xoay vòng vốn',
      totalDepositedNAV: 'Tổng tiền nộp ròng/Vốn ban đầu',
      distribution: 'Phân bổ',
      investmenteffect: 'Hiệu quả đầu tư',
      tooltip: {
        distribution:
          'Tỉ lệ phân bổ = giá trị thị trường của từng mã CK/ tổng giá trị thị trường của tất cả các mã CK.',
        assetTurnoverRate:
          'Tỉ lệ quay vòng vốn = (Tổng giá trị khớp mua + bán lũy kế)/ Vốn bình quân hiện tại.',
        totalDepositedNAV: 'Tổng tiền nộp ròng = tổng nạp - tổng rút',
      },
    },
    leaderBoard: {
      name: 'Bảng xếp hạng',
      round: 'Vòng',
      nickname: 'Nickname',
      pnl: ' PnL',
      topTrading: 'Giao dịch nhiều nhất',
    },
    QA: {
      name: 'Câu hỏi thường gặp',
    },
  },
  derivative: {
    name: 'Phái sinh',
    contracts: 'Hợp đồng',
    long: 'Mua',
    short: 'Bán',
    volLong: 'KL Long',
    volShort: 'KL Short',
    maxLong: 'KL Long tối đa',
    maxShort: 'KL Short tối đa',
    expireDate: 'Ngày ĐH',
    only: 'Chức năng này chỉ dành cho tiểu khoản phái sinh',
    marginAmountCanBeDeposit: 'Số tiền khả dụng có thể nộp KQ VSD',
    marginAmountCanBeWithdrawn: 'Số tiền ký quỹ có thể rút',
    marginDepositAmount: 'Số tiền nộp',
    marginWithdrawalAmount: 'Giá trị rút',
    dwmconfirm: 'Xác nhận {{action}}',
    depWdrawAction: {
      deposit: 'Nộp ký quỹ',
      withdraw: 'Rút ký quỹ',
    },
    depWdrawRequestSuccess: '{{action}} thành công',
    quantityOverError: 'Vượt quá mức số tiền có thể {{action}}',
    label: {
      contractCode: 'Mã hợp đồng',
      closedPrice: 'Giá đóng',
      closedQuantity: 'KL đã đóng',
      multiplier: 'Hệ số nhân',
      matchedTime: 'Thời gian khớp',
      matchedPrice: 'Giá khớp',
      matchedVolume: 'KL khớp',
      vn30Index: 'Chỉ số VN30',
      p1: 'Giá 1',
      p2: 'Giá 2',
      p3: 'Giá 3',
      v1: 'KL 1',
      v2: 'KL 2',
      v3: 'KL 3',
      bas: 'Độ lệch',
      quantity: 'KL',
      longVol: 'KL Mua',
      shortVol: 'KL Bán',
      longPrice: 'Giá Mua',
      shortPrice: 'Giá Bán',
    },
    closedPosition: {
      name: 'Vị thế đóng',
    },
    openOrders: {
      name: 'Sổ lệnh',
      originalNumber: 'Số hiệu gốc',
      cancelAll: 'Hủy tất cả',
      cancelOrder: {
        title: 'Xác nhận hủy lệnh',
        textConfirm: 'Do you agree to cancel order No.{{orderNo}}',
      },
      cancelAllOrder: {
        title: 'Xác nhận hủy tất cả lệnh',
        textConfirm: 'Quý khách có đồng ý hủy tất cả lệnh không?',
      },
      messageCancelOrderSuccess: 'Lệnh số {{orderNo}} đã được hủy thành công',
      messageEditedOrderSuccess: 'Lệnh số {{orderNo}} đã được sửa thành công',
    },
    totalAsset: {
      name: 'Tài sản',
      totalMoney: 'Tổng tiền',
      interestHeldForDebt: 'Nợ gốc và lãi trả chậm',
      deliveryValue: 'Nợ gốc',
      totalProfitAndLoss: 'Tổng lãi/ lỗ',
      cashAvailable: 'Tiền mặt có sẵn',
      originalDebt: 'Nợ gốc',
      latePaymentInterest: 'Lãi trễ hạn thanh toán',
      withdrawableSecurity: 'Chứng khoán có thể rút',
      odFeeEx: 'Phí GD trả sở tạm tính T0',
      odFeePT: 'Phí GD pinetree tạm tính T0',
      tax: 'Thuế tạm tính T0',
      poFee: 'Phí quản lý vị thế',
      imFee: 'Phí quản lý tài sản ký quỹ',
      feeTaxLoanAmt: 'Nợ thuế và Phí GD',
    },
    assetDetail: {
      name: 'Chi tiết tài sản',
      cash: 'Tiền mặt',
      valueOfMarginalSecurities: 'Giá trị chứng khoán ký quỹ',
      valueOfValidMarginaSecurities: 'Giá trị chứng khoán ký quỹ hợp lệ',
      totalValidDepositedValue: 'Tổng giá trị ký quỹ hợp lệ',
      withdrawableMoney: 'Tiền có thể rút',
      availableMarginalAssetValue: 'Giá trị ký quỹ còn lại',
      forceSell: 'Bán giải chấp',
    },
    useOfMargin: {
      name: 'Sử dụng ký quỹ',
      valueOfInitialMargin: 'Giá trị ký quỹ ban đầu',
      valueOfDeliveryMargin: 'Giá trị ký quỹ chuyển giao',
      useOfMarginalAssetRatio: 'Tỉ lệ sử dụng tài sản kí quỹ',
      totalValueOfRequiredMargin: 'Tổng giá trị ký quỹ yêu cầu',
      requiredAdditionalMoney: 'Yêu cầu ký quỹ bổ sung',
      warningThreshold: 'Ngưỡng cảnh báo',
    },
    orderHistory: {
      remainQty: 'KL Còn lại',
      name: 'Lịch sử lệnh',
      tradeDate: 'Ngày GD',
      orderTime: 'Thời gian đặt',
      originalNumber: 'SHL gốc',
      orderNumber: 'SHL',
      orderPrice: 'Giá đặt',
      matchedAvgPrice: 'Giá khớp TB',
      orderQuantity: 'KL đặt',
      matchedQuantity: 'KL khớp',
    },
    plReport: {
      name: 'Báo cáo Lãi/Lỗ',
      holdingPosition: 'Vị thế năm giữ',
      releasePLInDay: 'Lãi/Lỗ trong ngày',
      pl: 'Lãi/Lỗ',
      qty: 'Khối lượng',
      vmEndDay: 'Ký quỹ biến đổi cuối ngày (2)',
      closedQty: 'KL đã đóng',
      closedPrice: 'Giá đóng',
      totalQty: 'Tổng KL',
      totalPL: 'Tổng Lãi/Lỗ (1)',
      totalVM: 'Tổng (1+2)',
      vm: 'Ký quỹ biến đổi',
    },
    position: {
      title: 'Vị thế',
      open: 'Vị thế mở',
      openQuantity: 'KL mở',
      longPending: 'KL mua chờ khớp',
      shortPending: 'KL bán chờ khớp',
      marketPrice: 'Giá thị trường',
      profitPerLost: 'Lãi/Lỗ',
      closeAll: 'Đóng tất cả',
      closeAllPosition: {
        title: 'Xác nhận đóng vị thế',
        textConfirm: 'Đồng ý đóng tất cả vị thế',
      },
      closePosition: {
        success: 'Yêu cầu đóng vị thế đã gửi thành công',
      },
      reversePosition: {
        title: 'Xác nhận đảo vị thế',
        success: 'Yêu cầu đảo vị thế đã gửi thành công',
      },
    },
    placeOrder: {
      name: 'Đặt lệnh',
      closeALlPositionSuccess:
        'Lệnh đóng vị thế hợp đồng {{symbol}} đã đặt thành công',
    },
    topIndex: {
      label: 'Top ảnh hưởng',
    },
    confirmationSetting: {
      name: 'Cài đặt hiển thị xác nhận',
      alwayShow: 'Luôn luôn',
      hideUntilRelogin: 'cho đến khi login lại',
      hideUntilTurnOn: 'Cho đến khi bật lại ',
    },
    depWdrawTp: {
      1: 'Nộp ký quỹ',
      2: 'Rút ký quỹ',
      4: 'Rút ký quỹ cuối tháng',
      5: 'Nạp ký quỹ tự động',
      6: 'Rút ký quỹ tự động',
    },
    depWdrawNotes: {
      1: 'Thời gian nộp ký quỹ từ 8:00 tới 16:30',
      2: 'Thời gian rút ký quỹ từ 8:00 tới 16:00',
      3: 'Số tiền ký quỹ rút phải ≥ 5.500 VNĐ',
      4: 'Số dư ký quỹ còn lại tại VSD tối thiếu là 10.000 VNĐ',
      5: 'Số tiền nộp ký quỹ phải ≥ 20.000 VNĐ',
    },
  },
  survey: {
    derivative: 'Khảo sát phái sinh',
    drvxPromotion: 'Ưu đãi phái sinh',
  },
  partner: {
    allStatus: 'Tất cả trạng thái',
    rateUsedN: 'Mức phí áp dụng',
    rateUsedM: 'Mức lãi áp dụng',
    rateUsedD: 'Mức phí áp dụng',
    rateN: 'Giá trị giao dịch từ',
    rateM: 'Giá trị cho vay từ',
    rateD: 'Số lượng từ',
    approve: 'Phê duyệt',
    reject: 'Từ chối',
    createRequestSuccess: 'Tạo yêu cầu thành công',
    equitiesTradingFee: 'Phí giao dịch cổ phiếu',
    derivativesTradingFee: 'Phí giao dịch phái sinh',
    marginLoanInt: 'Khoản vay ký quỹ',
    validate: {
      vsdAccount: 'Số VSD khách hàng không được để trống',
      custPhone: 'Số điện thoại khách hàng không được để trống',
    },
    cancelRequest: 'Hủy yêu cầu',
    pending: 'Chờ xác nhận',
    canceled: 'Đã hủy',
    rejected: 'Đã từ chối',
    completed: 'Đã hoàn thành',
    registerDate: 'Ngày đăng ký',
    requestTypeOption: {
      allRequest: 'Tất cả yêu cầu',
      referral: 'Gán đối tác',
      unreferral: 'Hủy gán đối tác',
      serviceRegistration: 'Đăng ký dịch vụ',
      cancelServiceRegistration: 'Hủy đăng ký dịch vụ',
      changeReferral: 'Đổi đối tác',
      changeTheFeeGroup: 'Đổi nhóm phí',
    },
    requestList: 'Danh sách yêu cầu',
    referrerInformation: 'Thông tin Người giới thiệu',
    inviteReferral: 'Xác nhận gán đối tác',
    createdBy: 'Người tạo',
    referrerRequest: 'Yêu cầu của đối tác',
    changeFeeDescription: 'Biểu phí mặc định cho tất cả các dịch vụ',
    textConfirmAddBroker:
      'Tôi đã đọc và đồng ý với Điều khoản và Điều kiện Hợp tác tại Pinetree',
    referralName: 'Tên Người giới thiệu',
    removeBroker: 'Hủy gán đối tác',
    removeCustomer: 'Hủy gán khách hàng',
    changeFee: 'Thay đổi chính sách phí',
    changeFeeDetail: 'Chi tiết thay dổi chính sách phí',
    detailRequest: 'Chi tiết yêu cầu',
    approveMultipleRequest: 'Phê duyệt nhiều yêu cầu',
    cancelMultipleRequest: 'Hủy nhiều yêu cầu',
    requestType: 'Loại yêu cầu',
    createdDate: 'Ngày tạo',
    customerRequest: 'Yêu cầu của khách hàng',
    myRequest: 'Yêu cầu của tôi',
    tradingFee: 'Phí giao dịch',
    VSDAccount: 'Số tài khoản VSDC',
    phoneNumber: 'SĐT khách hàng',
    vsdNumber: 'Số VSD khách hàng',
    addNewCustomer: 'Thêm khách hàng mới',
    addNewBroker: 'Gán đối tác mới',
    customer: 'Khách hàng',
    customerList: 'Danh sách khách hàng',
    removeMultipleCustomer: 'Xóa nhiều khách hàng',
    totalRemuneration: 'Tổng thù lao',
    currentPeriodRemuneration: 'Thù lao tạm tính kỳ hiện tại',
    remunerationRate: 'Tỷ lệ thù lao',
    currentFeePolicy: 'Chính sách phí hiện tại',
    commissionType: 'Loại thù lao',
    referralCode: 'Mã giới thiệu',
    forReferrer: 'Đối tác',
    forReferee: 'Khách hàng',
    name: 'Đối tác phát triển khách hàng',
    infor: 'Thông tin đối tác',
    id: 'Mã đối tác',
    status: 'Trạng thái',
    link: 'Đường dẫn giới thiệu',
    qr: 'Mã giới thiệu',
    register: 'Đăng ký trở thành đối tác',
    receipt: 'Được nhận',
    commission: 'Thù lao',
    successDesc: 'Bạn đã đăng ký trở thành đối tác thành công.',
    goto: 'Chuyển tới trang Thông tin đối tác',
    tnc: 'Tôi đã đọc và đồng ý với các điều khoản và điều kiện hợp tác tại Pinetree.',
    serviceType: 'Loại Dịch Vụ',
    change_fee: 'Thay đổi phí',
    subAccount: 'Tiểu khoản',
    cancelService: 'Hủy Dịch Vụ',
    feePolicyApplied: 'Chính sách phí áp dụng',
    serviceStatus: {
      A: 'Đang hoạt động',
      P: 'Chờ xử lý',
      E: 'Hết hạn',
      N: 'Chưa đăng ký',
    },
    serviceLabel: {
      type: 'Loại dịch vụ',
      feePolicy: 'Chính sách phí',
      activeDate: 'Ngày kích hoạt',
      status: 'Trạng thái',
    },
  },
  notifications: {
    remindChangePassword: '',
    orderCancelled: '',
    orderMatched: '',
    orderReject: '',
    balanceChange: '',
    activeVsdAccount: '',
    important: '',
    product: '',
    communityNews: '',
    morningBrief: '',
    liveStream: '',
    balancePsVmProfit: '',
    videoCallCancel: '',
    videoCall: '',
    profileChange: '',
    accountActive: '',
    accountCreditUpdate: '',
    permissionDhcd: '',
    permissionNgdkhq: '',
    permissionQnct: '',
    permissionTtctCash: '',
    permissionTtctStock: '',
    permissionDkqm: '',
    permissionQm1: '',
    permissionQm2: '',
    pfPortfolioExpected: '',
    pfPortfolio: '',
    pinebPayInterest: '',
    marginAlert: '',
    marginForceSell: '',
    loanExpired: '',
    loanExtendResultSuccess: '',
    loanExtendResultFailed: '',
    psViolateLimitPosition: '',
    psViolateLimitAmount: '',
    psViolateUsedPercent: '',
    psViolateClosePosition: '',
    psDepositPayLoan: '',
    saPriceIncreaseSuddenly: '',
    saPriceOverTop1Month: '',
    saPriceOverTop3Month: '',
    saPriceOverTop6Month: '',
    saPriceOverTop52Week: '',
    saPriceDecreaseSuddenly: '',
    saPriceUnderTop1Month: '',
    saPriceUnderTop3Month: '',
    saPriceUnderTop6Month: '',
    saPriceUnderTop52Week: '',
    saAmountIncrease: '',
    saAmountDecrease: '',
    saMaOver20: '',
    saMaOver50: '',
    saMaOver100: '',
    saMaOver200: '',
    saMaUnder20: '',
    saMaUnder50: '',
    saMaUnder100: '',
    saMaUnder200: '',
    cashIn: '',
    cashOut: '',
    marketBrief: '',
    pfPortfolioNoNewSymbol: '',
  },
  cw: {
    vol: 'Khối lượng (nghìn)',
    openPrice: 'Giá mở cửa',
    highestPrice: 'Giá cao nhất',
    lowestPrice: 'Giá thấp nhất',
    totalVolume: 'Tổng khối lượng',
    totalValue: 'Tổng giá trị',
    ceilingPrice: 'Trần',
    floorPrice: 'Sàn',
    refPrice: 'Tham chiếu',
    fSell: 'NN bán',
    fBuy: 'NN mua',
    issuer: 'TCPH',
    type: 'Loại CQ',
    style: 'Kiểu CQ',
    strikePrice: 'Giá thực hiện',
    ratio: 'Tỉ lệ chuyển đổi',
    underlying: 'CK Cơ sở',
    underlyingPrice: 'Giá CK Cơ sở',
    status: 'Trạng thái',
    intrinsic: 'Độ lệch',
    breakEven: 'Điểm hòa vốn (Chênh lệch)',
    maturityDate: 'Ngày ĐH',
    lastTradingDate: 'Ngày GDCC',
    timeToMaturity: 'Thời gian ĐH',
    listedShare: 'KL phát hành',
    cwStatus: {
      trading: 'Đang giao dịch',
      expiry: 'Đã hết hạn',
    },
  },
  openApi: {
    generateNewKey: 'Tạo token mới',
    deviceId: 'ID thiết bị',
    ipAddress: 'Địa chỉ IP',
    downloadSource: 'Tải xuống bản cài và HDSD',
    createConnectionKey: 'Tạo khóa kết nối',
    openApiNote: 'Khóa kết nối được tạo tối đa 3 lần mỗi ngày',
    create: 'Tạo',
    keyInfo: 'Thông tin token',
    clientId: 'Client ID',
    secretToken: 'Secret Token',
    privateKey: 'Private Key',
    downloadKey: 'Tải xuống',
  },
};
