import React, { Component, Suspense, useEffect, useRef } from 'react';
import { isEqual } from 'lodash';
import { Container } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import * as router from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Loading from '../../components/loading';
import { Logout, logoutRequest } from '../../components/modal/login/actions';
import Toast from '../../components/toast';
import { handleApiErrors } from '../../lib/api-errors';
import { checkDefaultAuthorization } from '../../lib/check-auth';
import {
  makeGetCancelOrder,
  makeGetConfig,
  makeGetDefaultAccount,
  makeGetEditOrder,
  makeGetEventOrder,
  makeGetListAccount,
  makeGetNewOrder,
  makeGetToast,
  makeGetToken,
} from '../../lib/selector';
import {
  getCategory,
  getCookie,
  getSetting,
  removeCookie,
  saveState,
} from '../../lib/storages';
import {
  getMessageError,
  subAccountProcess,
  subAccountTypeDetect,
} from '../../util';
import Assets from '../asset';
import Bond from '../bond';
import BangGia from '../banggia';
import Personal from '../personal';
import Transaction from '../transaction';
import Pinefolio from '../pinefolio';
import News from '../news/index';
import Derivative from '../derivative/index';
import MarginCompetiton from '../marginCompetition';
import {
  allOrdRequest,
  allStockRequest,
  cwListRequest,
  listMessageRequest,
} from '../banggia/actions';
import Cash from '../cash';
import {
  appsettingRequest,
  categoryRequest,
  configRequest,
  setSetting,
  setTheme,
  unsetClient,
} from '../client/actions';
import Account from '../account';
import { cashBalanceRequest, getPrivDataRequest } from '../privData/actions';
import { WebSocketContext } from '../socket/webSocket';
import {
  summaryEventRequest,
  summaryNotificationRequest,
} from '../summary/actions';
import { bankInforRequest } from '../cash/actions';
import Trading from '../trading/trading';
// import '../../assets/scss/layout/layout.scss';
import { industryRequest } from '../../containers/client/actions';
import { loadState } from '../../lib/storages';
import { setDefaultAccount, updateListAccount } from '../socket/actions';
import { Mixpanel } from '../../lib/mixpanel';
import NotificationPopup from '../../components/notificationPopup';
import TradingPersional from '../tradingPersonal';
import { EVENT_TYPE, LONG_SHORT_TP, constants } from '../../util/constant';
import {
  getBrokerInfor,
  getBrokerInforSuccess,
  getCommonRequest,
} from '../customer/actions';

const DefaultFooter = React.lazy(() => import('./DefaultFooter'));
const DefaultHeader = React.lazy(() => import('./DefaultHeader'));

const usePrevLocation = (location) => {
  const prevLocRef = useRef(location);

  useEffect(() => {
    prevLocRef.current = location;
  }, [location]);

  return prevLocRef.current;
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <router.Route
      {...rest}
      // key={`r-${Math.random()}`}
      render={(props) => {
        checkDefaultAuthorization(rest.dispatch.store);
        return <Component {...props} store={rest.dispatch.store} />;
      }}
    />
  );
};

const appUrl = `${process.env.REACT_APP_API_URL}`;
class DefaultLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toastList: [],
      countInterval: 0,
    };
    this.props.dispatch(allStockRequest());
  }

  componentDidMount() {
    const { token } = this.props;
    const _date = new Date();

    const storedSetting = loadState('setting');
    console.log('storedSetting ===> ', storedSetting);
    const lang = storedSetting?.lang == 'en' ? 2 : 1;
    if (!token) {
      this.props.dispatch(industryRequest(lang));
      this.props.dispatch(bankInforRequest(null, 1));
      this.props.dispatch(cwListRequest());
      this.props.dispatch(
        appsettingRequest({
          lang: lang == 'en' ? 2 : 1,
          ver: '',
          module: '',
          key: '',
        })
      );

      getSetting(this.props);
      if (storedSetting && storedSetting.theme) {
        this.props.dispatch(setTheme(storedSetting.theme));
      }
    }

    this._handleRequestListMessage();
    this.intervalCheckStore = setInterval(() => this.handleCheckStore(), 30000);
    Mixpanel.openApp(getCookie('token'));

    // track event close webapp
    window.addEventListener('beforeunload', () => {
      Mixpanel.track('Exit Screen');
      return Mixpanel.closeApp();
    });
  }

  componentWillUnmount() {
    if (this.intervalCheckStore) clearInterval(this.intervalCheckStore);
  }

  //#region check store update
  componentDidUpdate(preProps) {
    const {
      token,
      toast,
      configSuccessful,
      config,
      socketReady,
      serverStatus,
      regPrivate,
      newOrder,
      cancelOrder,
      editOrder,
      defaultAccount,
      listAccount,
      event,
      t,
      location,
      currentSid,
    } = this.props;

    if (toast && !isEqual(toast, preProps.toast)) {
      if (toast.msg) {
        const toastMsg = {
          title: toast.title,
          description: toast.msg,
        };
        this.showToast(toastMsg);
      }
    }

    if (token && !isEqual(token, preProps.token)) {
      this.props.dispatch(configRequest(token));
      this.interval = setInterval(() => {
        // sau 1s khong co default account thi load lai list account
        if (!defaultAccount) {
          this._handleGetListAccount();
          this.setState((preState) => ({
            countInterval: preState.countInterval + 1,
          }));
        }
        if (this.state.countInterval > 10) {
          if (this.interval) clearInterval(this.interval);
          // logout
          this._handleLogout();
          // get setting localstorage
          getCategory(this.props);
          getSetting(this.props);
          // show toast
          this.showToast(
            `${t('txt-no-data-found')}. ${t('txt-please-try-again')}`
          );
        }
      }, 1000);

      setTimeout(() => {
        this._handleGetListAccount();
      }, 200);
    }

    // excuted when getConfig api success
    if (configSuccessful && config && !isEqual(config, preProps.config)) {
      if (config.rc < 0) {
        this._handleLogout();
        // get setting localstorage
        getCategory(this.props);
        getSetting(this.props);
      } else {
        // TODO: get setting from server
        this._handleRequestCategory();
        getSetting(this.props);

        // if (token && socketReady && regPrivate !== 'ok') {
        //   console.log('... handle config');
        //   this._handlePrivate();
        // }
      }
    }

    // login
    // TODO: if register socket just for login then excuted after submit loginadv success
    // if (regPrivate !== 'ok' && token && !isEqual(token, preProps.token)) {
    //   console.log('... handle token');
    //   this._handlePrivate();
    // }

    if (
      newOrder &&
      newOrder.newOrderSuccess &&
      !isEqual(newOrder.newOrderSuccess, preProps.newOrder.newOrderSuccess)
    ) {
      const toastMsg = {
        title: t('txt-title-neworder-success'),
        description: `${t('txt-orderNo')} ${newOrder.newOrder.ordrNo}`,
      };
      this.showToast(toastMsg);
      this._handleRequestOrderList();

      // // TODO: thêm settimeout gọi lại danh sách lệnh sau 3 giây khi đặt lệnh thành công
      // setTimeout(() => {
      //   this._handleRequestOrderList();
      // }, 3000);
    }
    if (
      newOrder &&
      !newOrder.newOrderSuccess &&
      newOrder.newOrderErrors.length > 0 &&
      !isEqual(newOrder.newOrderErrors, preProps.newOrder.newOrderErrors)
    ) {
      const toastMsg = {
        title: t('txt-title-neworder-unsuccess'),
        description: newOrder.newOrderErrors[0].body,
      };
      this.showToast(toastMsg);
      this._handleRequestOrderList();
    }

    if (
      editOrder &&
      editOrder.editOrderSuccess &&
      editOrder.editOrderSuccess !== preProps.editOrder.editOrderSuccess
    ) {
      const toastMsg = {
        title: t('txt-notice'),
        description: `${t('txt-title-editorder-success')},${t(
          'txt-orderNo'
        )}: ${editOrder.editOrder.ordrNo}`,
      };
      this.showToast(toastMsg);
      this._handleRequestOrderList();

      // // TODO: thêm settimeout gọi lại danh sách lệnh sau 3 giây khi đặt lệnh thành công
      // setTimeout(() => {
      //   this._handleRequestOrderList();
      // }, 3000);
    }
    if (
      editOrder &&
      !editOrder.editOrderSuccess &&
      editOrder.editOrderErrors.length > 0 &&
      !isEqual(editOrder.editOrderErrors, preProps.editOrder.editOrderErrors)
    ) {
      const toastMsg = {
        title: t('txt-notice'),
        description: editOrder.editOrderErrors[0].body,
      };
      this.showToast(toastMsg);
      this._handleRequestOrderList();
    }

    if (
      cancelOrder &&
      cancelOrder.cancelOrderSuccess &&
      cancelOrder.cancelOrderSuccess !== preProps.cancelOrder.cancelOrderSuccess
    ) {
      const toastMsg = {
        title: t('txt-title-delorder-success'),
        description: `${t('txt-orderNo')} ${cancelOrder.cancelOrder.ordrNo}`,
      };
      this.showToast(toastMsg);
      this._handleRequestOrderList();

      // // TODO: thêm settimeout gọi lại danh sách lệnh sau 3 giây khi đặt lệnh thành công
      // setTimeout(() => {
      //   this._handleRequestOrderList();
      // }, 3000);
    }
    if (
      cancelOrder &&
      !cancelOrder.cancelOrderSuccess &&
      cancelOrder.cancelOrderErrors.length > 0 &&
      !isEqual(
        cancelOrder.cancelOrderErrors,
        preProps.cancelOrder.cancelOrderErrors
      )
    ) {
      const toastMsg = {
        title: t('txt-notice'),
        description: cancelOrder.cancelOrderErrors[0].body,
      };
      this.showToast(toastMsg);
      this._handleRequestOrderList();
    }

    if (!isEqual(defaultAccount, preProps.defaultAccount)) {
      if (defaultAccount) {
        this._handleRequestOrderList();
        this._handleRequestEvent();
        this._handleRequestNotification();
      } else {
        this._handleLogout();
        getCategory(this.props);
        getSetting(this.props);
      }
      this._handleGetBrokerInfo(defaultAccount);
    }

    if (!defaultAccount && !isEqual(defaultAccount, preProps.defaultAccount)) {
      this._handleLogout();
      getCategory(this.props);
      getSetting(this.props);
    }

    if (event && event !== preProps.event) {
      this._eventProcessHandle(event);
    }

    if (
      socketReady &&
      serverStatus !== preProps.serverStatus &&
      regPrivate === 'ok'
    ) {
      // auto regis socket when
      this._handleRegisData();
    }

    if (location && !isEqual(location, preProps.location)) {
      Mixpanel.track('Exit Screen');
      Mixpanel.viewScreen(
        location?.pathname,
        location?.pathname,
        preProps.location?.pathname
      );
      Mixpanel.time_event();
      Mixpanel.people.set({
        'Last Screen View': location?.pathname,
      });

      if (token && token?.custInfo) {
        // reset default account after change location
        subAccountProcess(
          token?.custInfo,
          defaultAccount,
          (accounts, account) => {
            this.props.dispatch(updateListAccount(accounts));
            this.props.dispatch(setDefaultAccount(account));
          }
        );
      }
    }
  }
  //#endregion

  _handleRegisData = () => {
    let ws = this.context;
    const { regSym, isOddLot } = this.props;
    if (!regSym) return;

    const payload = {
      action: 'join',
      data: regSym,
      board: isOddLot ? constants.G.ODD_LOT : constants.G.DEFAULT,
    };

    return ws.sendMessage(payload);
  };

  signOut = (e) => {
    if (e) e.preventDefault();
    this._handleLogout();
  };

  _handleLogout = () => {
    const { token } = this.props;
    if (token) {
      console.log('logoutRequest');
      this.props.dispatch(logoutRequest(token));
    }

    const _settings = loadState('setting');
    if (_settings && _settings.hasOwnProperty('drvConfirmOrder')) {
      delete _settings.drvConfirmOrder;
      this.props.dispatch(setSetting(_settings));
      saveState('setting', _settings);
    }

    this.handleRemindeDC13Later();
    this.props.dispatch(unsetClient());
    this.props.dispatch(getBrokerInforSuccess(null));
    removeCookie('token');
    removeCookie('authen');

    this.props.dispatch(Logout());
    this.setState({ countInterval: 0 });
  };

  handleRemindeDC13Later = () => {
    const { setting } = this.props;
    let _setting = setting;
    _setting.remindLater = '0';
    saveState('setting', _setting);
  };

  handleCheckStore = () => {
    const { token, listAccount, defaultAccount } = this.props;
    if (token && (!defaultAccount || !listAccount || !listAccount.length)) {
      const custInfo = token['custInfo'];
      if (custInfo) {
        this._handleSetDefAccount(custInfo, defaultAccount);
      }
    }
  };

  showToast = (toast) => {
    const id = Math.floor(Math.random() * 101 + 1);
    const toastProperties = {
      id,
      title: toast.title,
      description: toast.description,
      htmlContent: toast.htmlContent || false,
    };
    // console.log(toastProperties)
    // return
    let _toastList = [...this.state.toastList, toastProperties];
    if (_toastList.length > 5) _toastList.shift();
    this.setState({ toastList: _toastList });
  };

  _handleRequestEvent = () => {
    const { defaultAccount, token } = this.props;
    if (!defaultAccount || !token) return;

    const uuid = uuidv4();

    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'GetAccountEvent',
      rqId: uuid,
      channel: 'WTS',
      data: {
        custNo: defaultAccount.acntNo,
      },
    };

    this.props.dispatch(summaryEventRequest(resData));
  };

  _handleRequestNotification = () => {
    const { token, lng } = this.props;
    if (!token) return;

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'GetNotifications',
      channel: 'WTS',
      rqId: uuid,
      data: {
        langTp: lng,
        custNo: token.cif,
      },
    };

    this.props.dispatch(summaryNotificationRequest(resData));
  };

  reloadHandle = () => {
    const { token, defaultAccount } = this.props;

    if (defaultAccount) {
      const uuid = uuidv4();

      const resData = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getTotalAssetAll',
        rqId: uuid,
        channel: 'WTS',
        data: {
          acntNo: defaultAccount.acntNo,
          subAcntNo: defaultAccount.subAcntNo,
        },
      };
      this.props.dispatch(cashBalanceRequest(resData));
    }
  };

  _handleRequestCategory = () => {
    const { token } = this.props;
    const uuid = uuidv4();

    if (!token) return;

    const resData = {
      group: 'BACK',
      user: token.user,
      session: token.session,
      cmd: 'GET_WAT',
      rqId: uuid,
      channel: 'WTS',
      data: {},
    };

    this.props.dispatch(categoryRequest(resData));
  };

  _handleRequestListMessage = () => {
    this.props.dispatch(listMessageRequest());
  };

  _handleRequestOrderList = () => {
    const { defaultAccount, token } = this.props;
    if (!defaultAccount || !token) return;

    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      cmd: 'getAllOrderList',
      user: token.user,
      session: token.session,
      rqId: uuid,
      channel: 'WTS',
      data: {
        custNo: token.cif,
      },
    };

    this.props.dispatch(allOrdRequest(params));
  };

  _handleGetListAccount = () => {
    const { defaultAccount, token } = this.props;
    const custInfo = token ? token['custInfo'] : null;
    if (custInfo) {
      this._handleSetDefAccount(custInfo, defaultAccount);
    } else {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        cmd: 'ListAccount',
        user: token.user,
        session: token.session,
        rqId: uuid,
        channel: 'WTS',
        data: { cif: token.cif },
      };

      const callback = (data) => {
        console.log('df layout data ===> ', data);
        // processSubAccount
        // const resData = res.data;
        // const { custInfo } = resData;
        // this._handleSetDefAccount(custInfo, defaultAccount);
      };

      this.props.dispatch(getPrivDataRequest(params, callback, true));
    }
  };

  _handleSetDefAccount = (custInfo, currentAccount) => {
    subAccountProcess(custInfo, currentAccount, (accounts, account) => {
      this.props.dispatch(updateListAccount(accounts));
      this.props.dispatch(setDefaultAccount(account));

      if (this.interval) clearInterval(this.interval);
      this.setState({ countInterval: 0 });
    });
  };

  _handleGetBrokerInfo = (account) => {
    const { token, lang } = this.props;
    if (!token || !account) return;

    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getBrkInfo',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
        acntNo: account.acntNo,
        langTp: lang,
      },
    };

    const callback = (data) => {
      this.props.dispatch(getBrokerInforSuccess(data));
    };

    this.props.dispatch(getCommonRequest(params, callback, true));
  };

  _eventProcessHandle = (event) => {
    const { t } = this.props;
    // console.log(event)
    if (event.eventType === 'LOGIN') {
      const toastMsg = {
        title: t('txt-notice'),
        description: t('txt-valid-loss-session'),
      };
      this.showToast(toastMsg);
      this._handleLogout();
    } else if (
      [EVENT_TYPE.CASH_IN, EVENT_TYPE.CASH_OUT].includes(event.eventType)
    ) {
      const toastMsg = {
        title: t('txt-notice'),
        description: event.msgEvent,
      };
      this.showToast(toastMsg);
      this.reloadHandle();
    } else if (event.eventType === 'THMS') {
      const toastMsg = {
        title: t('txt-notice'),
        description: event.msgEvent,
      };
      this.showToast(toastMsg);
    } else if (
      [
        EVENT_TYPE.NEW_ORDER,
        EVENT_TYPE.CANCEL_ORDER,
        EVENT_TYPE.MATCHING_ORDER,
        EVENT_TYPE.REJECT_NEW_ORDER,
        EVENT_TYPE.ADJUST_ORDER,
      ].includes(event.eventType)
    ) {
      const { ordrNo, ordrMatQty, ordrSymbol, subAcntNo, ordrSide, msgEvent } =
        event;
      let msg = msgEvent;
      const _orderSide =
        ordrSide == '1'
          ? t('txt-buy').toLowerCase()
          : t('txt-sell').toLowerCase();
      const _symbolType = subAcntNo?.startsWith(constants.subAccount.Derivative)
        ? t('symbolType.long.contract')
        : t('symbolType.long.symbol');

      if (event.eventType == EVENT_TYPE.NEW_ORDER) {
        msg = t('txt-new-order-sent-success', {
          orderNo: ordrNo,
          orderSide: _orderSide,
          symbol: ordrSymbol,
        });
      }

      if (event.eventType == EVENT_TYPE.CANCEL_ORDER) {
        msg = t('txt-cancel-order-success', {
          orderNo: ordrNo,
        });
      }

      if (event.eventType == EVENT_TYPE.MATCHING_ORDER) {
        msg = t('txt-matched-order-success', {
          orderNo: ordrNo,
          matchedQuantity: ordrMatQty,
          orderSide: _orderSide,
          symbol: ordrSymbol,
          symbolType: _symbolType,
        });
      }

      // Core have not sent the socket event yet
      // if (event.eventType == EVENT_TYPE.REJECT_NEW_ORDER) {
      //   msg = t('txt-new-order-rejected', {
      //     orderNo: ordrNo,
      //   });
      // }

      if (event.eventType == EVENT_TYPE.ADJUST_ORDER) {
        msg = t('txt-adjust-order-success', {
          orderNo: ordrNo,
        });
      }

      const toastMsg = {
        title: t('txt-notice'),
        description: msg,
        htmlContent: true,
      };
      this.showToast(toastMsg);
      this._handleRequestOrderList();
    } else {
      const toastMsg = {
        title: t('txt-notice'),
        description: event.msgEvent,
      };
      this.showToast(toastMsg);
    }
  };

  render() {
    const { currentTheme } = this.props;
    return (
      <div className={`${currentTheme?.name || 'dark'} app`}>
        <Suspense fallback={<Loading />}>
          <DefaultHeader
            onLogout={(e) => this.signOut(e)}
            dispatch={this.props.dispatch}
          />
        </Suspense>
        <div className="app-body">
          <main className="main">
            <Container fluid>
              <Suspense fallback={<Loading />}>
                <router.Switch>
                  <PrivateRoute
                    path="/home/derivative"
                    component={Derivative}
                    dispatch={this.props}
                    store={this.props}
                    key="proute-0"
                  />
                  <PrivateRoute
                    path="/home/bang-gia/:categoryId"
                    component={BangGia}
                    dispatch={this.props}
                    key="proute-1"
                  />
                  <PrivateRoute
                    path="/home/assets/:typeId"
                    component={Assets}
                    dispatch={this.props}
                    store={this.props}
                    key="proute-2"
                  />
                  <PrivateRoute
                    path="/home/personal/:typeId"
                    component={Personal}
                    dispatch={this.props}
                    store={this.props}
                  />
                  <PrivateRoute
                    path="/home/transaction/:typeId"
                    component={Transaction}
                    dispatch={this.props}
                    store={this.props}
                  />
                  <PrivateRoute
                    path="/home/trading/:typeId"
                    component={Trading}
                    dispatch={this.props}
                    store={this.props}
                    key="proute-3"
                  />
                  <PrivateRoute
                    path="/home/cash/:typeId"
                    component={Cash}
                    dispatch={this.props}
                    store={this.props}
                    key="proute-4"
                  />

                  <PrivateRoute
                    path="/home/account/:typeId"
                    component={Account}
                    dispatch={this.props}
                    store={this.props}
                  />

                  <PrivateRoute
                    path="/home/news"
                    component={News}
                    dispatch={this.props}
                    store={this.props}
                  />

                  <PrivateRoute
                    path="/home/bond/:id"
                    component={Bond}
                    dispatch={this.props}
                    store={this.props}
                  />

                  <PrivateRoute
                    path="/home/bond"
                    component={Bond}
                    dispatch={this.props}
                    store={this.props}
                  />

                  <PrivateRoute
                    path="/home/pinefolio/:id"
                    component={Pinefolio}
                    dispatch={this.props}
                    store={this.props}
                  />

                  <PrivateRoute
                    path="/home/pinefolio"
                    component={Pinefolio}
                    dispatch={this.props}
                    store={this.props}
                  />

                  <PrivateRoute
                    path="/home/competition"
                    component={MarginCompetiton}
                    dispatch={this.props}
                    store={this.props}
                  />

                  <PrivateRoute
                    path="/home/trading-personal"
                    component={TradingPersional}
                    dispatch={this.props}
                    store={this.props}
                  />

                  <router.Redirect
                    from="/home/assets"
                    to="/home/assets/tai-san"
                    key="r-6"
                  />
                  <router.Redirect
                    from="/home/personal"
                    to="/home/personal/assets-portfolio"
                  />

                  <router.Redirect
                    from="/home/transaction"
                    to="/home/transaction/deposit-withdraw-money"
                  />

                  {/* <router.Redirect from="/home/bond" to="/home/bond/-1" /> */}
                  <router.Redirect
                    from="/home/trading"
                    to="/home/trading/lenh-thuong"
                    key="r-7"
                  />
                  <router.Redirect
                    from="/home/cash"
                    to="/home/cash/nap-rut-tien"
                    key="r-8"
                  />
                  <router.Redirect
                    from="/home/account"
                    to="/home/account/profile"
                    key="r-9"
                  />
                  <router.Redirect
                    from="/home/bang-gia"
                    to="/home/bang-gia/vn30"
                    key="r-10"
                  />
                  <router.Redirect
                    key="r-11"
                    from="/bang-gia"
                    to="/home/bang-gia/vn30"
                  />
                </router.Switch>
              </Suspense>
            </Container>
          </main>
        </div>
        <Suspense fallback={<Loading />}>
          <DefaultFooter />
        </Suspense>
        <Toast
          toastList={this.state.toastList}
          position="top-right"
          autoDelete={true}
          dismissTime={4000}
        />
        <NotificationPopup />
      </div>
    );
  }
}

DefaultLayout.contextType = WebSocketContext;

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getToast = makeGetToast();
  const getConfig = makeGetConfig();
  const getNewOrder = makeGetNewOrder();
  const getCancelOrder = makeGetCancelOrder();
  const getEditOrder = makeGetEditOrder();
  const getListAccount = makeGetListAccount();
  const getDefaultAccount = makeGetDefaultAccount();
  const getEventOrder = makeGetEventOrder();

  const mapStateToProps = (state) => {
    return {
      token: getToken(state),
      toast: getToast(state),
      config: getConfig(state),
      defaultAccount: getDefaultAccount(state),
      listAccount: getListAccount(state),

      newOrder: getNewOrder(state),
      cancelOrder: getCancelOrder(state),
      editOrder: getEditOrder(state),

      event: getEventOrder(state),

      configSuccessful: state.client.configSuccessful,
      socketReady: state.socket.socketReady,
      serverStatus: state.socket.serverStatus,
      regPrivate: state.socket.regPrivate,
      regPrivateRequesting: state.socket.regPrivateRequesting,
      regSym: state.socket.regSym,
      isOddLot: state.priceBoard.isOddLot,
      currentTheme: state.client.currentTheme,
      themes: state.client.themes,
      setting: state.client.setting,
      currentLocation: window.location?.hash,
      allOrder: state.priceBoard.allOrder,
      currentSid: state.socket,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  translate('translations')(router.withRouter(DefaultLayout))
);
