import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import {
  maxLength,
  required,
  number,
  validateEmail,
  uploadFileRequired,
} from '../../../util/formikValidation';
import { Formik, Form, Field } from 'formik';
import { withNamespaces } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4, validate } from 'uuid';
import {
  updateProfileRequest,
  resetUpdateProfile,
} from '../../../containers/customer/actions';
import { makeGetToken, makeGetDefaultAccount } from '../../../lib/selector';
import Select from 'react-select';
import TextMask from 'react-text-mask';
import { _formatDate2, _convertformatDate2, resizeFile } from '../../../util';
import AuthenStep from '../../common/auth';

const pIDType = [
  { value: 1, label: 'CMND' },
  { value: 2, label: 'CCCD' },
];

const InputDate = (input) => {
  return (
    <TextMask
      {...input}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      className="form-control text-left form-input fz_14"
      type="text"
      placeholder="25/09/1970"
    />
  );
};

const PersonalIDChangeForm = withNamespaces('translations')(
  ({
    t,
    toastHandle,
    nextStepHandle,
    personalIDType,
    personalNum,
    fullname,
    residenceAddress,
    issuedPlace,
    issuedDate,
    expireDate,
    frontImage,
    backImage,
    frontImageName,
    backImageName,
  }) => {
    const formikRef = useRef();
    const [_frontImageName, setFrontImageName] = useState(frontImageName);
    const [_backImageName, setBackImageName] = useState(backImageName);
    const lstNoiCap = useSelector((state) => state.priceBoard?.noicap);
    const elRef = useRef(null);
    const MAX_UPLOAD_SIZE = 4194304; // 4MB
    const MIN_UPLOAD_RESIZE = 2621440; // 2.5MB
    const UPLOAD_FILE_NAME_MAX_LENGTH = 32; // 32 characters

    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };

    const uploadImageHandle = async (event, isFront = true) => {
      let file = event.target.files[0];
      let _base64 = '';

      // validate format
      const pattern = /image-*/;
      if (file && !file.type.match(pattern)) {
        toastHandle(t('account.personalModal.uploadInvalid'));
        return;
      }

      if (file && file.size > MAX_UPLOAD_SIZE) {
        toastHandle(t('account.personalModal.uploadMaxSize'));
        return;
      }

      // try {
      //   if (file && file.size > MIN_UPLOAD_RESIZE)
      //     file = await resizeFile(file);
      // } catch (error) {
      //   console.error('resize file error ===> ', error);
      // }

      // convert to base64 string
      if (file) {
        _base64 = await getBase64(file);
      } else {
        return;
      }

      let _name = file.name;
      if (_name.length > UPLOAD_FILE_NAME_MAX_LENGTH) {
        _name =
          '...' + _name.substring(_name.length - UPLOAD_FILE_NAME_MAX_LENGTH);
      }
      if (isFront) {
        setFrontImageName(_name);
        formikRef.current.setFieldValue('frontImage', _base64);
      } else {
        setBackImageName(_name);
        formikRef.current.setFieldValue('backImage', _base64);
      }
    };

    const validate = (values) => {
      const errors = {};
      if (!values.personalIDType) {
        errors.personalIDType = required();
      }

      if (!values.frontImage) {
        errors.frontImage = uploadFileRequired();
      }

      if (!values.backImage) {
        errors.backImage = uploadFileRequired();
      }

      if (!values.personalNum) {
        errors.personalNum = required();
      } else {
        const isNum = number(values.personalNum);
        if (isNum) errors.personalNum = number(values.personalNum);
      }

      if (!values.issuedPlace) {
        errors.issuedPlace = required();
      } else {
        const included = lstNoiCap.find((x) => x.orderno == values.issuedPlace);
        if (!included) {
          errors.issuedPlace = t('account.personalModal.issuedPlaceWrong');
        }
      }

      if (values.personalIDType == 2 && !values.expireDate) {
        errors.expireDate = required();
      }

      return errors;
    };

    useEffect(() => {
      if (elRef) elRef.current.focus();
    }, []);

    return (
      <>
        <Formik
          innerRef={formikRef}
          initialValues={{
            personalIDType: personalIDType,
            personalNum: personalNum,
            fullname: fullname,
            residenceAddress: residenceAddress,
            issuedPlace: issuedPlace,
            issuedDate: issuedDate,
            expireDate: expireDate,
            frontImage: frontImage,
            backImage: backImage,
          }}
          validate={validate}
          onSubmit={(values) => {
            const _values = { ...values, _frontImageName, _backImageName };
            nextStepHandle(_values);
          }}
        >
          {({ errors, touched, values }) => (
            <Form
              className="change-form personal-change-form"
              autoComplete="off"
            >
              <div className="order-text">
                <span className="icon iAccount"></span>
                <Select
                  className="profile-select"
                  classNamePrefix="profile-select"
                  defaultValue={pIDType.find((x) => x.value == personalIDType)}
                  name="personalIDType"
                  options={pIDType}
                  isSearchable={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(value) => {
                    console.log('value', value);
                    formikRef.current.setFieldValue(
                      'personalIDType',
                      value.value
                    );
                  }}
                />
              </div>
              {errors.personalIDType && (
                <div className="d my-2 error">{errors.personalIDType}</div>
              )}
              <div className="order-text">
                <span className="icon iAccount"></span>
                <Field
                  innerRef={elRef}
                  type="text"
                  name="personalNum"
                  placeholder={t('account.personal.number')}
                  autoComplete="off"
                  maxLength={15}
                />
              </div>
              {touched.personalNum && errors.personalNum && (
                <div className="d my-2 error">{errors.personalNum}</div>
              )}

              {/* <FloatingLabelInput
                type="text"
                name="personalNum"
                placeholder={t('account.personal.number')}
                autoComplete="off"
                maxLength={15}
                fieldName={t('account.personal.number')}
              /> */}

              <div className="order-text">
                <span className="icon iUser"></span>
                <Field
                  type="text"
                  name="fullname"
                  placeholder={t('account.personal.fullname')}
                  autoComplete="off"
                  validate={required}
                  maxLength={50}
                />
              </div>
              {touched.fullname && errors.fullname && (
                <div className="d my-2 error">{errors.fullname}</div>
              )}
              <div className="order-text">
                <span className="icon iLocation"></span>
                <Field
                  type="text"
                  name="residenceAddress"
                  placeholder={t('account.personal.residenceAddr')}
                  autoComplete="off"
                  validate={required}
                  maxLength={500}
                />
              </div>
              {touched.residenceAddress && errors.residenceAddress && (
                <div className="d my-2 error">{errors.residenceAddress}</div>
              )}
              <div className="order-text">
                <span className="icon iLocation"></span>
                <Select
                  className="profile-select"
                  classNamePrefix="profile-select"
                  name="issuedPlace"
                  options={lstNoiCap}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.orderno}
                  isSearchable={true}
                  defaultValue={lstNoiCap?.find(
                    (x) => x.orderno == issuedPlace
                  )}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(value) => {
                    formikRef.current.setFieldValue(
                      'issuedPlace',
                      value.orderno
                    );
                  }}
                />
              </div>
              {touched.issuedPlace && errors.issuedPlace && (
                <div className="d my-2 error">{errors.issuedPlace}</div>
              )}
              <div className="order-text">
                <span className="icon iCalendar"></span>
                <Field
                  type="text"
                  placeholder={t('account.personal.issuedDate')}
                  name="issuedDate"
                  autoComplete="off"
                  as={InputDate}
                  validate={required}
                  maxLength={50}
                />
              </div>
              {touched.issuedDate && errors.issuedDate && (
                <div className="d my-2 error">{errors.issuedDate}</div>
              )}
              {values.personalIDType != 1 && (
                <div className="order-text">
                  <span className="icon iCalendar"></span>
                  <Field
                    type="text"
                    name="expireDate"
                    placeholder={t('account.personal.expireDate')}
                    autoComplete="off"
                    as={InputDate}
                    maxLength={50}
                  />
                </div>
              )}
              {touched.expireDate && errors.expireDate && (
                <div className="d my-2 error">{errors.expireDate}</div>
              )}
              <span className="upload-desc">
                {t('account.personalModal.uploadDesc')}
              </span>

              <div className="file">
                <label htmlFor="front-image">
                  {!_frontImageName && (
                    <span className="text-white">
                      {t('account.personalModal.upload')}{' '}
                      {t('account.personalModal.frontImage').toLowerCase()}
                    </span>
                  )}
                  {_frontImageName && (
                    <>
                      <span className="icon iAttach"></span>
                      <span>{_frontImageName}</span>
                    </>
                  )}
                </label>
                <input
                  id="front-image"
                  type="file"
                  accept="image/*"
                  onChange={(e) => uploadImageHandle(e)}
                  autoComplete="off"
                  name="frontImage"
                />
              </div>
              {touched.frontImage && errors.frontImage && (
                <div className="d my-2 error">{errors.frontImage}</div>
              )}

              <div className="file file--upload">
                <label htmlFor="back-image">
                  {!_backImageName && (
                    <span className="text-white">
                      {t('account.personalModal.upload')}{' '}
                      {t('account.personalModal.backImage').toLowerCase()}
                    </span>
                  )}
                  {_backImageName && (
                    <>
                      <span className="icon iAttach"></span>
                      <span>{_backImageName}</span>
                    </>
                  )}
                </label>
                <input
                  id="back-image"
                  accept="image/*"
                  type="file"
                  onChange={(e) => uploadImageHandle(e, false)}
                  autoComplete="off"
                  name="backImage"
                />
              </div>
              {touched.backImage && errors.backImage && (
                <div className="d my-2 error">{errors.backImage}</div>
              )}

              <button className="btn btn-submit" type="submit">
                {t('txt-update')}
              </button>
            </Form>
          )}
        </Formik>
      </>
    );
  }
);

const PersonalIDChangeReview = withNamespaces('translations')(
  ({
    t,
    personalIDType,
    personalNum,
    fullname,
    residenceAddress,
    issuedPlace,
    issuedDate,
    expireDate,
    frontImage,
    backImage,
  }) => {
    const lstNoiCap = useSelector((state) => state.priceBoard?.noicap);
    const convertPersonalIDType = (id) => {
      const value = pIDType.find((x) => x.value == id);
      if (value) return value.label;
      else return '';
    };

    const getNoiCap = (id) => {
      const noiCap = lstNoiCap.find((x) => x.orderno == id);
      if (noiCap) return noiCap.name;
      else return '';
    };

    return (
      <div className="personalID-review">
        <div className="profile-panel-item">
          <span className="profile-panel-item__name">
            {t('txt-personalID-type')}
          </span>
          <span className="profile-panel-item__value">
            {convertPersonalIDType(personalIDType)}
          </span>
        </div>
        <div className="profile-panel-item">
          <span className="profile-panel-item__name">
            {t('txt-personalID-number')}
          </span>
          <span className="profile-panel-item__value">{personalNum}</span>
        </div>
        <div className="profile-panel-item">
          <span className="profile-panel-item__name">
            {t('txt-label-fullname')}
          </span>
          <span className="profile-panel-item__value">{fullname}</span>
        </div>
        <div className="profile-panel-item">
          <span className="profile-panel-item__name">
            {t('txt-residence-addr')}
          </span>
          <span className="profile-panel-item__value">{residenceAddress}</span>
        </div>
        <div className="profile-panel-item">
          <span className="profile-panel-item__name">
            {t('txt-personalID-IssuedPlace')}
          </span>
          <span className="profile-panel-item__value">
            {getNoiCap(issuedPlace)}
          </span>
        </div>
        <div className="profile-panel-item">
          <span className="profile-panel-item__name">
            {t('txt-personalID-IssuedDate')}
          </span>
          <span className="profile-panel-item__value">{issuedDate}</span>
        </div>
        {personalIDType != 1 && (
          <div className="profile-panel-item">
            <span className="profile-panel-item__name">
              {t('txt-personalID-ExpireDate')}
            </span>
            <span className="profile-panel-item__value">{expireDate}</span>
          </div>
        )}

        <div className="profile-image-section">
          <div className="profile-image profile-image--left">
            <span className="profile-image-desc">
              {t('account.personalModal.frontImage')}
            </span>
            <img src={frontImage} />
          </div>
          <div className="profile-image profile-image--right">
            <span className="profile-image-desc">
              {t('account.personalModal.backImage')}
            </span>
            <img src={backImage} />
          </div>
        </div>
      </div>
    );
  }
);

const PersonalIDChangeModal = withNamespaces('translations')(
  ({
    handleCloseModal,
    showModal,
    t,
    profile,
    noicap,
    toastHandle,
    handleLoadProfile,
    getProfileInforState,
  }) => {
    const dispatch = useDispatch();
    const [step, setStep] = useState(1);
    const [oneTimeToken, setOneTimeToken] = useState('');
    // const [authType, setAuthType] = useState(null);

    const [personalIDType, setPersonalIDType] = useState(profile?.custIdKnd);
    const [personalNum, setPersonalNum] = useState(profile?.custId);
    const [fullname, setFullname] = useState(profile?.custNm);
    const [residenceAddress, setResidenceAddress] = useState(
      profile?.perdomAddr
    );
    const [issuedPlace, setIssuedPlace] = useState(profile?.custIdIssuLoca);
    const [issuedDate, setIssuedDate] = useState(
      _formatDate2(profile?.custIdIssuDate)
    );
    const [expireDate, setExpireDate] = useState(
      _formatDate2(profile?.custIdExprDate)
    );
    const [frontImage, setFrontImage] = useState(null);
    const [backImage, setBackImage] = useState(null);
    const [frontImageName, setFrontImageName] = useState(null);
    const [backImageName, setBackImageName] = useState(null);

    const token = useSelector(makeGetToken());
    const defaultAccount = useSelector(makeGetDefaultAccount());

    const profileUpdateRequest = useSelector(
      (state) => state.customer.profileUpdateRequest
    );
    const profileUpdateRequestSuccess = useSelector(
      (state) => state.customer.profileUpdateRequestSuccess
    );
    const profileUpdateRequestError = useSelector(
      (state) => state.customer.profileUpdateRequestError
    );

    const nextStepHandle = (value) => {
      const {
        personalIDType,
        personalNum,
        fullname,
        residenceAddress,
        issuedPlace,
        issuedDate,
        expireDate,
        frontImage,
        backImage,
        _frontImageName,
        _backImageName,
      } = value;

      setPersonalIDType(personalIDType);
      setPersonalNum(personalNum);
      setFullname(fullname);
      setResidenceAddress(residenceAddress);
      setIssuedPlace(issuedPlace);
      setIssuedDate(issuedDate);
      setExpireDate(expireDate);
      setFrontImage(frontImage);
      setBackImage(backImage);
      setFrontImageName(_frontImageName);
      setBackImageName(_backImageName);
      setStep(2);
    };

    const submitHandle = () => {
      const uuid = uuidv4();
      const preprocessImage = (str) => {
        if (!str) return '';
        const _idx = str.indexOf(',');
        const _img = str.substring(_idx + 1);
        return _img;
      };
      const _frontImage = preprocessImage(frontImage);
      const _backImage = preprocessImage(backImage);
      const _issuedDate = _convertformatDate2(issuedDate);
      const _expireDate = _convertformatDate2(expireDate);

      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'changeCustInfo',
        rqId: uuid,
        channel: 'WTS',
        type: '6',
        token: oneTimeToken ?? '',
        data: {
          acntNo: defaultAccount.acntNo,
          cif: token.cif,
          chngTp: '01',
          custNm: fullname,
          custId: personalNum,
          custIdKnd: personalIDType,
          idIssuLoc: issuedPlace,
          idIssuDt: _issuedDate,
          idExprDt: _expireDate,
          addr: residenceAddress,
          idCardImg1: _frontImage,
          idCardImg2: _backImage,
        },
      };

      dispatch(updateProfileRequest(params));
    };

    useEffect(() => {
      if (!profileUpdateRequest) {
        if (profileUpdateRequestSuccess) {
          handleCloseModal();
          handleLoadProfile();
          getProfileInforState();
          toastHandle(t('account.personalModal.updateSuccess'));
          dispatch(resetUpdateProfile());
        }

        if (
          !profileUpdateRequestSuccess &&
          Array.isArray(profileUpdateRequestError) &&
          profileUpdateRequestError.length > 0
        ) {
          toastHandle(profileUpdateRequestError[0].body);
          dispatch(resetUpdateProfile());
        }
      }
    }, [profileUpdateRequest]);

    return (
      <div>
        <Modal
          show={showModal}
          dialogClassName="address-change-modal"
          backdropClassName="address-change-backdrop"
          contentClassName="address-change-content"
          onEscapeKeyDown={handleCloseModal}
          centered
          onHide={handleCloseModal}
          style={{ zIndex: 11111 }}
        >
          <span className="title">
            {t('account.personalModal.changeTitle')}
          </span>
          {step == 1 ? (
            <PersonalIDChangeForm
              nextStepHandle={nextStepHandle}
              toastHandle={toastHandle}
              personalIDType={personalIDType}
              personalNum={personalNum}
              fullname={fullname}
              residenceAddress={residenceAddress}
              issuedPlace={issuedPlace}
              issuedDate={issuedDate}
              expireDate={expireDate}
              frontImage={frontImage}
              frontImageName={frontImageName}
              backImage={backImage}
              backImageName={backImageName}
            />
          ) : (
            <>
              <PersonalIDChangeReview
                personalIDType={personalIDType}
                personalNum={personalNum}
                fullname={fullname}
                residenceAddress={residenceAddress}
                issuedPlace={issuedPlace}
                issuedDate={issuedDate}
                expireDate={expireDate}
                frontImage={frontImage}
                backImage={backImage}
              />
              <AuthenStep
                setOneTimeToken={setOneTimeToken}
                trackMixpanel
                // setAuthType={setAuthType}
              />

              <div className="confirm-btn-section">
                <button
                  className="btn btn-cancel"
                  type="button"
                  onClick={() => {
                    setStep(1);
                  }}
                >
                  {t('account.authen.back')}
                </button>
                <button
                  className="btn btn-submit"
                  type="submit"
                  disabled={!oneTimeToken}
                  onClick={() => submitHandle()}
                >
                  {t('account.authen.confirm')}
                </button>
              </div>
            </>
          )}
        </Modal>
      </div>
    );
  }
);

export default PersonalIDChangeModal;
