/* Trading - Giao Dich */
import i18n from '../i18n';
export const constants = {
  trading: {
    NORMAL_ORDER_TAB: '0',
    CONDITIONAL_ORDER_TAB: '1',
    PUT_THROUGH_ORDER_TAB: '2',
  },
  news: {
    ALL_MARKET_TAB: '0',
    US_INDICES_TAB: '1',
    COMMODITY_TAB: '2',
    VIDEO_TAB: '0',
    MARKET_BRIEF_TAB: '1',
  },
  pinefolio: {
    PRODUCT_LIST: 0,
    ORDER_LIST: 1,
    PORTFOLIO: 2,
    PLAN: 3,
    ANALYSIS: 4,
    createPlan: {
      FULL_SET: 0,
      SPLIT_SET: 1,
    },
    planManagement: {
      VIEW_1_MONTH: 1,
      VIEW_3_MONTHS: 3,
      VIEW_6_MONTHS: 6,
      VIEW_9_MONTHS: 9,
      VIEW_12_MONTHS: 12,
      ACCUMULATED_TAB: 0,
      PLAN_HISTORY_TAB: 1,
      BOND_ORDER_LIST_TAB: 2,
      BOND_DEAL_LIST_TAB: 3,
    },
  },
  marginCompetition: {
    GENERAL_INTRO_TAB: '0',
    RULE_AND_AWARD: '1',
    PERFORMANCE: '2',
    LEADER_BOARD: '3',
    QA: '4',
  },
  modules: {
    COMPETITION: 'COMPETITION',
    BOND: 'BOND',
    PINEFOLIO: 'PINEFOLIO',
  },
  G: {
    // Type of priceboard
    DEFAULT: 'G1', // normal
    ODD_LOT: 'G4', // oddlot
    POST_CLOSE: 'G3', // hnx post close
    BUY_IN: 'G7', // buyin
  },
  subAccount: {
    Pinefolio: 'P',
    Derivative: 'D',
    Margin: 'M',
    Normal: 'N',
  },
  serTp: {
    Derivative: 3,
    Margin: 4,
    Normal: 1,
  },
  authenType: {
    smsOTP: { name: 'SMS', value: 1 },
    matrix: { name: i18n.t('txt-matrix'), value: 2 },
    smartOTP: { name: 'Smart OTP', value: 3 },
  },
  bondProductType: {
    Fix: '1',
    Var: '2',
    Growth: '3',
  },
  sortDirection: {
    Asc: 0,
    Desc: 1,
  },
  siteTitle: 'Bảng giá chứng khoán - PineTree Securities',
};

export const bondConstants = {
  tabs: {
    PRODUCT_LIST: 0,
    ORDER_LIST: 1,
    DEAL_LIST: 2,
    PORTFOLIO: 3,
  },
  modals: {
    CLOSE: -1,
    INFOR: 0,
    BUY_ORDER: 1,
    SELL_ORDER: 2,
    DEMO: 3,
    CONTRACT: 4,
    INCOME_FLOW: 5,
  },
};

export const DERIVATIVE_CONSTANTS = {
  DRVX_TYPE: {
    INDEX: '21',
    BOND5Y: '22',
    BOND10Y: '23',
  },
  POSITION_PANEL: {
    POSITION_TAB: 0,
    OPEN_ORDER_TAB: 1,
    HISTORY_ORDER_TAB: 2,
    CONDITIONAL_ORDER_TAB: 3,
    CLOSE_POSITION_TAB: 4,
    PNL_REPORT_TAB: 5,
    ASSET_TAB: 6,
  },

  ORDER_TYPE: [
    {
      id: '01',
      label: 'LO',
    },
    {
      id: '02',
      label: 'ATO',
    },
    {
      id: '03',
      label: 'ATC',
    },
    {
      id: '05',
      label: 'MTL',
    },

    {
      id: '06',
      label: 'MOK',
    },
    {
      id: '07',
      label: 'MAK',
    },
  ],

  CONDITIONAL_ORDER_TYPE: [
    {
      id: '01',
      label: 'LO',
    },
    {
      id: '05',
      label: 'MTL',
    },
  ],

  PERCENTS: [
    {
      id: 25,
      label: '25%',
    },
    {
      id: 50,
      label: '50%',
    },
    {
      id: 75,
      label: '75%',
    },
    {
      id: 100,
      label: '100%',
    },
  ],
};

export const TRADING_TYPE = {
  LO: '01',
  ATO: '02',
  ATC: '03',
  MP: '04',
  MTL: '05',
  MOK: '06',
  MAK: '07',
  PLO: '08',
};

export const TYPE_SOCKET_CONDITION = [
  'CONW',
  'COCA',
  'COAD',
  'COEX',
  'CORE',
  'COEP',
];

export const TYPE_SOCKET_CONDITION_ACTIVE = ['COCA', 'COEX', 'CORE', 'COEP'];

export const SO_CONDITION_TYPE = {
  EQUAL: 'EQUAL',
  LESS_THAN: 'LESS_THAN',
  GREATER_THAN: 'GREATER_THAN',
  LESS_EQUAL_THAN: 'LESS_EQUAL_THAN',
  GREATER_EQUAL_THAN: 'GREATER_EQUAL_THAN',
};

export const SO_CONDITION_VALUE = {
  EQUAL: '=',
  LESS_THAN: '<',
  GREATER_THAN: '>',
  LESS_EQUAL_THAN: '≤',
  GREATER_EQUAL_THAN: '≥',
};

export const ORDER_ACTION_TYPE = {
  CANCEL_ORDER: '1',
  EDIT_ORDER: '2',
};

export const DERIVATIVE_CONFIRM_STATUS = {
  ALWAYS_SHOW: '0',
  HIDE_TO_RE_LOGIN: '1',
  HIDE_TO_TURN_ON: '2',
};

export const EQUITIES_OPEN_ORDER_PANEL_TABS = {
  OPEN_ORDER: '0',
  ORDER_HISTORY: '1',
  CONDITIONAL_ORDER: '2',
  PUT_THROUGH_ORDER: '3',
};

export const DERIVATIVES_POSITIONS_ASSET_TABS = {
  POSTIONS_TAB: '0',
  CLOSED_POSITION_TAB: '1',
  ASSET_TAB: '2',
};

/* Derivative */

export const CLOSE_REVERSE_TYPE = {
  CLOSE: '1',
  REVERSE: '2',
};

export const LONG_SHORT_TP = {
  LONG: '1',
  SHORT: '2',
};

export const CONDITION_TP = {
  TP: 'TP',
  SL: 'SL',
  SO: 'SO',
};

export const ORDER_PANEL_TP = {
  QUICK: 1,
  NORMAL: 2,
};

/* Trading - Giao Dich */

export const EVENT_TYPE = {
  NEW_ORDER: 'ODNW',
  CANCEL_ORDER: 'ODCA',
  MATCHING_ORDER: 'ODMA',
  REJECT_NEW_ORDER: 'ODRE',
  ADJUST_ORDER: 'ODAD',
  CASH_IN: 'CIIN',
  CASH_OUT: 'CIOU',
};

export const KEYBOARD_SHORTCUTS = {
  Long: {
    keycode: 113,
    name: 'F2',
  },
  Short: {
    keycode: 120,
    name: 'F9',
  },
};

// using to define switch multiple field in a cell column header in table
export const SWITCH_FIELD_STYLE = {
  dots: 1,
  switch: 2,
  none: 0,
};

// Loại nhóm chính sách:
//     1:Nhóm mặc định áp dụng cho KH CTV
//     2: Nhóm hiện tại của KH
export const PARTNER_FEE_POLICY = {
  defaultForCustomer: 1,
  currentForCustomer: 2,
};

// Loại dịch vụ
//
// "1": Phí GD cơ sở
// "2": Lãi vay Margin
// "3": Phí GD Phái sinh
// "":  Tất cả các loai
export const PARTNER_SERVICE_TYPE = {
  feeClass: '1',
  feeMargin: '2',
  feeDerivative: '3',
  all: '',
};

// Loại dịch vụ tính thù lao HH
//
// 1: Equities Trading Fee
// 2: Derivatives Trading Fee
// 3: Margin Loan Int
// Nếu không truyền giá trị gì à Truy vấn all loại phí HH
export const PARTNER_COMMISSION = {
  1: 'partner.equitiesTradingFee',
  3: 'partner.derivativesTradingFee',
  4: 'partner.marginLoanInt',
};

// Loại yêu cầu
//
// 1: Giới thiệu (actived dịch vụ CTV theo từng loại dịch vụ trên tiểu khoản KH)
// 2: Hủy giới thiệu (hủy gán CTV cho cả tài khoản)
// 3: Đăng ký dich vụ (gán CTV trên 1 tiểu khoản)
// 4: Hủy đăng ký dịch vụ(deactived dịch vụ CTV theo từng loại dịch vụ trên tiểu khoản KH)
// 5: Đổi CTV (tổng hợp của 1 và 2)
export const PARTNER_REQUEST_TYPE = {
  addBroker: '1',
  cancelInviteBroker: '2',
  registerService: '3',
  cancelRegisterService: '4',
  changeBroker: '5',
  changeFee: '6',
};

// Chiều tạo yêu cầu:
//     1: CTV tạo yêu cầu
//     2: KH tạo yêu cầu
export const PARTNER_SIDE_REQUEST = {
  broker: 1,
  customer: 2,
};

// 1: User truy vấn là CTV (hiển thị trên màn hình của CTV)
// 2: User truy vấn là KH (hiển thị trên màn hình của KH)
export const PARTNER_ROLE = {
  REFERRER: 1,
  REFEREE: 2,
};

// Loại xác nhận:
//     1 Chấp nhận yêu cầu
//     2 Từ chối yêu cầu
export const PARTNER_REQUEST_APPROVE_REJECT_TYPE = {
  APPROVE: 1,
  REJECT: 2,
};

// Loại yêu cầu
// 1: Giới thiệu (gán CTV cho cả tài khoản)
// 2: Hủy giới thiệu (hủy gán CTV cho cả tài khoản)
export const PARTNER_REQUEST_ADD_REMOVE_TYPE = {
  ADD: 1,
  REMOVE: 2,
};

export const PARTNER_REQUEST_STATUS = [
  { label: 'partner.allStatus', color: 'r', value: '-1' },
  { label: 'partner.pending', color: 'r', value: '0' },
  { label: 'partner.canceled', color: 'text--light3', value: '1' },
  { label: 'partner.rejected', color: 'd', value: '2' },
  { label: 'partner.completed', color: 'i', value: '3' },
];

export const serTp = {
  1: 'Equities Trading Fee',
  2: 'Derivatives Trading Fee',
  3: 'Margin Loan Int',
};

export const Ser4Cust = {
  Active: 'A',
  Pending: 'P',
  Expired: 'E',
  NotRegister: 'N',
};

export const OPENAPI_SOURCE = process.env.REACT_APP_OPENAPI_SOURCE;

export const CHART_LAYOUT = [
  { icon: 'iLayout1', value: 0, style: 'chart-layout-1' },
  { icon: 'iLayout2', value: 1, style: 'chart-layout-2' },
  { icon: 'iLayout2V', value: 1, style: 'chart-layout-2-vertical' },
  { icon: 'iLayout', value: 3, style: 'chart-layout-4' },
];

