import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field } from 'formik';
import {
  maxLength,
  required,
  number,
  validateEmail,
} from '../../../util/formikValidation';
import { withNamespaces } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  makeGetProfile,
  makeGetToken,
  makeGetDefaultAccount,
} from '../../../lib/selector';
import { _formatDate2, formatPhoneNumber } from '../../../util';
import {
  updateProfileRequest,
  resetUpdateProfile,
} from '../../../containers/customer/actions';
import { handleApiErrors } from '../../../lib/api-errors';
import { removeCookie } from '../../../lib/storages';
import { unsetClient } from '../../../containers/client/actions';
import { Logout, logoutRequest } from '../../modal/login/actions';
import AuthenStep from '../../common/auth';

const appUrl = `${process.env.REACT_APP_API_URL}`;

const NewPhoneConfirm = withNamespaces('translations')(
  ({ t, nextStepHandle, backStepHandle, phone, toastHandle }) => {
    const [disableTime, setDisableTime] = useState(null);
    const [OTPSent, setOTPSend] = useState(false);
    const token = useSelector(makeGetToken());
    const dispatch = useDispatch();

    const elRef = useRef(null);
    useEffect(() => {
      if (elRef) elRef.current.focus();
    }, []);

    const OTPSendingHandle = () => {
      const uuid = uuidv4();
      if (!token || disableTime) return;
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        rqId: uuid,
        channel: 'WTS',
        cmd: 'sendNewMobileOTP',
        data: {
          cif: token.cif,
          mobileNo: phone,
        },
      };

      const url = `${appUrl}/CoreServlet.pt`;
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: JSON.stringify(params),
      })
        .then(handleApiErrors)
        .then((response) => response.json())
        .then((res) => {
          if (res.http !== 200) {
            if (res.http === 401) {
              if (token) dispatch(logoutRequest(token));

              dispatch(unsetClient());
              removeCookie('token');
              removeCookie('authen');

              dispatch(Logout());
              toastHandle(t('txt-valid-loss-session'));
            } else {
              toastHandle(res.data.message || res.data.token);
            }
          } else {
            const _token = res.data.token;
            if (_token) setOTPSend(true);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    };

    const OTPVerifyHandle = (otp) => {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getToken',
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          type: '6',
          value: otp,
        },
      };

      const url = `${appUrl}/CoreServlet.pt`;
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: JSON.stringify(params),
      })
        .then(handleApiErrors)
        .then((response) => response.json())
        .then((res) => {
          if (res.http !== 200) {
            if (res.http === 401) {
              if (token) dispatch(logoutRequest(token));

              dispatch(unsetClient());
              removeCookie('token');
              removeCookie('authen');

              dispatch(Logout());
              toastHandle(t('txt-valid-loss-session'));
            } else {
              toastHandle(res.data.message || res.data.token);
            }
          } else {
            nextStepHandle();
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    };

    useEffect(() => {
      OTPSendingHandle();
    }, []);

    useEffect(() => {
      if (OTPSent) {
        // interval 60s
        const date = new Date();
        const enableTime = new Date(date.getTime() + 1 * 60000);
        let intervalID = setInterval(() => {
          const now = new Date();
          const diff = Math.round(
            (enableTime.getTime() - now.getTime()) / 1000
          );
          if (diff > 0) setDisableTime(diff);
          else {
            clearInterval(intervalID);
            setDisableTime(null);
            setOTPSend(false);
          }
        }, 1000);

        return () => {
          if (intervalID) {
            clearInterval(intervalID);
          }
        };
      }
    }, [OTPSent]);

    return (
      <>
        <span className="desc">
          {t('account.emailPhoneModal.confirmNewOTP')}
        </span>

        <Formik
          initialValues={{
            otp: '',
          }}
          onSubmit={(values) => {
            const { otp } = values;
            OTPVerifyHandle(otp);
          }}
        >
          {({ errors, touched }) => (
            <Form className="change-form confirm-otp-form" autoComplete="off">
              <div className="order-text">
                <Field
                  type="text"
                  name="otp"
                  placeholder={t('account.authen.inputOTP')}
                  autoComplete="off"
                  validate={required}
                  maxLength={6}
                  innerRef={elRef}
                />
              </div>
              {touched.otp && errors.otp && (
                <div className="d my-2 error">{errors.otp}</div>
              )}

              <span
                className="btn-resend"
                onClick={() => {
                  if (!OTPSent) setOTPSend(true);
                }}
              >
                <span className="icon iReload mr-2"></span>
                <span
                  className="mr-2"
                  onClick={() => {
                    if (!OTPSent) OTPSendingHandle();
                  }}
                >
                  {t('txt-resend')}
                </span>
                {OTPSent && disableTime && <span>({disableTime})</span>}
              </span>

              <div className="confirm-btn-section">
                <button
                  className="btn btn-cancel"
                  type="button"
                  onClick={() => {
                    backStepHandle();
                  }}
                >
                  {t('account.authen.back')}
                </button>
                <button className="btn btn-submit btn-lighter" type="submit">
                  {t('account.authen.confirm')}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
);

const EmailPhoneChangeForm = withNamespaces('translations')(
  ({ t, nextStepHandle, _email, _phone, toastHandle, profile }) => {
    const elRef = useRef(null);
    useEffect(() => {
      if (elRef) elRef.current.focus();
    }, []);

    return (
      <Formik
        initialValues={{
          email: _email,
          phone: _phone,
        }}
        onSubmit={(values) => {
          const { email, phone } = values;
          if (email == profile?.email && phone == profile?.cphnNo) {
            toastHandle(t('account.personalModal.compare'));
            return;
          }
          nextStepHandle(values);
        }}
      >
        {({ errors, touched }) => (
          <Form className="change-form" autoComplete="off">
            <div className="order-text">
              <span className="icon iPhone"></span>
              <Field
                type="text"
                name="phone"
                placeholder={t('txt-label-phone')}
                autoComplete="off"
                validate={number}
                maxLength={15}
                innerRef={elRef}
              />
            </div>
            {touched.phone && errors.phone && (
              <div className="d my-2 error">{errors.phone}</div>
            )}
            <div className="order-text">
              <span className="icon iEmail"></span>
              <Field
                type="text"
                name="email"
                placeholder="Email"
                autoComplete="off"
                validate={validateEmail}
                maxLength={50}
              />
            </div>
            {touched.email && errors.email && (
              <div className="d my-2 error">{errors.email}</div>
            )}
            <button className="btn btn-submit" type="submit">
              {t('txt-update')}
            </button>
          </Form>
        )}
      </Formik>
    );
  }
);

const EmailPhoneChangeModal = withNamespaces('translations')(
  ({
    handleCloseModal,
    showModal,
    t,
    toastHandle,
    profile,
    handleLoadProfile,
    getProfileInforState,
  }) => {
    const dispatch = useDispatch();
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState(profile?.email);
    const [phone, setPhone] = useState(profile?.cphnNo);
    const [oneTimeToken, setOneTimeToken] = useState('');
    const [authType, setAuthType] = useState(null);

    const token = useSelector(makeGetToken());
    const defaultAccount = useSelector(makeGetDefaultAccount());
    const profileUpdateRequest = useSelector(
      (state) => state.customer?.profileUpdateRequest
    );
    const profileUpdateRequestSuccess = useSelector(
      (state) => state.customer?.profileUpdateRequestSuccess
    );
    const profileUpdateRequestError = useSelector(
      (state) => state.customer?.profileUpdateRequestError
    );

    const nextStepHandle = (value) => {
      const { email, phone } = value;
      if (email) {
        setEmail(email);
        setStep(3);
      }

      if (phone) {
        const oldPhone = profile?.cphnNo;
        if (oldPhone != phone) {
          setPhone(phone);
          setStep(2);
        }
      }
    };

    const submitHandle = () => {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'changeCustInfo',
        rqId: uuid,
        channel: 'WTS',
        type: '6',
        token: oneTimeToken ?? '',
        data: {
          acntNo: defaultAccount.acntNo,
          cif: token.cif,
          chngTp: '02',
          mobile: phone,
          email: email,
        },
      };

      dispatch(updateProfileRequest(params));
    };

    useEffect(() => {
      if (!profileUpdateRequest) {
        if (profileUpdateRequestSuccess) {
          handleCloseModal();
          handleLoadProfile();
          getProfileInforState();
          toastHandle(t('account.personalModal.updateSuccess'));
          dispatch(resetUpdateProfile());
        }
        if (
          !profileUpdateRequestSuccess &&
          Array.isArray(profileUpdateRequestError) &&
          profileUpdateRequestError.length > 0
        ) {
          toastHandle(profileUpdateRequestError[0].body);
          dispatch(resetUpdateProfile());
        }
      }
    }, [profileUpdateRequest]);

    return (
      <div>
        <Modal
          show={showModal}
          dialogClassName="address-change-modal"
          backdropClassName="address-change-backdrop"
          contentClassName="address-change-content"
          //   onEscapeKeyDown={handleCloseModal}
          centered
          onHide={handleCloseModal}
          style={{ zIndex: 11111 }}
        >
          <span className="title">
            {t('account.emailPhoneModal.changeTitle')}
          </span>
          {step != 1 && phone && profile?.cphnNo != phone && (
            <span className="d-flex flex-column">
              <span className="desc">
                {t('account.emailPhoneModal.newPhone')}
              </span>
              <span>{formatPhoneNumber(phone)}</span>
            </span>
          )}
          {step != 1 && email && profile?.email != email && (
            <span className="d-flex flex-column">
              <span className="desc">
                {t('account.emailPhoneModal.newEmail')}
              </span>
              <span>{email}</span>
            </span>
          )}

          {step == 1 ? (
            <EmailPhoneChangeForm
              toastHandle={toastHandle}
              nextStepHandle={(value) => {
                nextStepHandle(value);
              }}
              profile={profile}
              _phone={phone}
              _email={email}
            />
          ) : step == 2 ? (
            <NewPhoneConfirm
              toastHandle={toastHandle}
              phone={phone}
              backStepHandle={() => setStep(1)}
              nextStepHandle={() => setStep(3)}
            />
          ) : (
            <>
              <AuthenStep
                setOneTimeToken={setOneTimeToken}
                ignoreTypes={['Matrix']}
                trackMixpanel
              />

              <div
                className="help"
                dangerouslySetInnerHTML={{ __html: t('account.authen.help') }}
              />

              <div className="confirm-btn-section">
                <button
                  className="btn btn-cancel"
                  type="button"
                  onClick={() => {
                    setStep(1);
                  }}
                >
                  {t('account.authen.back')}
                </button>
                <button
                  className="btn btn-submit"
                  type="submit"
                  disabled={!oneTimeToken}
                  onClick={() => submitHandle()}
                >
                  {t('account.authen.confirm')}
                </button>
              </div>
            </>
          )}
        </Modal>
      </div>
    );
  }
);

export default EmailPhoneChangeModal;
