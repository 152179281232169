import React, { useState, useEffect, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button, Col, ListGroup } from 'react-bootstrap';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { MdPhone, MdVerifiedUser } from 'react-icons/md';
import {
  makeGetToken,
  makeGetAuth,
  makeGetListAccount,
} from '../../lib/selector';
import { v4 as uuidv4 } from 'uuid';
import {
  getsMSSettingRequest,
  setsMSSettingRequest,
  getExtSettingRequest,
  setExtSettingRequest,
} from '../../containers/customer/actions';
import { setAuth, setToast } from '../../containers/client/actions';
import { GoUnverified } from 'react-icons/go';
import Switch from 'react-switch';
import Select from 'react-select';
import { withNamespaces } from 'react-i18next';

import './settings.scss';

const SMSRegistSwitches = ({
  notiCode,
  notiStatus,
  auth,
  token,
  dispatch,
  setSMSSettingRequesting,
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(notiStatus == '1');
  }, [notiStatus, setSMSSettingRequesting]);

  const toggle = () => {
    submitChangeHandle();
    setChecked(!checked);
  };

  const submitChangeHandle = () => {
    const uuid = uuidv4();
    const status = checked ? '2' : '1';

    if (token) {
      const params = {
        group: 'CORE',
        rqId: uuid,
        user: token.user,
        session: token.session,
        token: auth.token,
        type: auth.type,
        cmd: 'registCustSMSNotifi',
        channel: 'WTS',
        data: {
          notifiCode: notiCode,
          custNo: token.cif,
          custNotifiSetStat: status,
        },
      };

      dispatch(setsMSSettingRequest(params));
    }
  };

  return (
    <Switch
      onChange={toggle}
      checked={checked}
      className="react-switch"
      uncheckedIcon={false}
      checkedIcon={false}
      height={18}
      width={45}
    />
  );
};

const ServiceSwitches = ({
  featureTp,
  regTp,
  auth,
  token,
  dispatch,
  chooseAcc,
  disabled = false,
  t,
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(regTp == 'Y');
  }, [regTp]);

  const toggle = () => {
    submitChangeHandle();
    setChecked(!checked);
  };

  const submitChangeHandle = (state = null) => {
    const uuid = uuidv4();
    let status = checked ? 'N' : 'Y';
    if (state) {
      status = state;
    }

    if (token) {
      const params = {
        group: 'CORE',
        rqId: uuid,
        user: token.user,
        session: token.session,
        token: auth.token,
        type: auth.type,
        cmd: 'registCustExtFeature',
        channel: 'WTS',
        data: {
          featureTp: featureTp,
          custNo: token.cif,
          regTp: status,
          subAcntNo: chooseAcc?.subAcntNo,
        },
      };

      dispatch(setExtSettingRequest(params));
    }
  };

  // reset for matrix
  if (featureTp == '2')
    return (
      <div className="d-flex align-items-center">
        {checked && (
          <button
            className="btn-reset mr-3"
            onClick={() => submitChangeHandle('Y')}
          >
            {t('txt-reset')}
          </button>
        )}
        <Switch
          onChange={toggle}
          checked={checked}
          className="react-switch"
          uncheckedIcon={false}
          checkedIcon={false}
          height={18}
          width={45}
          disabled={disabled}
        />
      </div>
    );

  return (
    <Switch
      onChange={toggle}
      checked={checked}
      className="react-switch"
      uncheckedIcon={false}
      checkedIcon={false}
      height={18}
      width={45}
      disabled={disabled}
    />
  );
};

const Settings = withNamespaces('translations')(({ t }) => {
  const [chooseAcc, setChooseAcc] = useState();
  const [accounts, setAccounts] = useState([]);

  const dispatch = useDispatch();
  const auth = useSelector(makeGetAuth());
  const token = useSelector(makeGetToken());
  const listAccount = useSelector(makeGetListAccount());
  const extSetting = useSelector((state) => state.customer.extSetting);
  const lang = useSelector((state) => state.client.setting.lang || 'vi');
  const setExtSettingSuccess = useSelector(
    (state) => state.customer.setExtSettingSuccess
  );
  const setExtSettingErrors = useSelector(
    (state) => state.customer.setExtSettingErrors
  );
  const lastExtSetting = useSelector((state) => state.customer.lastExtSetting);
  const setExtSettingRequesting = useSelector(
    (state) => state.customer.setExtSettingRequesting
  );

  const sMSSetting = useSelector((state) => state.customer.sMSSetting);
  const lastSMSSetting = useSelector((state) => state.customer.lastSMSSetting);
  const setSMSSettingRequesting = useSelector(
    (state) => state.customer.setSMSSettingRequesting
  );
  const setSMSSettingSuccess = useSelector(
    (state) => state.customer.setSMSSettingSuccess
  );
  const setSMSSettingErrors = useSelector(
    (state) => state.customer.setSMSSettingErrors
  );

  useEffect(() => {
    getCustSMSNotifi();
  }, []);

  useEffect(() => {
    if (listAccount) {
      const _chooseAcc = listAccount.find((f) => f.isDefSubAcnt === 1);
      const _accounts = listAccount.filter((o) => o.subAcntStat !== 9);
      if (_accounts && _accounts.length > 0) {
        setAccounts(_accounts);
        if (_chooseAcc) setChooseAcc(_chooseAcc);
      }
    }
  }, [listAccount]);

  useEffect(() => {
    if (lang) {
      getCustSMSNotifi();
      getExtSetting();
    }
  }, [lang]);

  useEffect(() => {
    if (chooseAcc) getExtSetting();
  }, [chooseAcc]);

  useEffect(() => {
    let _obj = null;
    if (setSMSSettingRequesting) return;
    if (sMSSetting && lastSMSSetting) {
      _obj = sMSSetting.find((x) => x.notifiCode == lastSMSSetting.notifiCode);
    }
    if (_obj) {
      if (
        !setSMSSettingSuccess &&
        setSMSSettingErrors &&
        setSMSSettingErrors.length > 0
      ) {
        const toastMsg = {
          id: Math.random(),
          title: t('txt-unsuccessful'),
          msg: setSMSSettingErrors[0].body,
          time: setSMSSettingErrors[0].time,
        };

        dispatch(setToast(toastMsg));
      }

      if (setSMSSettingSuccess) {
        const toastMsg = {
          id: Math.random(),
          title: t('txt-success'),
          msg: `${
            _obj.custNotifiSetStat == '1'
              ? t('setting.reg')
              : t('setting.unReg')
          } "${_obj.notifiNm}" ${t('txt-success').toLowerCase()}`,
          time: new Date(),
        };

        dispatch(setToast(toastMsg));
      }
    }
  }, [setSMSSettingErrors, setSMSSettingSuccess]);

  useEffect(() => {
    console.log('lastSMSSetting ==> ', lastExtSetting);
    console.log('sMSSetting ==> ', extSetting);
    let _obj = null;
    if (setExtSettingRequesting) return;
    if (extSetting && lastExtSetting) {
      _obj = extSetting.find((x) => x.featureTp == lastExtSetting.featureTp);
    }
    if (_obj) {
      if (
        !setExtSettingSuccess &&
        setExtSettingErrors &&
        setExtSettingErrors.length > 0
      ) {
        const toastMsg = {
          id: Math.random(),
          title: t('txt-unsuccessful'),
          msg: setExtSettingErrors[0].body,
          time: setExtSettingErrors[0].time,
        };

        dispatch(setToast(toastMsg));
      }

      if (setExtSettingSuccess) {
        console.log('_obj ===> ', _obj);

        const toastMsg = {
          id: Math.random(),
          title: t('txt-success'),
          msg: `${
            _obj.regTp_note == 'R'
              ? t('setting.reset')
              : _obj.regTp == 'Y'
              ? t('setting.reg')
              : t('setting.unReg')
          } ${t('setting.service').toLowerCase()} ${_obj.featureName} ${t(
            'txt-success'
          ).toLowerCase()}`,
          time: new Date(),
        };

        dispatch(setToast(toastMsg));
      }
    }
  }, [setExtSettingSuccess, setExtSettingErrors]);

  const getCustSMSNotifi = () => {
    const uuid = uuidv4();

    if (token) {
      const params = {
        group: 'CORE',
        user: token.user,
        rqId: uuid,
        session: token.session,
        cmd: 'getCustSMSNotifi',
        data: {
          cif: token.cif,
          langTp: lang,
        },
      };

      dispatch(getsMSSettingRequest(params));
    }
  };

  const getExtSetting = () => {
    const uuid = uuidv4();

    if (token) {
      const params = {
        group: 'CORE',
        user: token.user,
        rqId: uuid,
        session: token.session,
        cmd: 'getCustExtFeature',
        channel: 'WTS',
        data: {
          cif: token.cif,
          langTp: lang,
          subAcntNo: chooseAcc?.subAcntNo,
        },
      };

      dispatch(getExtSettingRequest(params));
    }
  };

  const changeAccount = (value) => {
    setChooseAcc(value);
  };

  return (
    <PerfectScrollBar>
      <div className="d-flex justify-content-md-center settings">
        <Col
          md="6"
          className="d-flex flex-column"
          style={{ minWidth: '650px' }}
        >
          <div className="d-flex justify-content-between align-items-center mt-3">
            <label className="text-white fz_16">
              {t('lbl-sms-reg-service')}
            </label>
            <span className={'fz_14 ' + (auth.isVerified ? 'i' : 'd')}>
              {auth && auth.isVerified ? (
                <>
                  <MdVerifiedUser className="mr-2" />
                  {t('txt-da-xacthuc')}
                </>
              ) : (
                <>
                  <GoUnverified className="mr-2" />
                  {t('txt-chua-xacthuc')}
                </>
              )}
            </span>
          </div>
          <ListGroup className="mt-2">
            {sMSSetting &&
              sMSSetting.length > 0 &&
              sMSSetting.map((s, i) => (
                <ListGroup.Item
                  key={`sms-setting-item-${i}`}
                  className="d-flex align-items-center justify-content-between p-2"
                >
                  <span className="fz_14 text-content w-195 mr-3 flex-fill">
                    {s.notifiNm}
                  </span>
                  {auth && auth.isVerified ? (
                    <SMSRegistSwitches
                      key={`sms-switch-${i}`}
                      auth={auth}
                      token={token}
                      dispatch={dispatch}
                      notiCode={s.notifiCode}
                      notiStatus={s.custNotifiSetStat}
                      setSMSSettingRequesting={setSMSSettingRequesting}
                    />
                  ) : (
                    <Button
                      variant="warning"
                      className="fz_14 fw-500 text-white w-140"
                      onClick={() => dispatch(setAuth())}
                    >
                      {t('txt-xacthuc')}
                    </Button>
                  )}
                </ListGroup.Item>
              ))}
          </ListGroup>

          {extSetting && extSetting.length > 0 && (
            <>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <label className="text-white fz_16">
                  {t('lbl-other-reg-services')}
                </label>
              </div>

              <ListGroup className="mt-2">
                {extSetting.map((ex, i) => {
                  if (
                    ex.featureTp == 4 &&
                    ex?.subAcntNo != chooseAcc?.subAcntNo
                  )
                    return;
                  return (
                    <ListGroup.Item
                      key={`service-setting-item-${i}`}
                      className="d-flex align-items-center justify-content-between p-2"
                    >
                      {ex.featureTp != 4 ? (
                        <span className="fz_14 text-content w-195 mr-3 flex-fill">
                          {ex.featureName}
                        </span>
                      ) : (
                        <div className="d-flex justify-content-start flex-fill">
                          <span className="fz_14 text-content w-195 mr-3">
                            {ex.featureName}
                          </span>
                          <Select
                            options={accounts}
                            value={chooseAcc}
                            onChange={(e) => changeAccount(e)}
                            styles={{
                              control: () => ({
                                display: 'flex',
                                height: '28px',
                                minHeight: '28px',
                                backgroundColor: '#fff',
                                borderRadius: '4px',
                                border: 'solid 1px rgba(0, 0, 0, 0.4)',
                                position: 'relative',
                                width: '15rem',
                              }),
                              valueContainer: (styles) => ({
                                ...styles,
                                padding: 0,
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                color: state.isSelected ? '#fff' : '#000',
                                backgroundColor: state.isSelected
                                  ? '#005581'
                                  : '#adc3d8',
                                padding: 5,
                                fontSize: '14px',
                              }),
                              singleValue: (styles) => ({
                                ...styles,
                                padding: '2px 5px',
                                fontSize: '14px',
                              }),
                            }}
                          />
                        </div>
                      )}

                      {auth && auth.isVerified ? (
                        <ServiceSwitches
                          key={`service-switch-${i}`}
                          auth={auth}
                          token={token}
                          dispatch={dispatch}
                          featureTp={ex.featureTp}
                          featureName={ex.featureName}
                          regTp={ex.regTp}
                          chooseAcc={chooseAcc}
                          setExtSettingRequesting={setExtSettingRequesting}
                          t={t}
                          disabled={
                            ex.featureTp == 4 &&
                            chooseAcc &&
                            chooseAcc.subAcntNo.startsWith('M')
                          }
                        />
                      ) : (
                        <Button
                          variant="warning"
                          className="fz_14 fw-500 text-white w-140"
                          onClick={() => dispatch(setAuth())}
                        >
                          {t('txt-xacthuc')}
                        </Button>
                      )}
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </>
          )}

          <p className="text-content fz_14 mt-3 mb-3 text-center">
            {t('txt-make-sure-can-access-settings')}
          </p>
          <a
            href={`tel:02462823535`}
            className="text-center text-white fz_12 mb-3 mx-auto"
            style={{
              width: '120px',
              backgroundColor: '#589dc0',
              borderRadius: '18px',
              padding: '5px',
            }}
          >
            <MdPhone className="mr-2" />
            {'0246.282.3535'}
          </a>
        </Col>
      </div>
    </PerfectScrollBar>
  );
});

export default Settings;
