import { isEqual, takeRight } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import i18n from '../i18n';
import { constants } from './constant';
import Resizer from 'react-image-file-resizer';

export const MARKET = {
  10: 'VNI',
  11: 'VN30',
  '02': 'HNX',
  12: 'HNX30',
  '03': 'UPCOM',
};

export function _processMapCWData(item) {
  if (!item) return {};

  let symbol = { ...item };
  symbol.change = (item.lastPrice - item.r >= 0 ? '' : '-') + item.ot;
  symbol.changePc = (item.lastPrice - item.r >= 0 ? '' : '-') + item.changePc;

  let g1 = item.g1.split('|');
  symbol.bP1 = g1[0];
  symbol.bV1 = g1[1];
  symbol.bCl1 = g1[0] === 'ATC' || g1[0] === 'ATO' ? '' : g1[2];
  symbol.bVC1 = symbol.bCl1;
  let g2 = item.g2.split('|');
  symbol.bP2 = g2[0];
  symbol.bV2 = g2[1];
  symbol.bCl2 = g2[2];
  symbol.bVC2 = symbol.bCl2;
  let g3 = item.g3.split('|');
  symbol.bP3 = g3[0];
  symbol.bV3 = g3[1];
  symbol.bCl3 = g3[2];
  symbol.bVC3 = symbol.bCl3;
  let g4 = item.g4.split('|');
  symbol.oP1 = g4[0];
  symbol.oV1 = g4[1];
  symbol.oCl1 = g4[0] === 'ATC' || g4[0] === 'ATO' ? '' : g4[2];
  symbol.oVC1 = symbol.oCl1;
  let g5 = item.g5.split('|');
  symbol.oP2 = g5[0];
  symbol.oV2 = g5[1];
  symbol.oCl2 = g5[2];
  symbol.oVC2 = symbol.oCl2;
  let g6 = item.g6.split('|');
  symbol.oP3 = g6[0];
  symbol.oV3 = g6[1];
  symbol.oCl3 = g6[2];
  symbol.oVC3 = symbol.oCl3;
  let g7 = item.g7.split('|');
  symbol.duMua = g7[0];
  symbol.duBan = g7[1];
  return symbol;
}

export function _processMapDataCS(item) {
  if (!item) return {};
  let symbol = { ...item };
  symbol.change = (item.lastPrice - item.r >= 0 ? '' : '-') + item.ot;
  symbol.changePc = (item.lastPrice - item.r >= 0 ? '' : '-') + item.changePc;
  let g1 = item.g1.split('|');
  symbol.bP1 = g1[0];
  symbol.bV1 = g1[1];
  symbol.bCl1 = g1[0] === 'ATC' || g1[0] === 'ATO' ? '' : g1[2];
  symbol.bVC1 = symbol.bCl1;
  let g2 = item.g2.split('|');
  symbol.bP2 = g2[0];
  symbol.bV2 = g2[1];
  symbol.bCl2 = g2[2];
  symbol.bVC2 = symbol.bCl2;
  let g3 = item.g3.split('|');
  symbol.bP3 = g3[0];
  symbol.bV3 = g3[1];
  symbol.bCl3 = g3[2];
  symbol.bVC3 = symbol.bCl3;
  let g4 = item.g4.split('|');
  symbol.oP1 = g4[0];
  symbol.oV1 = g4[1];
  symbol.oCl1 = g4[0] === 'ATC' || g4[0] === 'ATO' ? '' : g4[2];
  symbol.oVC1 = symbol.oCl1;
  let g5 = item.g5.split('|');
  symbol.oP2 = g5[0];
  symbol.oV2 = g5[1];
  symbol.oCl2 = g5[2];
  symbol.oVC2 = symbol.oCl2;
  let g6 = item.g6.split('|');
  symbol.oP3 = g6[0];
  symbol.oV3 = g6[1];
  symbol.oCl3 = g6[2];
  symbol.oVC3 = symbol.oCl3;
  let g7 = item.g7.split('|');
  symbol.duMua = g7[0];
  symbol.duBan = g7[1];
  return symbol;
}

export function _processMapDataIndex(element) {
  let index = {};
  index.mc = element.mc;
  index.name = MARKET[element.mc];
  index.cIndex = element.cIndex;
  index.oIndex = element.oIndex;
  index.vol = element.vol;
  index.value = element.value;
  index.time = element.time;
  index.status = element.status;
  let dt = element.ot.split('|');
  index.change = _getTrendIndex(element.cIndex, element.oIndex) + dt[0];
  index.changePc = _getTrendIndex(element.cIndex, element.oIndex) + dt[1];
  index.up = dt[3];
  index.down = dt[4];
  index.rel = dt[5];
  index.cl = _getClassIndex(element.cIndex, element.oIndex);
  index.cei = 0;
  index.flo = 0;
  if (element && element.cf) {
    let cf = element.cf.split('|');
    if (Array.isArray(cf) && cf[0]) index.cei = cf[0];
    if (Array.isArray(cf) && cf[1]) index.flo = cf[1];
  }

  return index;
}

export function _processMapDataKRX(item) {
  if (!item) return;
  let symbol = {};
  symbol.sym = item.sym;
  symbol.mc = item.mc;
  symbol.c = item.c;
  symbol.f = item.f;
  symbol.r = item.r;
  symbol.lastPrice = item.lastPrice;
  symbol.lastVolume = item.lastVolume;
  symbol.lot = item.lot;
  symbol.change = (item.lastPrice - item.r >= 0 ? '' : '-') + item.ot;
  symbol.changePc = (item.lastPrice - item.r >= 0 ? '' : '-') + item.changePc;
  symbol.avePrice = item.avePrice;
  symbol.highPrice = item.highPrice;
  symbol.lowPrice = item.lowPrice;
  symbol.fBVol = item.fBVol;
  symbol.fSVolume = item.fSVolume;

  let totalBV = 0,
    totalSV = 0;
  const process = (symbol, values, fieldName, side = 'B') => {
    if (!values) return symbol;

    const value = values.split('|');
    let _symbol = symbol;
    _symbol[fieldName + '_p'] = value[0]; // price
    _symbol[fieldName + '_v'] = value[1]; // volume
    _symbol[fieldName + '_cl'] = value[2]; // color
    if (side == 'B') totalBV += parseInt(value[1]);
    if (side == 'S') totalSV += parseInt(value[1]);
    return _symbol;
  };

  Array.from({ length: 10 }, (_, i) => {
    symbol = process(symbol, item[`gb${i + 1}`], `gb${i + 1}`, 'B');
    symbol = process(symbol, item[`gs${i + 1}`], `gs${i + 1}`, 'S');
  });

  symbol.totalBV = totalBV;
  symbol.totalSV = totalSV;
  symbol.flag = item.flag;
  return symbol;
}

// process derivative data
export function _processMapDataDv(item, number = 10) {
  let symbol = {};
  if (!item) return symbol;
  symbol = { ...item };
  symbol.change = (item.lastPrice - item.r >= 0 ? '' : '-') + item.ot;
  symbol.changePc = (item.lastPrice - item.r >= 0 ? '' : '-') + item.changePc;

  let totalBV = 0,
    totalSV = 0;
  const process = (symbol, values, fieldName, side = 'B') => {
    let _symbol = symbol;

    if (!values) {
      _symbol[fieldName + '_p'] = '0';
      _symbol[fieldName + '_v'] = '0';
      _symbol[fieldName + '_cl'] = '';
    } else {
      const value = values.split('|');
      _symbol[fieldName + '_p'] = value[0]; // price
      _symbol[fieldName + '_v'] = value[1]; // volume
      _symbol[fieldName + '_cl'] = value[2]; // color
      if (side == 'B') totalBV += parseInt(value[1]);
      if (side == 'S') totalSV += parseInt(value[1]);
    }
    return _symbol;
  };

  Array.from({ length: number }, (_, i) => {
    symbol = process(symbol, item[`gb${i + 1}`], `gb${i + 1}`, 'B');
    symbol = process(symbol, item[`gs${i + 1}`], `gs${i + 1}`, 'S');
  });

  symbol.totalBV = totalBV;
  symbol.totalSV = totalSV;
  return symbol;
}

/**
 * !function
 */

export function addZero(i) {
  if (i < 10) {
    i = '0' + i;
  }
  return i;
}

function pick(...manyMoreArgs) {
  let a = manyMoreArgs,
    c,
    r,
    u = a.length;
  for (c = 0; c < u; c++) {
    if (((r = a[c]), void 0 !== r && null !== r)) return r;
  }
}

function FormatCurrency(num, delimitor, separate) {
  let sign, tail, ret_value;
  num = num.toString().replace(/\$|\,/g, '');
  if (isNaN(num)) num = '0';
  sign = num == (num = Math.abs(num));
  let str = num.toString();
  let arr_str = str.split(separate);
  if (arr_str.length > 1) {
    tail = String(arr_str[1]);
    if (tail.length < 2) {
      tail = tail + '0';
    }
  } else {
    tail = '';
  }

  num = arr_str[0];
  for (let i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
    num =
      num.substring(0, num.length - (4 * i + 3)) +
      delimitor +
      num.substring(num.length - (4 * i + 3));
  }

  if (tail == '') ret_value = (sign ? '' : '-') + num;
  else ret_value = (sign ? '' : '-') + num + separate + tail;
  return ret_value;
}

export function formatVolume10(number, hasDot = false) {
  const _number = number + '';
  if (_number.includes('.')) {
    const _index = _number.indexOf('.');
    const cut = _number.slice(_index, _number.length);
    const rest = _number.slice(0, _index);
    if (cut == '.0') return formatVolume10(rest);
    else return formatVolume10(rest, true) + cut;
  } else {
    let vTemp = StringToInt(number) * 10;
    let vNumber = FormatCurrency(vTemp.toString(), ',', '.');
    return vTemp == 0 && hasDot
      ? '0'
      : vNumber.substring(0, vNumber.length - 1);
  }
}

const orderType = ['ATO', 'ATC', 'LO', 'MP', 'MTL', 'MOK', 'MAK', 'PLO'];
export function numberFormat(h, c = 0, t = '', r, u) {
  if (orderType.includes(h)) return h;
  h = +h || 0;
  c = +c;
  let w = (h.toString().split('.')[1] || '').split('e')[0].length,
    n,
    g,
    d = h.toString().split('e');

  g = (Math.abs(d[1] ? d[0] : h) + Math.pow(10, -Math.max(c, w) - 1)).toFixed(
    c
  );
  w = String(parseInt(g, 10));
  n = 3 < w.length ? w.length % 3 : 0;
  r = pick(r, '.');
  u = pick(u, ',');
  h = (0 > h ? '-' : '') + (n ? w.substr(0, n) + u : '');
  h += w.substr(n).replace(/(\d{3})(?=\d)/g, '$1' + u);
  c && (h += r + g.slice(-c));
  d[1] && 0 !== +h && (h += 'e' + d[1]);
  if (h == 0) return t;
  return h;
}

export function colorFix(cPrice, oPrice, tran, san, tc) {
  if (cPrice == '0' || cPrice == '0.0' || cPrice == '0.00' || cPrice == '') {
    return '';
  }
  if (cPrice == tc) return 'r';
  if (cPrice == tran) return 'c';
  if (cPrice == san) return 'f';
  if (cPrice - oPrice > 0) {
    return 'i';
  } else if (cPrice - oPrice < 0) {
    return 'd';
  }
  return 'r';
}

export function StringToInt(pString) {
  pString = '' + pString;
  pString = pString.replace(/,/g, '');
  let vInt = parseInt(pString, 10);
  if (isNaN(vInt)) {
    return 0;
  } else {
    return vInt;
  }
}

export function StringToInt_2(pString, hasDot = false) {
  // Oddlot case
  pString = '' + pString;
  if (pString.includes('.')) {
    const _index = pString.indexOf('.');
    const odd = parseInt(pString.slice(_index + 1, pString.length));
    const rest = pString.slice(0, _index);

    return StringToInt_2(rest, true) + odd;
  } else {
    return pString == '' ? 0 : parseFloat(pString.replace(/,/g, '')) * 10;
  }
}

export function StringToDouble(pString) {
  pString = '' + pString;
  pString = pString.replace(/,/g, '');
  //Convert sang so he so 10
  let vFloat = parseFloat(pString);
  if (isNaN(vFloat)) {
    return 0;
  } else {
    return vFloat;
  }
}

export function StringToDouble2(pString) {
  if (pString.includes('.')) {
    const _index = pString.indexOf('.');
    const cut = pString.slice(_index, pString.length);
    const rest = pString.slice(0, _index);

    return StringToDouble2(rest) + cut;
  } else {
    pString = '' + pString;
    pString = pString.replace(/,/g, '');
    //Convert sang so he so 10
    let vFloat = parseFloat(pString);
    if (isNaN(vFloat)) {
      return 0;
    } else {
      return vFloat;
    }
  }
}

export function formatDateTimeFull(idata, slack = ':') {
  const h = addZero(idata.getHours());
  const m = addZero(idata.getMinutes());
  const s = addZero(idata.getSeconds());

  const yyyy = idata.getFullYear();
  const mm = addZero(idata.getMonth() + 1);
  const dd = addZero(idata.getDate());

  return (
    <>
      {''}
      <span className="ml-0">{h + slack + m + slack + s}</span>
      <span>{dd + '/' + mm + '/' + yyyy}</span>
      {''}
    </>
  );
}

export function formatDateTime(idata, slack = ':') {
  if (typeof idata === 'number') {
    let st = new Date(idata);
    let h = addZero(st.getHours());
    let m = addZero(st.getMinutes());
    let s = addZero(st.getSeconds());
    return h + slack + m + slack + s;
  } else {
    let h = addZero(idata.getHours());
    let m = addZero(idata.getMinutes());
    let s = addZero(idata.getSeconds());
    return h + slack + m + slack + s;
  }
}

export function formatDate(idata, slack = ':', _fm = 'ymd') {
  let y, m, d;

  try {
    if (typeof idata === 'number' || typeof idata === 'string') {
      let st = new Date(idata);
      y = st.getFullYear();
      m = addZero(st.getMonth() + 1);
      d = addZero(st.getDate());
    } else {
      y = idata.getFullYear();
      m = addZero(idata.getMonth() + 1);
      d = addZero(idata.getDate());
    }
    if (_fm === 'ymd') {
      return y + slack + m + slack + d;
    } else {
      return d + slack + m + slack + y;
    }
  } catch (error) {
    return null;
  }
}

export function stringToDate(idata, format = 'ymd', slack = '/') {
  try {
    const _date = idata.split(slack);
    if (format === 'ymd') {
      let y = _date[0];
      let m = addZero(_date[1]);
      let d = addZero(_date[2]);
      let st = new Date(y + '/' + m + '/' + d);
      return st;
    } else {
      let y = _date[2];
      let m = addZero(_date[1]);
      let d = addZero(_date[0]);
      let st = new Date(y + '/' + m + '/' + d);
      return st;
    }
  } catch (error) {
    return null;
  }
}

export function stringToDate2(str) {
  if (!str || str.length !== 8) return str;

  const year = str.substring(0, 4);
  const month = str.substring(4, 6);
  const day = str.slice(-2);

  const result = new Date(year + '/' + month + '/' + day);
  return new Date(result);
}

export function _formatDate(str) {
  if (!str) return str;
  const arr = str.split('/');

  return arr.reverse().join('');
}
export function _formatDate2(str, slack = '/') {
  if (!str || str.length !== 8) return str;

  const year = str.substring(0, 4);
  const month = str.substring(4, 6);
  const day = str.slice(-2);

  return day + slack + month + slack + year;
}

export function _getColorPnl(number) {
  if (number > 0) {
    return 'i';
  } else if (number < 0) {
    return 'd';
  } else {
    return 'r';
  }
}

export function _convertformatDate2(str, slack = '/') {
  if (!str) return str;
  const _str = str.split(slack);

  const year = _str[2];
  const month = _str[1];
  const day = _str[0];

  return year + month + day;
}

export function checkRightEndDate(str) {
  if (!str || str.length !== 8) return false;
  const year = str.substring(0, 4);
  const month = str.substring(4, 6);
  const day = str.slice(-2);

  let chkDate = new Date(year, month - 1, day);
  let curDate = new Date();
  curDate.setHours(0, 0, 0, 0);

  return curDate <= chkDate;
}

export function _diff2Date(date1, date2) {
  let mydate1, mydate2;
  if (typeof date1 === 'string') {
    let parts1 = date1.split('/');
    mydate1 = new Date(parts1[2], parts1[1] - 1, parts1[0]);
  } else {
    mydate1 = date1;
  }
  if (typeof date2 === 'string') {
    let parts2 = date2.split('/');
    mydate2 = new Date(parts2[2], parts2[1] - 1, parts2[0]);
  } else {
    mydate2 = date2;
  }

  var diff = Math.floor((mydate1 - mydate2) / (1000 * 60 * 60 * 24));

  return diff || 0;
}

export function _diff2DateSecond(date1, date2) {
  let mydate1, mydate2;
  if (typeof date1 === 'string') {
    let parts1 = date1.split('/');
    mydate1 = new Date(parts1[2], parts1[1] - 1, parts1[0]);
  } else {
    mydate1 = date1;
  }
  if (typeof date2 === 'string') {
    let parts2 = date2.split('/');
    mydate2 = new Date(parts2[2], parts2[1] - 1, parts2[0]);
  } else {
    mydate2 = date2;
  }

  var diff = Math.floor((mydate1 - mydate2) / 1000);

  return diff || 0;
}

export function getStatusIndex(st) {
  if (st === 'K' || st === 'C' || st === 'Undefined') {
    return i18n.t('txt-closed');
  }
  if (st === 'I') {
    return i18n.t('txt-intermission');
  }
  return i18n.t('txt-opened');
}

export function _getClassIndex(cPrice, oPrice) {
  if (cPrice - oPrice > 0) {
    return 'i';
  } else if (cPrice - oPrice < 0) {
    return 'd';
  }
  return 'r';
}

export function _getTrendIndex(cPrice, oPrice) {
  if (cPrice - oPrice < 0) {
    return '-';
  } else return '';
}

export function _getStatusIndex(st, mc = '10') {
  const day = new Date().getDay();
  const hours = new Date().getHours();
  const mins = new Date().getMinutes();

  if (
    st === 'C' &&
    [1, 2, 3, 4, 5].includes(day) &&
    hours < 9 &&
    hours >= 8 &&
    mins > 15
  ) {
    return i18n.t('txt-wait-open');
  } // If the day is week day and 8h15 < date < 9h

  if (mc === '10' || mc === '11') {
    // hose
    if (st === 'P') return i18n.t('txt-preopen');

    if (st === 'O') return i18n.t('txt-opened');

    if (st === 'I') return i18n.t('txt-intermission');

    if (st === 'A') return i18n.t('txt-atc');

    if (st === 'Z') return i18n.t('txt-pt');

    if (st === 'C') return i18n.t('txt-closed');

    return i18n.t('txt-closed');
  }

  if (mc === '02') {
    // hnx
    if (st === 'O') return i18n.t('txt-opened');

    if (st === 'I') return i18n.t('txt-intermission');

    if (st === 'A') return i18n.t('txt-atc');

    if (st === 'C' && hours < 15 && [1, 2, 3, 4, 5].includes(day)) {
      return i18n.t('txt-plo');
    }

    return i18n.t('txt-closed');
  }

  if (mc === '03') {
    // Upcom
    if (st === 'O') return i18n.t('txt-opened');

    if (st === 'I') return i18n.t('txt-intermission');

    return i18n.t('txt-closed');
  }
  // if (st === 'K' || st === 'C' || st === 'J' || st === 'Undefined') {
  //   return i18n.t('txt-closed');
  // }
  // if (st === 'I') {
  //   return i18n.t('txt-intermission');
  // }
  // return i18n.t('txt-opened');
}

export function _getNameAcronym(str) {
  let matches = str.match(/\b([A-Z])/g);
  let acronym = takeRight(matches, 2).join('').toUpperCase();
  return acronym;
}

export const ordStatus = [
  { id: '1', desc: 'txt-reserved' },
  { id: '2', desc: 'txt-sent' },
  { id: '3', desc: 'txt-waitingConfirm' },
  { id: '4', desc: 'txt-matched' },
  { id: '5', desc: 'txt-pending' },
  { id: '6', desc: 'txt-canceled' },
  { id: '7', desc: 'txt-admended' },
  { id: '8', desc: 'WaitBankDeposits' },
  { id: '9', desc: 'txt-rejected' },
  { id: 'E', desc: 'txt-error-order' },
  { id: 'X', desc: 'txt-expired' },
];

export const ordConditionStatus = [
  { id: 'NEW', desc: 'txt-waiting-executed', key: 1, color: 'r' },
  { id: 'EXECUTED', desc: 'txt-executed', key: 2, color: 'i' },
  { id: 'CANCELED', desc: 'txt-canceled', key: 3, color: 'text--light3' },
  { id: 'EXPIRED', desc: 'txt-expired', key: 4, color: 'text--light3' },
  { id: 'REJECTED', desc: 'txt-rejected', key: 5, color: 'd' },
];

export function _getOrderStatus(sttCode, volume = '0', matchVolume = '0') {
  let sttStr = '';

  const result = ordStatus.find((x) => x.id === sttCode);
  if (result) {
    // if (sttCode === '3' && volume !== matchVolume) {
    //   sttStr = i18n.t('txt-part-matched');
    // } else {
    sttStr = i18n.t(result.desc);
    //}
  }

  return sttStr;
}

export function _convertTradeTp(orderPrice) {
  if (StringToDouble(orderPrice) > 0) return '01';
  const ordPrice = orderPrice.toUpperCase();

  switch (ordPrice) {
    case 'ATO':
      return '02';

    case 'ATC':
      return '03';

    // MP is no longer in KRX
    case 'MP':
      return '04';

    case 'MTL':
      return '05';

    case 'MOK':
      return '06';

    case 'MAK':
      return '07';

    case 'PLO':
      return '08';

    default:
      return '00';
  }
}

export function _convertTpToPrice(odType) {
  switch (odType) {
    case '01':
      return 'LO';

    case '02':
      return 'ATO';

    case '03':
      return 'ATC';

    // MP is no longer in KRX
    case '04':
      return 'MP';

    case '05':
      return 'MTL';

    case '06':
      return 'MOK';

    case '07':
      return 'MAK';

    case '08':
      return 'PLO';

    // case '09':
    //   return 'Buy in';

    default:
      return 'LO';
  }
}

export function _convertActTypeToStr(actType) {
  switch (actType) {
    case '0':
      return i18n.t('txt-match-order-vol');

    case '1':
      return i18n.t('txt-match-one-time');

    default:
      return actType;
  }
}

export const condStatus = [
  { id: 'P', desc: 'txt-cond-pending' },
  { id: 'C', desc: 'txt-cond-cancelling' },
  { id: 'I', desc: 'txt-cond-active' },
  { id: 'A', desc: 'txt-cond-activated' },
  { id: 'F', desc: 'txt-cond-final' },
  { id: 'E', desc: 'txt-cond-expired' },
  { id: 'R', desc: 'txt-cond-wait-react' },
  { id: 'N', desc: 'txt-cond-pending' },
];

export function _getOrdCondStatus(stt) {
  let strStt = '-';

  const result = condStatus.find((x) => x.id == stt);
  if (result) strStt = i18n.t(result.desc);
  else strStt = i18n.t('txt-cond-pending');

  return strStt;
}

export function mapOrderChanel(ordrChnl) {
  let strStt = '-';
  switch (ordrChnl) {
    case '01':
      strStt = i18n.t('orderChannel.offline');
      break;

    case '02':
      strStt = i18n.t('orderChannel.cc');
      break;

    case '03':
      strStt = i18n.t('orderChannel.web');
      break;

    case '04':
      strStt = i18n.t('orderChannel.app');
      break;

    case '05':
      strStt = i18n.t('orderChannel.co');
      break;

    case '06':
      strStt = i18n.t('orderChannel.bloomberg');
      break;

    case '07':
      strStt = i18n.t('orderChannel.pist');
      break;

    case '08':
      strStt = i18n.t('orderChannel.stk');
      break;

    default:
      break;
  }
  return strStt;
}

export function mapRightType(rghtSubTp) {
  let strStt = '-';
  strStt = i18n.t('txt-stt-right-' + rghtSubTp);
  return strStt;
}

export const ordCondType = [
  { id: 'GTC', desc: 'txt-out-time' },
  { id: 'TSO', desc: 'txt-trend-order' },
  { id: 'TPO', desc: 'txt-take-profit' },
  { id: 'STO', desc: 'txt-stop-loss' },
  { id: 'PCO', desc: 'txt-purchase-order' },
];

export function _getOrdCondStr(ordertype) {
  let strStt = '-';
  const result = ordCondType.find((x) => x.id == ordertype);
  if (result) strStt = i18n.t(result.desc);
  return strStt;
}

export function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

export function getTransferStatus(stt) {
  let strStt = '-';
  switch (stt) {
    case '1':
      strStt = i18n.t('txt-transfer-pending');
      break;

    case '2':
      strStt = i18n.t('txt-complete');
      break;

    case '3':
      strStt = i18n.t('txt-rejected');
      break;

    case '4':
      strStt = i18n.t('txt-bank-error');
      break;

    default:
      break;
  }
  return strStt;
}

export function getStockTransferStatus(stt) {
  let strStt = '-';
  switch (stt) {
    case '1':
    case '0':
      strStt = i18n.t('txt-success');
      break;

    case '2':
      strStt = i18n.t('txt-unsuccessful');
      break;

    case '3':
      strStt = i18n.t('txt-rejected');
      break;

    default:
      break;
  }
  return strStt;
}

export function _chkOrdCanEdit(stt, qty, matQty, odType) {
  let canEdit = false;
  switch (stt) {
    case '1':
      break;

    default:
      break;
  }
  return canEdit;
}

export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
}

export function _validVolHose(vol) {
  let step = 10;
  if (vol < 1 || vol > 500000 || vol % step !== 0) {
    return false;
  }

  return true;
}

export function _validVolHnx(vol) {
  let step = 100;
  if (vol === 0 || (vol >= 100 && vol % step !== 0)) {
    return false;
  }

  return true;
}

export function _hideMidStr(str) {
  if (!str || str.length < 2) return str;

  return str.replace(/^(\+?[\d]{2})\d+(\d{2})$/g, '$1*****$2');
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function renderEvent(account, symbol, buySelTp, ordrStatTp, ordrNo) {
  let str =
    'TK: ' +
    account +
    ' ' +
    (buySelTp === '1' ? 'Mua' : 'Bán') +
    ' ' +
    symbol +
    '. Trạng thái: ' +
    _getOrderStatus(ordrStatTp) +
    ', ' +
    (ordrNo ? 'SHL: ' + ordrNo : '');
  return str;
}

export function formatPhoneNumber(str) {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '');
  //Check if the input is of correct

  let match84 = cleaned.match(/^(84|)?(\d{3})(\d{3})(\d{3})$/);
  if (match84) {
    //Remove the matched extension code
    //Change this to format for any country code.
    let intlCode = match84[1] ? '+84' : '';
    return [
      '(',
      intlCode,
      ') ',
      match84[2],
      '.',
      match84[3],
      '.',
      match84[4],
    ].join('');
  } else {
    let match = cleaned.match(/(\d{4})(\d{3})(\d{3})$/);
    if (match) {
      return [match[1], '.', match[2], '.', match[3]].join('');
    }
  }
  return null;
}

export function canEditOrder(ordrStatTp, ordrQty, matchedQty, ordrTrdTp) {
  if (ordrQty === matchedQty) return false;
  if (['3'].indexOf(ordrStatTp) < 0) return false;

  if (ordrTrdTp == '09' && matchedQty === 0 && ordrStatTp == '3') return true; // buy in allow edit
  if (ordrTrdTp !== '01' && matchedQty === 0) return false;

  return true;
}

export function canDeleteOrder(ordrStatTp, ordrQty, matchedQty) {
  if (ordrQty === matchedQty) return false;
  if (['1', '3'].indexOf(ordrStatTp) < 0) return false;

  return true;
}

export function canDeletePinefolioOrder(ordrStatTp) {
  if (['1', '2'].indexOf(ordrStatTp) < 0) return false;

  return true;
}

export function removeAccent(str) {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  str = str.replace(/Đ/g, 'D');
  return str;
}

export function validateEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function getMessageError(res, getState) {
  const { setting } = getState().client;
  const { listMessage } = getState().priceBoard;

  const lang = setting.lang || 'vi';
  const msg = listMessage[res.data.messageNo];

  if (msg) {
    return msg[lang];
  }

  return (
    res.data.message ||
    res.data.errorMessage ||
    'Có lỗi trong quá trình xử lý. Hãy thử lại sau!'
  );
}

/*
 * Vietnam Market time
 * */
export const isMarketOpen = () => {
  const currentHour = new Date().getUTCHours();
  // 08 A.M GMT+7
  const OPEN_TIME_UTC = 1;
  // 03 P.M GMT+7
  const CLOSE_TIME_UTC = 8;

  return OPEN_TIME_UTC <= currentHour && currentHour <= CLOSE_TIME_UTC;
};

/*
 * Reboot backend time
 * */
export const isRebootServerTime = () => {
  const currentHour = new Date().getUTCHours();
  const currentMin = new Date().getUTCMinutes();
  // 00 A.M GMT +7
  const SHUTDOWN_HOUR_UTC = 17;
  return (
    currentHour == SHUTDOWN_HOUR_UTC && currentMin >= 12 && currentMin <= 15
  );
};

/*
 * Function convert number
 */
export const convertToInternationalCurrencySystem = (
  labelValue,
  fixedNumber = 2
) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(fixedNumber)
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(fixedNumber)
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(fixedNumber)
    : Math.abs(Number(labelValue));
};

/*
 * Function convert number
 */
export const convertToInternationalCurrencyCharacter = (
  labelValue,
  isShortQuanlity = true,
  toLowerCase = false
) => {
  let text = '';
  if (!labelValue) return text;
  const value = Math.abs(Number(labelValue));
  const isOne = ('' + labelValue).startsWith('1');

  // Nine Zeroes for Billions
  if (value >= 1.0e9)
    text = isShortQuanlity
      ? i18n.t('quantity.short.b')
      : isOne
      ? i18n.t('quantity.long.b')
      : i18n.t('quantity.many.b');
  else if (value >= 1.0e6)
    text = isShortQuanlity
      ? i18n.t('quantity.short.m')
      : isOne
      ? i18n.t('quantity.long.m')
      : i18n.t('quantity.many.m');
  else if (value >= 1.0e3)
    text = isShortQuanlity
      ? i18n.t('quantity.short.k')
      : isOne
      ? i18n.t('quantity.long.k')
      : i18n.t('quantity.many.k');
  else text = '';

  return toLowerCase ? text.toLocaleLowerCase() : text;
};

/*
 * Function convert number
 */
export const convertToInternationalCurrency = (labelValue) => {
  // Nine Zeroes for Billions
  return (
    convertToInternationalCurrencySystem(labelValue) +
    convertToInternationalCurrencyCharacter(labelValue)
  );
};

export const getWindowDimensions = () => {
  // const { innerWidth: width, innerHeight: height } = window;
  const { width, height } = window.screen;

  return {
    width,
    height,
  };
};

export const BOND_TYPE = [
  { id: '2', name: 'bond.bondOTC' },
  { id: '3', name: 'bond.bondListed' },
  { id: '4', name: 'bond.bondPrivate' },
];

export const INVESTOR_TYPE = [
  { id: 1, name: 'bond.investor.type.professional' },
  { id: 2, name: 'bond.investor.type.unProfessional' },
];

export const formatToNewDate = (date, type = 'dmy') => {
  if (date) {
    if (type == 'dmy') {
      return new Date(
        date.slice(0, 4),
        date.slice(4, 6) - 1,
        date.slice(6, 8),
        0,
        0,
        0,
        0
      );
    } else {
      return new Date(
        date.slice(8, 6),
        date.slice(6, 4) - 1,
        date.slice(4, 0),
        0,
        0,
        0,
        0
      );
    }
  } else return new Date();
};

export const mapConditionalOrderStatus = (oderStatus) => {
  const ORDER_STATUSES = [
    { value: 'A', label: 'txt-cond-activated' },
    { value: 'I', label: 'txt-cond-active' },
    { value: 'C', label: 'txt-cond-cancelling' },
    { value: 'E', label: 'txt-cond-expired' },
    { value: 'F', label: 'txt-cond-final' },
    { value: 'P', label: 'txt-cond-pending' },
    { value: 'R', label: 'txt-cond-wait-react' },
  ];
  return ORDER_STATUSES.find((orderStatus) => orderStatus.value == oderStatus);
};

export const mapNegoOrdrStatTp = (ordStatus) => {
  const ORDER_STATUSES = [
    { value: '1', label: 'txt-sent' },
    { value: '2', label: 'txt-waitingConfirm' },
    { value: '3', label: 'txt-pending' },
    { value: '4', label: 'txt-canceled' },
    { value: '5', label: 'txt-modifyed' },
    { value: '6', label: 'txt-negotiation' },
    { value: '7', label: 'txt-match' },
    { value: '8', label: 'txt-not-accept' },
    { value: '9', label: 'txt-match-canceled' },
    { value: 'P', label: 'txt-matched-pending' },
    { value: 'X', label: 'txt-rejected' },
    { value: 'R', label: 'txt-expired' },
  ];
  return ORDER_STATUSES.find((orderStatus) => orderStatus.value == ordStatus);
};

export const SORT_NEWS = [
  { value: '0', label: 'txt-all' },
  { value: '1', label: 'news.hotArticle' },
];

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}

export const mapPfOrderStat = (ordStatus) => {
  const ORDER_STATUSES = [
    {
      value: '1',
      label: 'pinefolio.orderList.status.waitingForExecuting',
      color: 'i',
    },
    {
      value: '2',
      label: 'pinefolio.orderList.status.executing',
      color: 'text--light-blue3',
    },
    { value: '3', label: 'pinefolio.orderList.status.completed', color: 'i' },
    { value: '4', label: 'pinefolio.orderList.status.canceled', color: 'r' },
    { value: '5', label: 'pinefolio.orderList.status.break', color: 'd' },
    { value: '6', label: 'pinefolio.orderList.status.expired', color: 'd' },
  ];
  return ORDER_STATUSES.find((orderStatus) => orderStatus.value == ordStatus);
};

export const mapPfOrderDtl = (orderStatus) => {
  const ORDER_STATUSES = [
    { value: '1', label: 'pinefolio.orderList.status.processing', color: 'r' },
    { value: '2', label: 'pinefolio.orderList.status.completed', color: 'i' },
    { value: '3', label: 'pinefolio.orderList.status.canceled', color: 'd' },
    { value: '4', label: 'pinefolio.orderList.status.expired', color: 'd' },
  ];
  return ORDER_STATUSES.find(
    (_orderStatus) => _orderStatus.value == orderStatus
  );
};

export const mapPfOrderDtlSt = (orderStatus) => {
  const ORDER_STATUSES = [{ id: '0', desc: 'txt-opened' }, ...ordStatus];
  return ORDER_STATUSES.find((_orderStatus) => _orderStatus.id == orderStatus);
};

export const mapAplcSrcTp = (source) => {
  return APLC_SRC_TP.find((_source) => _source.id == source);
};

export const ORDER_TYPES = [
  {
    id: '0',
    label: 'txt-all',
  },
  {
    id: '1',
    label: 'txt-buy',
  },
  {
    id: '2',
    label: 'txt-sell',
  },
];

export const APLC_SRC_TP = [
  { id: '0', label: 'pinefolio.orderList.source.direct' },
  { id: '2', label: 'pinefolio.orderList.source.accumulation' },
  { id: '3', label: 'pinefolio.orderList.source.rebalance' },
];

export const INVESTMENT_PLAN_STATUS = [
  { value: '1', label: 'pinefolio.orderList.status.new' },
  { value: '2', label: 'pinefolio.orderList.status.executing' },
  { value: '3', label: 'pinefolio.orderList.status.completed' },
  { value: '4', label: 'pinefolio.orderList.status.canceled' },
  { value: '5', label: 'pinefolio.orderList.status.break' },
];

export const getAllDaysInMonth = (month, year) => {
  return Array.from(
    { length: new Date(year, month, 0).getDate() },
    (_, i) => new Date(year, month - 1, i + 1)
  );
};

export const getListMonth = (month, step) => {
  var theMonths = [
    'time.months.january',
    'time.months.february',
    'time.months.march',
    'time.months.april',
    'time.months.may',
    'time.months.june',
    'time.months.july',
    'time.months.august',
    'time.months.september',
    'time.months.october',
    'time.months.november',
    'time.months.december',
  ];
  if (month + step - 1 <= 12) {
    return [...theMonths].slice(month - 1, month + step - 1);
  } else {
    return [...theMonths]
      .slice(month - 1, 12)
      .concat([...theMonths].slice(0, month + step - 1 - 12));
  }
};

export const numberToDayOfWeek = (date) => {
  const weekday = [
    'time.dayOfWeekShort.sunday',
    'time.dayOfWeekShort.monday',
    'time.dayOfWeekShort.tuesday',
    'time.dayOfWeekShort.wednesday',
    'time.dayOfWeekShort.thursday',
    'time.dayOfWeekShort.friday',
    'time.dayOfWeekShort.saturday',
  ];
  return weekday[date];
};

export const dragDiv = (className) => {
  const divs = document.querySelectorAll(className);
  console.log('div ===> ', divs);
  let isDown = false,
    startX,
    scrollLeft;

  if (divs && !!divs.length > 0) {
    const div = divs[divs.length - 1];
    div.addEventListener('mousedown', (e) => {
      isDown = true;
      div.classList.add('active');
      startX = e.pageX - div.offsetLeft;
      scrollLeft = div.scrollLeft;
    });
    div.addEventListener('mouseleave', () => {
      isDown = false;
      div.classList.remove('active');
    });
    div.addEventListener('mouseup', () => {
      isDown = false;
      div.classList.remove('active');
    });
    div.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - div.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      div.scrollLeft = scrollLeft - walk;
      console.log(walk);
    });
  }
};

export const moveDiv = (className, offset) => {
  const divs = document.querySelectorAll(className);
  let isDown = false,
    startX,
    scrollLeft;

  if (divs && !!divs.length > 0) {
    const div = divs[divs.length - 1];
    scrollLeft = div.scrollLeft;
    div.scrollLeft = scrollLeft + offset;
  }
};

export const accountTypeDetect = (account) => {
  if (!account) return '';

  const subAcntNo = account.subAcntNo;
  if (subAcntNo && subAcntNo.startsWith('M')) return 'Margin';
  else if (subAcntNo && subAcntNo.startsWith('N')) return 'txt-normal';
  else if (subAcntNo && subAcntNo.startsWith('P')) return 'PineFolio';
  else if (subAcntNo && subAcntNo.startsWith('D')) return 'txt-derivative';
  else return '';
};

export const subAccountTypeDetect = (subAcntNo) => {
  if (!subAcntNo) return '';

  if (subAcntNo.startsWith('M')) return 'Margin';
  else if (subAcntNo.startsWith('N')) return 'txt-normal';
  else if (subAcntNo.startsWith('P')) return 'PineFolio';
  else if (subAcntNo.startsWith('D')) return 'txt-derivative';
  else return '';
};

export const packDeposit = (pfPlan) => {
  if (pfPlan && pfPlan.packs && pfPlan.packs.length > 0) {
    let _activePack = pfPlan.activePack;

    return pfPlan.packs.find((pack) => _activePack == pack.packNo);
  } else {
    return 0;
  }
};

export const isWeekend = (date) => {
  if (!date) {
    return false;
  }
  return (isWeekend = [0, 6].indexOf(date.getDay()) != -1);
};

export const mapDepoTp = (depoTp) => {
  const DEPO_TP = [
    { value: 1, label: 'pinefolio.plan.depositType.schedule' },
    { value: 2, label: 'pinefolio.plan.depositType.manual' },
  ];
  if (!depoTp) return;

  return DEPO_TP.find((item) => item.value == depoTp);
};

/** Helper */
const _formatDate3 = (str, slack = '-') => {
  if (!str || str.length !== 8) return str;

  const year = str.substring(0, 4);
  const month = str.substring(4, 6);
  const day = str.slice(-2);

  return year + slack + month + slack + day;
};

export const durationFrom2Dates = (_startDate, _endDate) => {
  const _date1 = new Date(_formatDate3(_startDate));
  const _date2 = new Date(_formatDate3(_endDate));

  var difference_In_Time = _date2.getTime() - _date1.getTime();
  var difference_In_Days = difference_In_Time / (1000 * 3600 * 24) + 1;
  return difference_In_Days;
};

export const mapPlanTp = (planType) => {
  const DEPO_TP = [
    { value: 1, label: 'pinefolio.plan.fullSet' },
    { value: 2, label: 'pinefolio.plan.splitSet' },
  ];
  if (!planType) return;

  return DEPO_TP.find((item) => item.value == planType);
};

export const getTickerType = (ticker, cwList) => {
  if (!ticker) return '';
  if (ticker.length == 3) return 'Stock';
  if (cwList?.find((x) => x.code == ticker)) return 'CW';
  return 'Bond';
};

export const getSymbolInfor = (ticker, allTickers) => {
  const res = allTickers.find((x) => x.stock_code == ticker);
  return res;
};

export const LIST_BOND_ORDER_STATUS = [
  { key: '', value: 'txt-all', color: 'light-color' },
  { key: '1', value: 'bond.orderList.statusValue.pending', color: 'r' },
  { key: '2', value: 'bond.orderList.statusValue.confirmed', color: 'i' },
  { key: '3', value: 'bond.orderList.statusValue.matched', color: 'i' },
  { key: '4', value: 'bond.orderList.statusValue.canceled', color: 'd' },
  { key: '5', value: 'bond.orderList.statusValue.rejected', color: 'd' },
];

export const LIST_BOND_DEAL_STATUS = [
  { key: '', value: 'txt-all', color: 'light-color' },
  { key: '1', value: 'bond.dealList.statusValue.dealed', color: 'i' },
  { key: '2', value: 'bond.dealList.statusValue.paid', color: 'i' },
  { key: '3', value: 'bond.dealList.statusValue.leg1', color: 'i' },
  { key: '4', value: 'bond.dealList.statusValue.leg2', color: 'i' },
  {
    key: '5',
    value: 'bond.dealList.statusValue.done',
    color: 'i',
  },
];

export const mapStatus = (statusCode) => {
  return LIST_BOND_ORDER_STATUS.find((item) => item.key === statusCode);
};

export const getCurrentDateMinusDays = (minusNumber, type = 'd') => {
  const today = new Date();
  if (type == 'd') {
    return today.setDate(today.getDate() - minusNumber);
  } else if (type == 'm') {
    return today.setMonth(today.getMonth() - minusNumber);
  } else if (type == 'y') {
    return today.setFullYear(today.getFullYear() - minusNumber);
  } else return today;
};

/**
 *  Func init account list and reset current account follow location changing
 *  if current location is derivative then defaultAccount derivative
      else if current location is pinefolio then defaultAccount pinefolio
      else set defaultAccount to the subaccount has isDefSubAcnt = true || 1
      else set defaultAccount to the normal subaccount
 * @param {*} data = token?.custInfo
 * @param {*} callback
 */
export const subAccountProcess = (data, currentAccount, callback) => {
  let listAccount = [],
    defaultAccount;
  const currentLocation = window.location?.hash || '';

  if (data && data.normal && !!data.normal.length > 0) {
    // Init list account
    data.normal.forEach((element) => {
      const opts = {
        ...element,
        value: element.subAcntNo,
        label:
          element.subAcntNo +
          ' - ' +
          i18n.t(subAccountTypeDetect(element.subAcntNo)),
      };

      listAccount.push(opts);
      // if current location is derivative then defaultAccount derivative
      // else if current location is pinefoliothen defaultAccount pinefolio
      // else set defaultAccount to the subaccount has isDefSubAcnt = true || 1
      // else set defaultAccount to the normal subaccount

      if (currentLocation == '#/home/derivative') {
        if (element?.subAcntClss == constants.subAccount.Derivative)
          defaultAccount = opts;
      }

      if (currentLocation == '#/home/pinefolio') {
        if (element?.subAcntClss == constants.subAccount.Pinefolio)
          defaultAccount = opts;
      }
    });

    const defaultSetupAccount = listAccount.find(
      (x) => x.isDefSubAcnt == 1 && x.subAcntStat != 9
    );

    if (!defaultAccount) {
      if (currentAccount) {
        if (
          currentLocation.includes('#/home/transaction') ||
          currentLocation.includes('#/home/personal')
        ) {
          defaultAccount = currentAccount;
        } else {
          if (
            currentAccount.subAcntClss == constants.subAccount.Pinefolio ||
            currentAccount.subAcntClss == constants.subAccount.Derivative
          )
            defaultAccount = defaultSetupAccount;
          else defaultAccount = currentAccount;
        }
      } else {
        const normalAccount = listAccount.find(
          (x) =>
            x.subAcntClss == constants.subAccount.Normal && x.subAcntStat != 9
        );

        defaultAccount = defaultSetupAccount || normalAccount;
      }
    }

    if (callback && typeof callback == 'function') {
      callback(listAccount, defaultAccount);
    }
  }
};

export const DERIVATIVE_ORDER_TYPE = [
  { key: 1, value: 1, label: 'derivative.long' },
  { key: 2, value: 2, label: 'derivative.short' },
];

export const setHeightPerfectScroll = (id) => {
  const panelContent = document.getElementById('panel-content-' + id);
  const filter = document.getElementById('panel-filter-' + id);
  const tableHeader = document.getElementById('table-header-' + id);
  const perfectScroll = document.getElementById('perfect-scroll-' + id);
  if (perfectScroll)
    perfectScroll.style.height =
      (panelContent ? panelContent.clientHeight : 0) -
      (tableHeader ? tableHeader.clientHeight : 0) -
      (filter ? filter.clientHeight : 0) +
      'px';
};

export function toQueryString(obj) {
  const params = new URLSearchParams();
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      let value = obj[key];
      if (
        value !== undefined &&
        value !== null &&
        value !== '' &&
        !Number.isNaN(value)
      ) {
        params.append(key, value);
      }
    }
  }
  return params.toString();
}

export const mergeAndDistinct = (array, fieldname) => {
  const mergedArray = array.reduce((acc, obj) => {
    return acc.concat(obj[fieldname]);
  }, []);

  // Distinct merged array
  const distinctArray = [...new Set(mergedArray)];

  return distinctArray;
};

export const resizeFile = (file) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      600,
      600,
      'JPEG',
      50,
      0,
      (uri) => {
        resolve(uri);
      },
      'file'
    );
  });
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// use recursion settimeout to replace setInterval to avoid Browser Throttling
export const recursionTask = (task, time) => {
  if (task && typeof task == 'function') {
    task();
  }

  setTimeout(() => {
    if (task && typeof task == 'function') {
      task();
    }
  }, time);
};

export const isObjectEmpty = (obj) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const tryIntParse = (value) => {
  const parseValue = parseInt(value, 10);
  if (isNaN(parseValue)) {
    return { success: false, value: null };
  }

  return { success: true, value: parseValue };
};

export const timeStrToTimespan = (timeStr) => {
  const [hours, minutes, seconds] = timeStr.split(':').map(Number);
  const now = new Date();
  now.setHours(hours, minutes, seconds, 0);
  return now.getTime();
};

export const timestampToTimeStr = (timestamp) => {
  const date = new Date(timestamp);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
};

export const isEmptyObject = (obj) => {
  if (obj === null || obj === undefined) {
    return true;
  }

  return Object.keys(obj).length === 0;
};


export const PARTNER_REQUEST_TYPE_OPTION = [
  {
    value: '',
    label: 'partner.requestTypeOption.allRequest',
    color: 'light-color',
  },
  { value: '1', label: 'partner.requestTypeOption.referral' },
  { value: '2', label: 'partner.requestTypeOption.unreferral', color: 'd' },
  {
    value: '3',
    label: 'partner.requestTypeOption.serviceRegistration',
  },
  {
    value: '4',
    label: 'partner.requestTypeOption.cancelServiceRegistration',
    color: 'd',
  },
  { value: '5', label: 'partner.requestTypeOption.changeReferral' },
  {
    value: '6',
    label: 'partner.requestTypeOption.changeTheFeeGroup',
  },
];
