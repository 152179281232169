import React, { Fragment } from 'react';
import {
  Button,
  Col,
  ListGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { translate } from 'react-i18next';
import { BsFillTrashFill } from 'react-icons/bs';
import { GoUnverified } from 'react-icons/go';
import { MdPhone, MdVerifiedUser } from 'react-icons/md';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import { setAuth, setChangeBank } from '../../containers/client/actions';
import { removeBankRequest } from '../../containers/customer/actions';
import {
  makeGetAuth,
  makeGetCustomerBankAccount,
  makeGetDefaultAccount,
  makeGetToken,
} from '../../lib/selector';

const BankItem = ({
  bankAcntNo,
  bankAcntNm,
  bankName,
  bankCode,
  branchName,
  t,
  dispatch,
  token,
  auth,
  defaultAccount,
}) => {
  const handleDelete = () => {
    swal
      .fire({
        title: t('lbl-are-you-sure'),
        text: t('lbl-remove-the-bank'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('lbl-ok'),
        cancelButtonText: t('txt-cancel'),
      })
      .then((result) => {
        if (result.isConfirmed) {
          const uuid = uuidv4();
          if (token) {
            const params = {
              group: 'CORE',
              user: token.user,
              rqId: uuid,
              session: token.session,
              token: auth.token,
              type: auth.type,
              cmd: 'RegistBankAcnt',
              channel: 'WTS',
              data: {
                acntNo: defaultAccount?.acntNo,
                bankAcntNo: bankAcntNo,
                bankAcntStat: 9,
                bankCode: bankCode,
              },
            };

            dispatch(removeBankRequest(params));
          }
        }
      });
  };

  const buttonStyle = {
    outline: 0,
    borderRadius: '5px',
  };

  return (
    <>
      <ListGroup
        className="mt-2"
        style={{
          position: 'relative',
        }}
      >
        <ListGroup.Item className="d-flex p-2 align-item-center justify-content-between">
          <div>
            <span className="fz_14 text-content w-195 d-inline-block align-middle">
              {t('txt-label-account-num')}
            </span>
            <span className="fz_14 text-white fw-500 d-inline-block align-middle">
              {bankAcntNo}
            </span>
          </div>
          <div>
            {auth && auth.isVerified && (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{t('lbl-remove')}</Tooltip>}
              >
                <button
                  className="btn btn-danger btn-sm"
                  onClick={handleDelete}
                  style={buttonStyle}
                >
                  <BsFillTrashFill size={14} />
                </button>
              </OverlayTrigger>
            )}
          </div>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex p-2">
          <span className="fz_14 text-content w-195">
            {t('txt-label-account-holder')}
          </span>
          <span className="fz_14 text-white fw-500">{bankAcntNm}</span>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex p-2">
          <span className="fz_14 text-content w-195">
            {t('txt-label-beneficiary-bank')}
          </span>
          <span className="fz_14 text-white fw-500">{bankName}</span>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex p-2">
          <span className="fz_14 text-content w-195">
            {t('txt-label-branch')}
          </span>
          <span className="fz_14 text-white fw-500">{branchName}</span>
        </ListGroup.Item>
      </ListGroup>
    </>
  );
};

class Bank extends React.Component {
  _handleAuthen = () => {
    this.props.dispatch(setAuth());
  };

  render() {
    const { auth, token, bankAcc, t, defaultAccount } = this.props;
    return (
      <PerfectScrollBar>
        <div className="d-flex justify-content-md-center">
          <Col md="6" className="d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center mt-3">
              <label className="text-white fz_16">
                {t('txt-label-bank-account')}
              </label>
              <span className={'fz_14 ' + (auth.isVerified ? 'i' : 'd')}>
                {auth && auth.isVerified ? (
                  <Fragment>
                    <MdVerifiedUser className="mr-2" />
                    {t('txt-da-xacthuc')}
                  </Fragment>
                ) : (
                  <Fragment>
                    <GoUnverified className="mr-2" />
                    {t('txt-chua-xacthuc')}
                  </Fragment>
                )}
              </span>
            </div>
            <ListGroup className="mt-2">
              {bankAcc &&
                bankAcc.bankAccount &&
                !!bankAcc.bankAccount.length && (
                  <BankItem
                    bankAcntNo={bankAcc.bankAccount[0].bankAcntNo}
                    bankAcntNm={bankAcc.bankAccount[0].bankAcntNm}
                    bankName={bankAcc.bankAccount[0].bankName}
                    bankCode={bankAcc.bankAccount[0].bankCd}
                    branchName={bankAcc.bankAccount[0].branchName}
                    t={t}
                    key={'bank-acc-0'}
                    dispatch={this.props.dispatch}
                    auth={auth}
                    token={token}
                    defaultAccount={defaultAccount}
                  />
                )}
            </ListGroup>
            <label className="text-white fz_16 mt-3">
              {t('txt-label-account-second')}
            </label>
            {bankAcc &&
              bankAcc.bankAccount &&
              !!bankAcc.bankAccount.length &&
              bankAcc.bankAccount.map((item, index) => {
                if (index === 0) return null;
                return (
                  <BankItem
                    bankAcntNo={item.bankAcntNo}
                    bankAcntNm={item.bankAcntNm}
                    bankName={item.bankName}
                    bankCode={item.bankCd}
                    branchName={item.branchName}
                    defaultAccount={defaultAccount}
                    t={t}
                    key={`bank-acc-` + index}
                    dispatch={this.props.dispatch}
                    auth={auth}
                    token={token}
                  />
                );
              })}
            {bankAcc &&
              bankAcc.bankAccount &&
              bankAcc.bankAccount.length < 2 && (
                <p className="text-content fz_14 mt-3 mb-3 text-center">
                  {t('txt-no-data-found')}
                </p>
              )}
            {bankAcc &&
              bankAcc.bankAccount &&
              bankAcc.bankAccount.length < 5 &&
              (auth && auth.isVerified ? (
                <Button
                  variant="info"
                  className="fz_14 fw-500 text-white mx-auto px-3 py-1 my-2"
                  onClick={() => this.props.dispatch(setChangeBank())}
                >
                  {t('txt-regis-bank-account')}
                </Button>
              ) : (
                <Button
                  variant="warning"
                  className="fz_14 fw-500 text-white w-140 mx-auto px-3 py-1 my-2"
                  onClick={() => this._handleAuthen()}
                >
                  {t('txt-xacthuc')}
                </Button>
              ))}
            <p className="text-content fz_14 mt-3 mb-3 text-center">
              {t('txt-hotline-support')}
            </p>
            <a
              href={`tel:02462823535`}
              className="text-center text-white fz_12 mb-3 mx-auto"
              style={{
                width: '120px',
                backgroundColor: '#589dc0',
                borderRadius: '18px',
                padding: '5px',
              }}
            >
              <MdPhone className="mr-2" />
              {'0246.282.3535'}
            </a>
          </Col>
        </div>
      </PerfectScrollBar>
    );
  }
}

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getAuth = makeGetAuth();
  const getCustomerBankAccount = makeGetCustomerBankAccount();
  const getDefaultAccount = makeGetDefaultAccount();

  const mapStateToProps = (state) => {
    return {
      token: getToken(state),
      auth: getAuth(state),
      bankAcc: getCustomerBankAccount(state),
      defaultAccount: getDefaultAccount(state),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(translate('translations')(Bank));
