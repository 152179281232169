import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Col, ListGroup } from 'react-bootstrap';

import PerfectScrollBar from 'react-perfect-scrollbar';

import { MdVerifiedUser, MdPhone } from 'react-icons/md';
import { GoUnverified } from 'react-icons/go';
import {
  makeGetToken,
  makeGetDefaultAccount,
  makeGetAuth,
} from '../../lib/selector';
import { translate } from 'react-i18next';
class Relative extends React.Component {
  render() {
    const { auth, token, t } = this.props;
    console.log(auth, token);
    return (
      <PerfectScrollBar>
        <div className="d-flex justify-content-md-center">
          <Col md="6" className="d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center mt-3">
              <label className="text-white fz_16">
                {t('txt-label-authorization-person')}
              </label>
              <span className={'fz_14 ' + (auth.isVerified ? 'i' : 'd')}>
                {auth && auth.isVerified ? (
                  <Fragment>
                    <MdVerifiedUser className="mr-2" />
                    {t('txt-da-xacthuc')}
                  </Fragment>
                ) : (
                  <Fragment>
                    <GoUnverified className="mr-2" />
                    {t('txt-chua-xacthuc')}
                  </Fragment>
                )}
              </span>
            </div>
            <ListGroup className="mt-2">
              <ListGroup.Item className="d-flex p-2">
                <span className="fz_14 text-content w-195">
                  {t('txt-label-fullname')}
                </span>
                <span className="fz_14 text-white fw-500">
                  {token && token.name}
                </span>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex p-2">
                <span className="fz_14 text-content w-195">
                  {t('txt-label-identification')}
                </span>
                <span className="fz_14 text-white fw-500">{'-'}</span>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex p-2">
                <span className="fz_14 text-content w-195">
                  {t('txt-label-issued-on')}
                </span>
                <span className="fz_14 text-white fw-500">{'-'}</span>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex p-2">
                <span className="fz_14 text-content w-195">
                  {t('txt-label-place')}
                </span>
                <span className="fz_14 text-white fw-500">{'-'}</span>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex p-2">
                <span className="fz_14 text-content w-195">
                  {t('txt-label-gender')}
                </span>
                <span className="fz_14 text-white fw-500">{'-'}</span>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex p-2">
                <span className="fz_14 text-content w-195">
                  {t('txt-label-contact-info')}
                </span>
                <span className="fz_14 text-white fw-500">{'-'}</span>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex p-2">
                <span className="fz_14 text-content w-195">
                  {t('txt-label-authorization-type')}
                </span>
                <span className="fz_14 text-white fw-500">{'-'}</span>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex p-2">
                <span className="fz_14 text-content w-195">
                  {t('txt-label-start-date')}
                </span>
                <span className="fz_14 text-white fw-500">{'-'}</span>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex p-2">
                <span className="fz_14 text-content w-195">
                  {t('txt-label-end-date')}
                </span>
                <span className="fz_14 text-white fw-500">{'-'}</span>
              </ListGroup.Item>
            </ListGroup>
            <label className="text-white fz_16 mt-3 mb-3">
              {t('txt-label-authorization-person-other')}
            </label>
            <ListGroup className="mt-2">
              <ListGroup.Item className="d-flex p-2">
                <span className="fz_14 text-content w-195">
                  {t('txt-label-fullname')}
                </span>
                <span className="fz_14 text-white fw-500">
                  {token && token.name}
                </span>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex p-2">
                <span className="fz_14 text-content w-195">
                  {t('txt-label-identification')}
                </span>
                <span className="fz_14 text-white fw-500">{'-'}</span>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex p-2">
                <span className="fz_14 text-content w-195">
                  {t('txt-label-issued-on')}
                </span>
                <span className="fz_14 text-white fw-500">{'-'}</span>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex p-2">
                <span className="fz_14 text-content w-195">
                  {t('txt-label-place')}
                </span>
                <span className="fz_14 text-white fw-500">{'-'}</span>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex p-2">
                <span className="fz_14 text-content w-195">
                  {t('txt-label-gender')}
                </span>
                <span className="fz_14 text-white fw-500">{'-'}</span>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex p-2">
                <span className="fz_14 text-content w-195">
                  {t('txt-label-address')}
                </span>
                <span className="fz_14 text-white fw-500">{'-'}</span>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex p-2">
                <span className="fz_14 text-content w-195">
                  {t('txt-label-authorization-type')}
                </span>
                <span className="fz_14 text-white fw-500">{'-'}</span>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex p-2">
                <span className="fz_14 text-content w-195">
                  {t('txt-label-start-date')}
                </span>
                <span className="fz_14 text-white fw-500">{'-'}</span>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex p-2">
                <span className="fz_14 text-content w-195">
                  {t('txt-label-end-date')}
                </span>
                <span className="fz_14 text-white fw-500">{'-'}</span>
              </ListGroup.Item>
            </ListGroup>
            <p className="text-content fz_14 mt-3 mb-3 text-center">
              {t('txt-hotline-support')}
            </p>
            <a
              href={`tel:02462823535`}
              className="text-center text-white fz_12 mb-3 mx-auto"
              style={{
                width: '120px',
                backgroundColor: '#589dc0',
                borderRadius: '18px',
                padding: '5px',
              }}
            >
              <MdPhone className="mr-2" />
              {'0246.282.3535'}
            </a>
          </Col>
        </div>
      </PerfectScrollBar>
    );
  }
}

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getAuth = makeGetAuth();
  const getDefaultAccount = makeGetDefaultAccount();

  const mapStateToProps = (state) => {
    return {
      token: getToken(state),
      auth: getAuth(state),
      defaultAccount: getDefaultAccount(state),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  translate('translations')(Relative)
);
