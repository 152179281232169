import React from 'react';
import { isEqual } from 'lodash';
import { Nav } from 'react-bootstrap';
import { translate } from 'react-i18next';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as SvgBank } from '../../assets/img/_bank.svg';
import { ReactComponent as SvgProfile } from '../../assets/img/_profile.svg';
import { ReactComponent as SvgRelative } from '../../assets/img/_relative.svg';
import { ReactComponent as SvgSecurity } from '../../assets/img/_security.svg';
import { ReactComponent as SvgSetting } from '../../assets/img/_setting.svg';
import Bank from '../../components/customer/bank';
import Profile from '../../components/customer/profile/index';
import Relative from '../../components/customer/relative';
import Security from '../../containers/account/pages/security';
import Setting from '../../components/customer/settings';
import { changeBankReset } from '../../components/modal/auth/actions';
import {
  makeGetAuthen,
  makeGetCustomerBankAccount,
  makeGetDefaultAccount,
  makeGetToken,
} from '../../lib/selector';
import { noicapRequest } from '../banggia/actions';
import { WebSocketContext } from '../socket/webSocket';
import { bankAccRequest, profileRequest } from './actions';
import { getProfileState } from '../../containers/customer/actions';

class Customer extends React.Component {
  componentDidMount() {
    this.props.dispatch(noicapRequest());
    const { defaultAccount } = this.props;
    if (defaultAccount) {
      this._handleLoadProfile();
      this._handleLoadBankAccount();
    }
  }

  componentDidUpdate(preProps) {
    const {
      defaultAccount,
      authen: { changeBankSuccessful },
    } = this.props;
    if (defaultAccount && !isEqual(defaultAccount, preProps.defaultAccount)) {
      this._handleLoadProfile();
      this._handleLoadBankAccount();
    }

    if (
      changeBankSuccessful &&
      changeBankSuccessful !== preProps.authen.changeBankSuccessful
    ) {
      this.props.dispatch(changeBankReset());
      this._handleLoadProfile();
      this._handleLoadBankAccount();
    }
  }

  _handleGetProfileInforState = () => {
    const { token } = this.props;
    if (!token) return;
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getChangeCustInfoPending',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
      },
    };

    this.props.dispatch(getProfileState(params));
  };

  _handleLoadProfile = () => {
    const { token } = this.props;
    if (!token) return;

    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'GetProfiles',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
      },
    };

    this.props.dispatch(profileRequest(params));
  };

  _handleLoadBankAccount = () => {
    const { token, defaultAccount } = this.props;
    if (!defaultAccount) return;

    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getBankAcntList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: defaultAccount.acntNo,
        subAcntNo: defaultAccount.subAcntNo,
      },
    };

    this.props.dispatch(bankAccRequest(params));
  };

  render() {
    const {
      match: { params },
      token,
      t,
    } = this.props;

    if (!token) return <Redirect to="/" />;
    return (
      <div className="page-asset">
        <div className="page-content d-flex flex-row">
          <PerfectScrollBar>
            <Nav as="ul" className="d-flex flex-column">
              <li
                className="asset-header d-flex align-items-center"
                style={{ width: '319px', borderBottom: '1px solid #2F3134' }}
              >
                <span className="ml-2 fz_12 color-half-brown text-uppercase">
                  {`${t('txt-label-profile')} & ${t('txt-label-security')}`}
                </span>
              </li>
              <Nav.Item
                as="li"
                className={params.typeId === 'ho-so' ? 'active' : ''}
              >
                <Link to="ho-so">
                  <SvgProfile />
                  {t('txt-label-profile')}
                </Link>
              </Nav.Item>
              <Nav.Item
                as="li"
                className={params.typeId === 'bank' ? 'active' : ''}
              >
                <Link to="bank">
                  <SvgBank />
                  {t('txt-label-bank-account')}
                </Link>
              </Nav.Item>
              <Nav.Item
                as="li"
                className={params.typeId === 'uy-quyen' ? 'active' : ''}
              >
                <Link to="uy-quyen">
                  <SvgRelative />
                  {t('txt-label-authorization')}
                </Link>
              </Nav.Item>
              <Nav.Item
                as="li"
                className={params.typeId === 'security' ? 'active' : ''}
              >
                <Link to="security">
                  <SvgSecurity />
                  {`${t('txt-pass')} & ${t('txt-xacthuc')}`}
                </Link>
              </Nav.Item>
              <Nav.Item
                as="li"
                className={params.typeId === 'cai-dat' ? 'active' : ''}
              >
                <Link to="cai-dat">
                  <SvgSetting />
                  {t('lbl-setting-services')}
                </Link>
              </Nav.Item>
            </Nav>
          </PerfectScrollBar>
          <div
            className="d-flex flex-column"
            style={{ width: 'calc( 100% - 320px )', height: '100%' }}
          >
            {params.typeId === 'ho-so' ? (
              <Profile
                handleLoadProfile={this._handleLoadProfile}
                getProfileInforState={this._handleGetProfileInforState}
              />
            ) : params.typeId === 'bank' ? (
              <Bank />
            ) : params.typeId === 'uy-quyen' ? (
              <Relative />
            ) : params.typeId === 'cai-dat' ? (
              <Setting />
            ) : (
              <Security />
            )}
          </div>
        </div>
      </div>
    );
  }
}

Customer.contextType = WebSocketContext;

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getDefaultAccount = makeGetDefaultAccount();
  const getAuthen = makeGetAuthen;
  const getBankAccount = makeGetCustomerBankAccount();

  const mapStateToProps = (state) => {
    return {
      token: getToken(state),
      defaultAccount: getDefaultAccount(state),
      bankAcc: getBankAccount(state),
      authen: getAuthen(state),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  translate('translations')(Customer)
);
