import React, { useEffect, useState } from 'react';
import { take, takeRight } from 'lodash';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import Checkbox from '../../../../../components/common/checkbox';
import PagingCustom from '../../../../../components/common/paging/PagingCustom';
import Page from '../../../../../components/print/Page';
import { numberFormat, useDebounce } from '../../../../../util';
import {
  PARTNER_COMMISSION,
  PARTNER_FEE_POLICY,
  PARTNER_SERVICE_TYPE,
} from '../../../../../util/constant';
import { getCommonRequest } from '../../../../customer/actions';
import ModalAddNewCustomer from '../../modal/modalAddNewCustomer';
import ModalChangeFee from '../../modal/modalChangeFee';
import ModalRemovePartner from '../../modal/modalRemovePartner';
import { setAuth } from '../../../../client/actions';

const PAGE_SIZES_OPTIONS = [
  { value: 15, label: '15' },
  { value: 30, label: '30' },
  { value: 50, label: '50' },
];

function handleMergeData(list) {
  const newData = [];
  list.forEach((el) => {
    const check = newData.findIndex((ele) => ele[0].vsdAcntNo === el.vsdAcntNo);
    if (check !== -1) {
      newData[check].push(el);
    } else {
      newData.push([el]);
    }
  });
  return newData;
}

const CustomerList = withNamespaces('translations')(({ t }) => {
  const { defaultAccount } = useSelector((state) => state.socket);
  const { broker: brokers } = useSelector((state) => state.customer);
  const { auth, token } = useSelector((state) => state.client);
  const [showModalChangeFee, setShowModalChangeFee] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showModalRemoveCustomer, setShowModalRemoveCustomer] = useState(false);
  const [selectList, setSelectList] = useState([]);
  const theme = useSelector((state) => state.client.currentTheme);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [continued, setContinued] = useState(false);
  const [pageSizeOption, setPageSizeOption] = useState(PAGE_SIZES_OPTIONS[0]);
  const [customerList, setCustomerList] = useState([]);
  const [dataCustomer, setDataCustomer] = useState();
  const [vsdAcntNo, setVsdAcntNo] = useState();
  const [serviceTp, setServiceTp] = useState();
  const dispatch = useDispatch();
  const textSearchDebounce = useDebounce(vsdAcntNo, 300);
  const [currentRemoid, setCurrentRemoid] = useState(0);
  const handleNextPage = (step) => {
    handleQueryPage(page + step);
  };

  const handleQueryPage = (page) => {
    if (!customerList) return;
    const _pageSize = pageSizeOption?.value ?? PAGE_SIZES_OPTIONS[0].value;
    const totalPage =
      _pageSize != 0
        ? customerList && customerList.length > 0
          ? Math.ceil(customerList.length / _pageSize)
          : 1
        : 1;

    const _takeContent = take(customerList, _pageSize * page);
    const _contentShow = takeRight(
      _takeContent,
      _takeContent.length - _pageSize * (page - 1)
    );

    if (_pageSize == 0) {
      setCustomerList(customerList);
    } else {
      setCustomerList(_contentShow);
    }
    setPage(page);
    setContinued(page < totalPage);
    setTotalPage(totalPage);
  };

  const pageSizeOptions = [
    ...PAGE_SIZES_OPTIONS,
    { value: 0, label: 'txt-all' },
  ];

  const _handleLoadData = () => {
    if (!token) return;

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getCustRefList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
        acntNo: defaultAccount.acntNo,
        vsdAcntNo: textSearchDebounce,
        serTp: serviceTp,
      },
    };

    const callback = (data) => {
      setCurrentRemoid(
        data.reduce((a, b) => {
          return a + b.brokerFeeAmt;
        }, 0)
      );
      setCustomerList(handleMergeData(data));
    };

    dispatch(getCommonRequest(resData, callback, true));
  };

  useEffect(() => {
    _handleLoadData();
  }, [textSearchDebounce, serviceTp]);

  const totalRenum = brokers.reduce((a, b) => a + (b?.totRenum || 0), 0);

  return (
    <div className={`${theme?.name || 'dark'} page-partner-list`}>
      <ModalRemovePartner
        t={t}
        showModal={showModalRemoveCustomer}
        handleClose={() => {
          setDataCustomer({});
          setShowModalRemoveCustomer(false);
        }}
        dataPartner={dataCustomer}
      />
      <ModalChangeFee
        t={t}
        showModal={showModalChangeFee}
        dataCustomer={dataCustomer}
        handleClose={() => {
          setShowModalChangeFee(false);
          setDataCustomer({});
        }}
        isCustomer={false}
        isEdit
        submitApproveCallback={() => {
          _handleLoadData();
          setShowModalChangeFee(false);
          setDataCustomer({});
        }}
      />
      <ModalAddNewCustomer
        t={t}
        showModal={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
      />
      <div className="d-flex justify-content-center align-items-center p-4">
        <div className="item-total">
          <div className="text text--sm text--light3">
            {t('partner.customer')}
          </div>
          <div>{customerList.length}</div>
        </div>
        <div className="item-total">
          <div className="text text--sm text--light3">
            {t('partner.currentPeriodRemuneration')}
          </div>
          <div>{numberFormat(currentRemoid, 0, '0')}</div>
        </div>
        <div className="item-total">
          <div className="text text--sm text--light3">
            {t('partner.totalRemuneration')}
          </div>
          <div>{numberFormat(totalRenum, 0, '0')}</div>
        </div>
      </div>
      <div className="p-3">
        <div className="text text--lg text--light">
          {t('partner.customerList')}
        </div>
        <div className="d-flex gap-16 mt-2">
          <div className="w-160 p-0">
            <input
              placeholder={'VSD ' + t('partner.customer')}
              className="search__input"
              onChange={(e) => {
                setVsdAcntNo(e.target.value);
              }}
            />
          </div>
          <ReactSelect
            options={[
              {
                key: '',
                value: '',
                label:
                  t('txt-all') +
                  ' ' +
                  t('partner.commissionType').toLowerCase(),
              },
            ].concat(
              Object.keys(PARTNER_COMMISSION).map((el) => ({
                key: el,
                value: el,
                label: PARTNER_COMMISSION[el],
              }))
            )}
            defaultValue={{
              key: '',
              value: '',
              label:
                t('txt-all') + ' ' + t('partner.commissionType').toLowerCase(),
            }}
            className="filter-control-select w-200 p-0"
            classNamePrefix="filter-control-select"
            isSearchable={false}
            onChange={(e) => {
              setServiceTp(e.value);
            }}
            getOptionLabel={(option) => t(option.label)}
            getOptionValue={(option) => option.value}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
        <div className="d-flex justify-content-end gap-16 mt-3">
          <button
            onClick={() => {
              if (auth && auth.isVerified) {
                setShowModal(true);
              } else {
                dispatch(setAuth());
              }
            }}
            className={`btn d-flex justify-content-center align-items-center gap-6 ${
              auth && auth.isVerified ? 'btn--primary' : 'btn--authen'
            }`}
          >
            <span className="icon iPlus"></span> {t('partner.addNewCustomer')}
          </button>
        </div>
      </div>
      <Page
        style={{
          maxHeight: 'calc(100vh - 440px)',
          overflow: 'auto',
          position: 'relative',
        }}
      >
        <table className="table table-bordered tbl-list scroll-table">
          <thead>
            <tr>
              <th className="text-center">{t('partner.referralCode')}</th>
              <th className="text-center">{t('partner.VSDAccount')}</th>
              <th className="text-center">{t('txt-label-customer-name')}</th>
              <th className="text-center">{t('txt-sub-account')}</th>
              <th className="text-center">{t('partner.commissionType')}</th>
              <th className="text-center">{t('partner.currentFeePolicy')}</th>
              <th className="text-center">{t('partner.remunerationRate')}</th>
              <th className="text-center">
                {t('partner.currentPeriodRemuneration')}
              </th>
              <th className="text-center">{t('txt-action')}</th>
            </tr>
          </thead>
          <tbody className="">
            {customerList &&
              !!customerList.length &&
              customerList.map((item, i) => {
                return (
                  <tr key={i}>
                    <td className="text-center text--light3">
                      {item[0].brokerId}
                    </td>
                    <td className="text-center text--light3">
                      {item[0].vsdAcntNo}
                    </td>
                    <td className="text-center text--light3">
                      {item[0].custNm}
                    </td>
                    <td className="text-center fw-500">
                      <div className={'d-flex flex-column gap-6 w-100'}>
                        {item.map((el) => (
                          <div>{el.subAcntNo}</div>
                        ))}
                      </div>
                    </td>
                    <td className="text-center fw-500 text--light">
                      <div className={'d-flex flex-column gap-6 w-100'}>
                        {item.map((el) => (
                          <div>{t(PARTNER_COMMISSION[el.serviceType])}</div>
                        ))}
                      </div>
                    </td>
                    <td className="text-center text--light">
                      <div className={'d-flex flex-column gap-6 w-100'}>
                        {item.map((el) => (
                          <div className="d-flex gap-16 justify-content-center">
                            <span className="text-center c">
                              {el.feePolicy}
                            </span>
                          </div>
                        ))}
                      </div>
                    </td>
                    <td className="text-right text--light fw-500">
                      <div className={'d-flex flex-column gap-6 w-100'}>
                        {item.map((el) => (
                          <div className="text-center">{el.comRt}</div>
                        ))}
                      </div>
                    </td>
                    <td className="text-right text--light">
                      <div className={'d-flex flex-column gap-6 w-100'}>
                        {item.map((el) => (
                          <div> {numberFormat(el.brokerFeeAmt, 0, '0')}</div>
                        ))}
                      </div>
                    </td>
                    <td className="h-100">
                      <div className="h-100 d-flex justify-content-center gap-8">
                        <button
                          onClick={() => {
                            if (auth && auth.isVerified) {
                              setShowModalRemoveCustomer(true);
                              setDataCustomer(item[0]);
                            } else {
                              dispatch(setAuth());
                            }
                          }}
                          className={`btn d-flex ${
                            auth && auth.isVerified
                              ? 'btn-danger-secondary d'
                              : 'btn--authen'
                          }`}
                        >
                          <span
                            className={
                              auth && auth.isVerified
                                ? 'icon iClose bg-red'
                                : 'icon iClose'
                            }
                          ></span>
                        </button>
                        <button
                          className={`btn d-flex ${
                            auth && auth.isVerified
                              ? 'btn--cancel'
                              : 'btn--authen'
                          }`}
                          onClick={() => {
                            if (auth && auth.isVerified) {
                              setShowModalChangeFee(true);
                              setDataCustomer(item[0]);
                            } else {
                              dispatch(setAuth());
                            }
                          }}
                        >
                          <span className="icon iEdit"></span>
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {(!customerList || !customerList.length) && (
          <p className="text-center fz_14 mt-2 title-time">
            {t('txt-no-data-found')}
          </p>
        )}
      </Page>
      {/*{customerList && (*/}
      {/*  <div className="pb-5">*/}
      {/*    <PagingCustom*/}
      {/*      handleNextPage={() => handleNextPage(1)}*/}
      {/*      handlePrevPage={() => handleNextPage(-1)}*/}
      {/*      page={page}*/}
      {/*      totalPage={totalPage}*/}
      {/*      continued={continued}*/}
      {/*      t*/}
      {/*      hasPageSize={true}*/}
      {/*      pageSizeOption={pageSizeOption}*/}
      {/*      _handleSetPageSize={setPageSizeOption}*/}
      {/*      pageSizeOptions={pageSizeOptions}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
});

export default CustomerList;
