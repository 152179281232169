import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { getCommonRequest } from '../../customer/actions';
import { withNamespaces } from 'react-i18next';
import { setAuth, setToast } from '../../client/actions';
import * as clipboard from 'clipboard-polyfill';
import { Redirect } from 'react-router';
import QRCode from 'qrcode.react';
import logo from '../../../assets/img/logo.svg';
import { setDefaultAccount } from '../../socket/actions';
import { accountTypeDetect, StringToInt } from '../../../util';
import ModalAddNewCustomer from '../components/modal/modalAddNewCustomer';
import Customer from '../../customer';
import CustomerList from '../components/partnership/referrer/customerList';
import RequestList from '../components/partnership/referrer/requestList';
import { TRADING_TYPE } from '../../../util/constant';
import { Mixpanel } from '../../../lib/mixpanel';

const Partnership = withNamespaces('translations')(({ t }) => {
  const { broker: brokers } = useSelector((state) => state.customer);
  const [showModal, setShowModal] = useState(false);
  const [tabActive, setTabActive] = useState(0);
  const [brkId, setBrkId] = useState();
  const {
    token,
    auth,
    setting: { lang = 'vi' },
    appsetting,
  } = useSelector((state) => state.client);
  const dispatch = useDispatch();
  const qrRef = useRef();

  const generals =
    appsetting && !!appsetting?.length > 0
      ? JSON.parse(
          appsetting?.find((item) => item.key == 'GENERAL')?.values || []
        )
      : [];

  const broker_url =
    generals.find((x) => x.key == 'partnership-url')?.value ||
    process.env.REACT_APP_BROKER_URL;

  const statusLabel = (status) => {
    return status == '0' ? t('txt-active') : t('txt-deactive');
  };

  const copyToClipboardHandle = (e, msg = t('txt-copied-to-clipboard')) => {
    if (!e) return;

    const textToCopy = e.trim();

    var tempInputElement = document.createElement('input');
    tempInputElement.type = 'text';
    tempInputElement.value = textToCopy;
    document.body.appendChild(tempInputElement);
    tempInputElement.select();
    document.execCommand('Copy');
    document.body.removeChild(tempInputElement);

    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: t('txt-notice'),
    };

    dispatch(setToast(toastMsg));
  };

  const downloadQRCodeHandle = () => {
    const canvas = qrRef.current.children[0];
    if (canvas) {
      const url = canvas.toDataURL('image/png');
      const a = document.createElement('a');
      a.href = url;
      a.download = 'pinetree-qrcode.png';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const copyImageToClipboard = async (msg = t('txt-copied-to-clipboard')) => {
    try {
      const canvas = qrRef.current.children[0];
      if (canvas) {
        // Convert canvas to blob
        const blob = await new Promise((resolve) => canvas.toBlob(resolve));
        // Write blob to clipboard
        await navigator.clipboard.write([
          new ClipboardItem({ 'image/png': blob }),
        ]);

        const toastMsg = {
          id: Math.random(),
          msg: msg,
          title: t('txt-notice'),
        };

        dispatch(setToast(toastMsg));
      }
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  return (
    <div className="partner-container account-panel">
      <ModalAddNewCustomer
        t={t}
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        brkId={brkId}
      />
      <div className="personal-assets-header card-panel-2-header">
        <span className="personal-assets-header__left">
          <span
            className={`card-panel-2__tab ${tabActive == 0 ? 'active' : ''}`}
            onClick={() => {
              setTabActive(0);
            }}
          >
            {t('partner.referrerInformation')}
          </span>
          <span
            className={`card-panel-2__tab ${tabActive == 1 ? 'active' : ''}`}
            onClick={() => {
              setTabActive(1);
            }}
          >
            {t('partner.customerList')}
          </span>
          <span
            className={`card-panel-2__tab ${tabActive == 2 ? 'active' : ''}`}
            onClick={() => {
              setTabActive(2);
            }}
          >
            {t('partner.requestList')}
          </span>
        </span>
      </div>
      {tabActive === 0 && (
        <div className="partner-page pt-4">
          <div className="account-panel col-md-8">
            {brokers.map((broker) => (
              <div
                key={broker.brokerId}
                className="account-panel-body d-flex gap-16"
              >
                <div className="account-panel-items">
                  <div className="account-panel-item">
                    <span className="account-panel-item__name mw-165px">
                      {t('partner.id')}
                    </span>
                    <span className="account-panel-item__value d-flex align-items-center">
                      <span>{broker?.brokerId}</span>
                      <span
                        className="d-flex ml-2 btn-icon btn--transparent cursor-pointer"
                        onClick={() => {
                          copyToClipboardHandle(
                            broker?.brokerId,
                            t('txt-copied-to-clipboard', {
                              object: t('partner.id').toLowerCase() + ' ',
                            })
                          );
                        }}
                      >
                        <span className="icon iCopy"></span>
                      </span>
                    </span>
                  </div>
                  <div className="account-panel-item">
                    <span className="account-panel-item__name mw-165px">
                      {t('partner.status')}
                    </span>
                    <span className="account-panel-item__value">
                      {statusLabel(broker?.status)}
                    </span>
                  </div>
                  <div className="account-panel-item">
                    <span className="account-panel-item__name mw-165px">
                      {t('partner.link')}
                    </span>
                    <span className="account-panel-item__value d-flex align-items-center">
                      <a
                        className="text text--light-blue3"
                        href={`${broker_url}/${broker?.brokerId}`}
                        target="_blank"
                      >
                        {`${broker_url}/${broker?.brokerId}`}
                      </a>
                      <span
                        className="d-flex ml-2 btn-icon btn--transparent cursor-pointer"
                        onClick={() => {
                          copyToClipboardHandle(
                            `${broker_url}/${broker?.brokerId}`,
                            t('txt-copied-to-clipboard', {
                              object: t('object.url').toLowerCase() + ' ',
                            })
                          );
                        }}
                      >
                        <span className="icon iCopy"></span>
                      </span>
                    </span>
                  </div>
                  <button
                    onClick={() => {
                      if (auth && auth.isVerified) {
                        setBrkId(broker.brokerId);
                        setShowModal(true);
                      } else {
                        dispatch(setAuth());
                      }
                    }}
                    className={`btn d-flex justify-content-center align-items-center gap-4 mt-2 ${
                      auth && auth.isVerified ? 'btn--primary' : 'btn--authen'
                    }`}
                  >
                    <span className="icon iPlus"></span>{' '}
                    {t('partner.addNewCustomer')}
                  </button>
                </div>
                <div className="account-panel-item">
                  <div className="account-panel-item__value d-flex gap-3">
                    <div className="partner-page__qr" ref={qrRef}>
                      <QRCode
                        id="partnership-qr"
                        value={`${broker_url}/${broker?.id}`}
                        imageSettings={{
                          src: logo,
                          excavate: true,
                          width: 24,
                          height: 24,
                        }}
                      />
                    </div>
                    <div className="d-flex flex-column gap-3">
                      <button
                        className="btn btn--primary2"
                        onClick={() => downloadQRCodeHandle()}
                      >
                        {t('txt-save')}
                      </button>
                      <button
                        className="btn btn--primary2"
                        onClick={() =>
                          copyImageToClipboard(
                            t('txt-copied-to-clipboard', {
                              object: t('object.img').toLowerCase() + ' ',
                            })
                          )
                        }
                      >
                        {t('txt-copy')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {tabActive === 1 && <CustomerList t={t} />}
      {tabActive === 2 && <RequestList t={t} />}
    </div>
  );
});

export default Partnership;
